import { HasStatus, HasType, IBase } from "./utils.types";
import { TokenEntity } from "./token.types";
import { UserEntity } from "./user.types";

export interface CreatePaymentRequestDto {
  amount?: number;
  tokenId?: string;
  description?: string;
  webHookUrl?: string;
  redirectUrls?: {
    onSuccess?: string;
    onError?: string;
    onReject?: string;
  };
  receiverRef?: string;
  email?: string;
  phone?: string;
  orderId?: string;
}

export interface InvoiceEntity
  extends IBase,
    HasStatus<RefmeInvoiceStatusEnum>,
    HasType<RefmeInvoiceTypeEnum> {
  sender?: UserEntity;

  receiver?: UserEntity;

  token?: TokenEntity;

  // transaction?: Transa;

  amount?: number;

  expiresIn?: number;

  ref?: string;

  orderId?: string;

  description?: string;

  error?: string;

  email?: string;

  phone?: string;

  receiverRef?: string;

  redirectUrls?: {
    onSuccess?: string;

    onError?: string;

    onReject?: string;
  };

  webHookUrl?: string;

  paymentUrl?: string;
}

export enum RefmeInvoiceTypeEnum {
  payment = "payment",
  transfer = "transfer",
}
export enum RefmeInvoiceStatusEnum {
  draft = "draft",
  error = "error",
  fail = "fail",
  wait_confirm = "wait_confirm",
  expired = "expired",
  rejected = "rejected",
  accepted = "accepted",
  hold = "hold",
}

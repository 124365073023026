import storage from "redux-persist/lib/storage";
import { PersistConfig, Storage, WebStorage } from "redux-persist";

import { TransactionsState } from "./transactions/transactions.slice";
import { WalletsState } from "./wallets/wallets.slice";
import { MlmRulesState } from "./mlm-rules/mlm-rules.slice";
import { UsersState } from "./users/users.slice";
import { EventsState } from "./events/events.slice";
import { TokensState } from "./tokens/tokens.slice";
import { AuthState } from "./auth/auth.slice";
import { SessionState } from "node:http2";
import { Keys } from "../types/utils.types";

enum ModuleNameEnum {
  auth = "auth",
  users = "users",
  authSessions = "authSessions",
  wallets = "wallets",
  mlm_rules = "mlm_rules",
  events = "events",
  tokens = "tokens",
  transactions = "transactions",
}

type RootState = {
  [ModuleNameEnum.users]: UsersState;
  [ModuleNameEnum.transactions]: TransactionsState;
  [ModuleNameEnum.auth]: AuthState;
  [ModuleNameEnum.authSessions]: SessionState;
  [ModuleNameEnum.wallets]: WalletsState;
  [ModuleNameEnum.users]: UsersState;
  [ModuleNameEnum.mlm_rules]: MlmRulesState;
  [ModuleNameEnum.events]: EventsState;
  [ModuleNameEnum.tokens]: TokensState;
};

export const persistorConfigs = {
  ...createConfig(ModuleNameEnum.transactions, { storage }),
  ...createConfig(ModuleNameEnum.auth, { storage }),
  ...createConfig(ModuleNameEnum.users, { storage }),
  ...createConfig(ModuleNameEnum.authSessions, { storage }),
  ...createConfig(ModuleNameEnum.wallets, { storage }),
  ...createConfig(ModuleNameEnum.mlm_rules, { storage }),
  ...createConfig(ModuleNameEnum.events, { storage }),
  ...createConfig(ModuleNameEnum.tokens, { storage }),
};

type CustomPersistConfigs<StateKeys> = {
  storage: Storage | WebStorage;
} & Omit<PersistConfig<any>, "key"> & {
    whitelist?: StateKeys[];
    blacklist?: StateKeys[];
  };

function createConfig<
  Name extends ModuleNameEnum,
  StateKeys extends Keys<RootState[Name]>,
  Configs extends
    CustomPersistConfigs<StateKeys> = CustomPersistConfigs<StateKeys>,
>(
  moduleName: Name,
  configs: Configs,
): Record<
  Name,
  Configs & {
    key: Name;
  }
> {
  const _configs: Configs = {
    ...configs,
    whitelist: configs.whitelist
      ? Array.from(new Set(configs.whitelist))
      : undefined,
    blacklist: configs.blacklist
      ? Array.from(new Set(configs.blacklist))
      : undefined,
  };

  return {
    [moduleName]: {
      ..._configs,
      key: moduleName,
    },
  } as unknown as Record<
    Name,
    Configs & {
      key: Name;
    }
  >;
}

import { TokenEntity } from "../../../types";
import { Tag, TagProps } from "antd";

export const TokenTypeTag = ({ info }: { info: Partial<TokenEntity> }) => {
  return (
    <Tag bordered color={info?.type ? tokenTagColors?.[info.type] : "default"}>
      {info?.type}
    </Tag>
  );
};

export const tokenTagColors: Record<string, TagProps["color"]> = {
  bonus: "yellow",
  fiat: "blue",
  crypto: "purple",
};

import React, { useLayoutEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import FlexBox from "../components/Atoms/Flex";
import { Container } from "../components/Atoms/Container";
import { useAuthSelector } from "../redux/selectors.redux";
import ClientApi from "../api/Client.api";

const PublicLayout = () => {
  const { access_token } = useAuthSelector();

  useLayoutEffect(() => {
    !access_token && ClientApi.unsetToken();
  }, [access_token]);

  return (
    <FlexBox>
      <Container>
        {access_token ? <Navigate to={"/profile"} /> : <Outlet />}
      </Container>
    </FlexBox>
  );
};

export default PublicLayout;

import BaseTable, { BaseTableProps, TableDataType } from "../Atoms/Table";
import React, { useState } from "react";
import FlexBox from "../Atoms/Flex";
import { Text } from "../Atoms/Text";
import { Pagination } from "antd";
import SvgIcon from "../Atoms/SvgIcon";
import { AppButton } from "../Atoms/Buttons";
import { ButtonProps } from "antd/es/button/button";

export type TableActionProps = ButtonProps & {
  action: keyof Omit<typeof AppButton, "Base">;
  visible?: boolean;
};

export const TableWithActions = <T extends TableDataType = TableDataType>({
  label,
  columns,
  data,
  onAddPress,
  onRemove,
  onEdit,
  isDeleting,
  onRestore,
  isRestoring,
  expandable = true,
  onOpenInNew,
  limitOptions = [5, 10, 25, 50],
  limit,
  onLimitChange,
  selected,
  onRowPress,
  onRefresh,
  isRefreshing,
  setOpen,
  onPageSelect,
}: BaseTableProps<T> & {
  label?: string;
  onAddPress?: () => void;
  onRemove?: (data: T) => void;
  onRestore?: (data: T) => void;
  onOpenInNew?: (data: T) => void;
  onRefresh?: () => void;
  isRefreshing?: boolean;
  onEdit?: (data: T) => void;
  isDeleting?: boolean;
  isRestoring?: boolean;
  expandable?: boolean;
  setOpen?: boolean;
  onLimitChange?: (count: number) => void;
  limitOptions?: number[];
  limit?: number;
  onPageSelect?: (page: number) => void;
}) => {
  const [_selected, setSelected] = useState<T>();
  const [isVisible, setIsVisible] = useState(setOpen ?? true);
  // const [isSoftDelete, setIsSoftDelete] = useState(true);
  const current = selected || _selected;

  return (
    <FlexBox
      $gap={2}
      $padding={""}
      $minHeight={"50px"}
      $fillWidth
      onMouseLeave={() => {
        setSelected(undefined);
      }}
    >
      <FlexBox
        $fxDirection={"row"}
        $padding={"8px 8px"}
        $justifyContent={"space-between"}
        $alignItems={"center"}
        $gap={8}
      >
        {expandable && (
          <AppButton.Base
            type={"button"}
            onClick={() => setIsVisible((p) => !p)}
          >
            <SvgIcon $icon={isVisible ? "SmallArrowDown" : "SmallArrowRight"} />
          </AppButton.Base>
        )}

        <Text $weight={600} $size={13}>
          {label}
        </Text>

        <FlexBox
          $fxDirection={"row"}
          margin={"0 0 0 auto"}
          $gap={8}
          $alignItems={"center"}
        >
          {isVisible &&
            (
              [
                {
                  action: "OpenInNew",
                  visible: !!onOpenInNew,
                  disabled: !current,
                  onClick: () => {
                    current && onOpenInNew && onOpenInNew(current);
                  },
                },
                {
                  action: "AddOne",
                  disabled: !onAddPress,
                  onClick: onAddPress,
                },
                {
                  action: "Refresh",
                  visible: !!onRefresh,
                  onClick: onRefresh,
                  loading: isRefreshing,
                },
              ] as TableActionProps[]
            ).map(({ action, visible, ...props }) => {
              const Comp = AppButton?.[action] ?? AppButton.Base;
              return visible === false ? null : (
                <Comp key={action} {...props} />
              );
            })}
        </FlexBox>
      </FlexBox>

      {isVisible && (
        <>
          <FlexBox $flex={1} overflow={"hidden"} $fillWidth>
            <BaseTable
              columns={columns}
              data={data}
              selected={_selected}
              keyExtractor={(row) => row._id}
              onRowPress={(row, index) => {
                const data = row?._id === current?._id ? undefined : row;

                if (onRowPress) {
                  onRowPress(data, index);
                } else {
                  setSelected(data);
                }
              }}
            />
          </FlexBox>

          <FlexBox
            $fxDirection={"row"}
            $padding={"8px 8px"}
            $justifyContent={"flex-end"}
            $alignItems={"center"}
            $gap={8}
          >
            {!!data?.length &&
              (
                [
                  {
                    action: "Restore",
                    onClick: () => {
                      current && onRestore && onRestore(current);
                    },
                    disabled: !onRestore,
                    loading: isRestoring,
                    visible: !!current?.deletedAt,
                  },

                  {
                    action: "Edit",
                    onClick: () => {
                      current && onEdit && onEdit(current);
                    },
                    disabled: !current || !onEdit,
                    // loading: isRestoring,
                    visible: !current?.deletedAt,
                  },
                  {
                    action: "Delete",
                    onClick: () => {
                      current && onRemove && onRemove(current);
                    },
                    disabled: !current || !onRemove,
                    loading: isDeleting,
                    visible: !current?.deletedAt,
                  },
                ] as TableActionProps[]
              ).map(({ action, visible, ...props }) => {
                const Comp = AppButton?.[action] ?? AppButton.Base;
                return visible === false ? null : (
                  <Comp key={action} {...props} />
                );
              })}
          </FlexBox>

          <FlexBox margin={"0 auto 0 0"}>
            <Pagination
              onChange={onPageSelect}
              total={data?.length}
              onShowSizeChange={onLimitChange}
              responsive={true}
              pageSizeOptions={limitOptions}
              showSizeChanger
              size={"small"}
            />
          </FlexBox>
        </>
      )}
    </FlexBox>
  );
};

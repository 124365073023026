import { useSelector } from "react-redux";
import { AuthState } from "./auth/auth.slice";
import { RootState } from "./store.redux";
import { TokensState } from "./tokens/tokens.slice";
import { MlmRulesState } from "./mlm-rules/mlm-rules.slice";
import { InvoicesState } from "./invoices/invoices.slice";
import { AuthSessionsState } from "./auth-sessions/auth-sessions.slice";
import { TransactionsState } from "./transactions/transactions.slice";
import { UsersState } from "./users/users.slice";
import { EventsState } from "./events/events.slice";
import { WalletsState } from "./wallets/wallets.slice";
import { OAuthState } from "./auth/o-auth/o-auth.slice";

export const useRootSelector = (): RootState =>
  useSelector((state: RootState): RootState => state) as RootState;

export const useAuthSelector = (): AuthState =>
  useSelector((s: RootState) => s.auth);

export const useOAuthSelector = (): OAuthState =>
  useSelector((s: RootState) => s.o_auth);

export const useTokensSelector = (): TokensState =>
  useSelector((s: RootState) => s.tokens);

export const useMlmRulesSelector = (): MlmRulesState =>
  useSelector((s: RootState) => s.mlm_rules);

export const useInvoicesSelector = (): InvoicesState =>
  useSelector((s: RootState) => s.invoices);

export const useAuthSessionsSelector = (): AuthSessionsState =>
  useSelector((s: RootState) => s["auth/sessions"]);

export const useTransactionsSelector = (): TransactionsState =>
  useSelector((s: RootState) => s.transactions);

export const useUsersSelector = (): UsersState =>
  useSelector((s: RootState) => s.users);

export const useEventsSelector = (): EventsState =>
  useSelector((s: RootState) => s.events);

export const useWalletsSelector = (): WalletsState =>
  useSelector((s: RootState) => s.wallets);

import {
  HasLabelEntity,
  HasNameEntity,
  HasReference,
  IBase,
} from "../../types/utils.types";
import { TableColumnData, TableDataType } from "../Atoms/Table";
import { MlmRuleEntity, TokenEntity, User } from "../../types";
import {
  UserAccessStatusTag,
  UserRoleTag,
  UserStatusTag,
} from "../Atoms/tags/user-tags";
import React from "react";
import { WalletStatusTag } from "../Atoms/tags/bal-account-tags";
import { Tag } from "antd";
import { TokenTypeTag } from "../Atoms/tags/token-tags";
import FlexBox from "../Atoms/Flex";
import { AppButton } from "../Atoms/Buttons";
import { Wallet } from "../../types/wallets.types";

export function nameOrLabelColumn<
  Data extends HasLabelEntity & HasNameEntity = any,
>(props?: TableColumnData<Data>): TableColumnData<Data> {
  return {
    title: "Name/Label",
    key: "nameOrLabelColumn",
    ...props,
    getData(d) {
      return (
        (d.name && `${d.name.first} ${d.name.second}`) ||
        (d.label && d.label.base) ||
        "---"
      );
    },
  };
}
export function referenceColumn<Data extends HasReference = any>(
  props?: TableColumnData<Data>,
): TableColumnData<Data> {
  if (props?.canBeCopied) {
    return copyTextColumn({
      key: "referenceColumn_Copied",
      title: "Reference",
      getData: (row) => row.reference,
      getCopyValue: (row) => row.reference ?? "",
    });
  }

  return {
    title: "Reference",
    key: "referenceColumn",
    ...props,
    getData: (row) => row.reference,
  };
}

export function defaultColumn<Data extends TableDataType = any>(
  props?: TableColumnData<Data> & {
    path?: string;
    getCopyValue?: (row: Data) => string | undefined;
  },
): TableColumnData<Data> {
  if (props?.getCopyValue || props?.canBeCopied) {
    return copyTextColumn({
      key: `defaultColumn_${props.title ?? "---"}`,
      title: "(Default column)",
      getData: (row) => (props.path ? row?.[props.path] : "---"),
      ...props,
      getCopyValue:
        props?.getCopyValue ||
        ((row) => (props.path ? row?.[props.path] : "---")),
    });
  }

  return {
    title: "(Default column)",
    key: `defaultColumn_${props?.title ?? "---"}`,
    getData: (row) => (props?.path ? row?.[props.path] : "---"),
    ...props,
  };
}

export function userAccessStatusColumn<
  Data extends User.Entity = any,
>(): TableColumnData<Data> {
  return {
    title: "Access",
    key: "userAccessStatusColumn",
    getData(d) {
      return <UserAccessStatusTag user={d} />;
    },
  };
}
export function userRoleColumn<
  Data extends User.Entity = any,
>(): TableColumnData<Data> {
  return {
    title: "Role",
    key: "userRoleColumn",
    getData(d) {
      return <UserRoleTag user={d} />;
    },
  };
}
export function userStatusColumn<
  Data extends User.Entity = any,
>(): TableColumnData<Data> {
  return {
    title: "Status",
    key: "userStatusColumn",
    getData(d) {
      return <UserStatusTag user={d} />;
    },
  };
}
export function mlmRuleUserStatusColumn<Data extends MlmRuleEntity = any>({
  getStatus,
  ...props
}: TableColumnData<Data> & {
  getStatus?: (d: Data) => User.Entity["status"] | undefined;
} = {}): TableColumnData<Data> {
  return {
    title: "User status",
    key: "mlmRuleUserStatusColumn",
    ...props,
    getData(d) {
      return (
        <UserStatusTag
          status={d.userStatus ?? (getStatus ? getStatus(d) : undefined)}
        />
      );
    },
  };
}
export function mlmRuleWalletStatusColumn<Data extends MlmRuleEntity = any>({
  getStatus,
  ...props
}: TableColumnData<Data> & {
  getStatus?: (d: Data) => Wallet.StatusEnum | undefined;
} = {}): TableColumnData<Data> {
  return {
    title: "Wallet status",
    key: "mlmRuleWalletStatusColumn",
    ...props,
    getData(d) {
      return (
        <WalletStatusTag
          status={d.walletStatus ?? (getStatus ? getStatus(d) : undefined)}
        />
      );
    },
  };
}
export function mlmRuleSourceVolumeColumn<Data extends MlmRuleEntity = any>(
  props: TableColumnData<Data> = {},
): TableColumnData<Data> {
  return {
    title: "Source volume",
    key: "mlmRuleSourceVolumeColumn",
    ...props,
    getData: (row) => {
      return <Tag>{row.sourceVolume}</Tag>;
    },
  };
}
export function mlmRuleTargetVolumesColumn<Data extends MlmRuleEntity = any>(
  props: TableColumnData<Data> = {},
): TableColumnData<Data> {
  return {
    title: "Target volume",
    key: "mlmRuleTargetVolumesColumn",
    ...props,
    getData: (row) => {
      return (
        <FlexBox $gap={6} $fxDirection={"row"}>
          {row.targetVolumes?.map((value) => <Tag>{value}</Tag>) ?? "---"}
        </FlexBox>
      );
    },
  };
}
export function mlmRuleThresholdTypeColumn<Data extends MlmRuleEntity = any>(
  props: TableColumnData<Data> = {},
): TableColumnData<Data> {
  return {
    title: "Threshold type",
    width: "115px",
    align: "right",
    key: "mlmRuleThresholdTypeColumn",
    ...props,
    getData(d) {
      return (
        <Tag color={"default"} bordered>
          {d.thresholdType}
        </Tag>
      );
    },
  };
}
export function mlmRuleValueTypeColumn<Data extends MlmRuleEntity = any>(
  props: TableColumnData<Data> = {},
): TableColumnData<Data> {
  return {
    title: "Value type",
    width: "115px",
    key: "mlmRuleValueTypeColumn",
    ...props,
    getData(d) {
      return (
        <Tag color={"default"} bordered>
          {d.valueType}
        </Tag>
      );
    },
  };
}

export function deleteStatusColumn<Data extends IBase = any>({
  ...props
}: TableColumnData<Data> = {}): TableColumnData<Data> {
  return {
    title: "Active status",
    key: "deleteStatusColumn",
    // minWidth: "90px",
    ...props,
    getData: (data) => {
      return (
        <Tag color={data?.deletedAt ? "error" : "success"}>
          {data?.deletedAt ? "Deleted" : "Active"}
        </Tag>
      );
    },
  };
}

export function tokenTypeColumn<Data extends TokenEntity = any>(
  props: TableColumnData<Data> = {},
): TableColumnData<Data> {
  return {
    title: "Type",
    path: "type",
    width: "90px",
    key: "tokenTypeColumn",
    ...props,
    getData(d) {
      return <TokenTypeTag info={d} />;
    },
  };
}

export function copyTextColumn<Data extends TableDataType = any>({
  getCopyValue,
  getData,
  ...props
}: TableColumnData<Data> & {
  getCopyValue: (Data: Data) => string | undefined;
}): TableColumnData<Data> {
  return {
    title: "[Copy value]",
    width: "90px",
    key: "copyTextColumn",
    ...props,
    getData: (row, title) => (
      <AppButton.Copy
        copyValue={getCopyValue(row)}
        style={{ justifyContent: "flex-start" }}
      >
        {(getData && getData(row, title)) ?? null}
      </AppButton.Copy>
    ),
  };
}

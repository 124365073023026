import ClientApi from "../Client.api";
import { OAuth } from "../../types/auth/o-auth.namespace";
import { ApiAsyncResponse } from "../types.api";
import { AxiosHeaderValue } from "axios";
import { LocalApi } from "../LocalClient.api";

export class OAuthApi {
  private static _api = ClientApi.getClient();
  private static _local_api = LocalApi.Client.ref;
  private static _endps = ClientApi.endpoints.auth.o_auth;

  public static logIn = (input?: {
    params?: OAuth.LogInByEmailDto;
  }): ApiAsyncResponse<OAuth.GetAuthUrlResponseData> => {
    return this._local_api.post(
      this._endps.logIn(),
      {},
      { params: input?.params },
    );
  };

  static getAuthUrl = ({
    params,
    headers,
  }: {
    params?: OAuth.GetAuthUrlQuery<OAuth.Provider.TypeEnum>;
    headers?: Record<string, AxiosHeaderValue>;
  } = {}): ApiAsyncResponse<OAuth.GetAuthUrlResponseData> => {
    return this._local_api.post(
      this._endps.getAuthUrl(),
      {},
      { params, headers },
    );
  };
  static accept = (input?: {
    params?: OAuth.AcceptLogInQuery;
  }): ApiAsyncResponse<OAuth.AcceptLogInResponseData> => {
    return this._api.post(this._endps.accept(), {}, input);
  };
  // public static handleCallback = (input?: {
  //   params: OAuth.CallbackQuery;
  // }): Promise<ApiResponse<OAuth.Profile.Entity>> => {
  //   return this._local_api.post(
  //     this._endps.callback(),
  //     {},
  //     { params: input?.params },
  //   );
  // };

  public static connections = {
    create: (input?: {
      data: OAuth.Connection.Dto;
    }): ApiAsyncResponse<OAuth.Connection.Entity> => {
      return this._api.get(this._endps.connections.getOne(), input);
    },
    getOne: (input?: {
      params: {
        publicKey: string;
      };
    }): ApiAsyncResponse<OAuth.Connection.Entity> => {
      return this._api.get(this._endps.connections.getOne(), input);
    },
    getAll: (input?: {
      params: {
        publicKey?: string;
      };
    }): ApiAsyncResponse<OAuth.Connection.Entity[]> => {
      return this._api.get(this._endps.connections.getAll(), input);
    },
  };
}

import { BlockTemplate } from "./BlockTemplate";
import { useEventsSelector } from "../../redux/selectors.redux";
import { revenueEventColumns } from "../../hardData/events";
import { TableWithActions } from "../Tables/TableWithActions";

const EventsListBlock = () => {
  const list = useEventsSelector().revenues;

  return (
    <BlockTemplate
      title={"Revenue events"}
      contentContainerStyles={{
        $padding: "0 16px 16px",
        $xsStyles: { $padding: "0 0 16px" },
      }}
    >
      <TableWithActions columns={revenueEventColumns} data={list} />
    </BlockTemplate>
  );
};
export default EventsListBlock;

import { HTMLInputTypeAttribute } from "react";
import { FieldValues, Path } from "react-hook-form";
import { CreatePaymentRequestDto } from "../../types";

export interface FormFieldInfo<FormData extends FieldValues = FieldValues> {
  name: Path<FormData>;
  registerName?: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
}

interface RegisterFormData {
  name?: {
    first?: string;
    second?: string;
  };
  email?: string;
  password?: string;
}

export const registerInputsData: FormFieldInfo<RegisterFormData>[] = [
  // {
  //   name: "firstName",
  //   registerName: "firstName",
  //   placeholder: "Enter your name",
  //   label: "Name",
  //   required: true,
  // },
  // {
  //   name: "secondName",
  //   placeholder: "Enter your second name",
  //   label: "Second name",
  //   required: true,
  // },
  {
    name: "email",
    placeholder: "Enter your email",
    label: "Email",
    required: true,
  },
  {
    name: "password",
    placeholder: "Enter your password",
    label: "Password",
    required: true,
    type: "password",
  },
];

interface LogInFormData {
  email?: string;
  password?: string;
}

export const logInInputsData: FormFieldInfo<LogInFormData>[] = [
  {
    name: "email",
    placeholder: "Enter your email",
    label: "Email",
    required: true,
  },
  {
    name: "password",
    placeholder: "Enter your password",
    label: "Password",
    type: "password",
    required: true,
  },
];

export interface EditProfileFormData {
  firstName?: string;
  secondName?: string;
  email?: string;
  phone?: string;
}

export const editUserInfoInfo: FormFieldInfo<EditProfileFormData>[] = [
  {
    name: "firstName",
    placeholder: "Enter your name",
    label: "Name",
    required: true,
  },

  {
    name: "secondName",
    placeholder: "Enter your second name",
    label: "Second name",
    required: true,
  },
  {
    name: "phone",
    placeholder: "Enter your new phone",
    label: "Phone",
    required: true,
  },
  {
    name: "email",
    placeholder: "Enter your email",
    label: "Second name",
    required: true,
  },
];

interface EditPasswordInfoFormData {
  password?: string;
  passwordCheck: string;
}

export const editPasswordInfo: FormFieldInfo<EditPasswordInfoFormData>[] = [
  {
    name: "password",
    placeholder: "Enter your new password",
    label: "Enter your new password",
    // canBeHidden: true,
    required: true,
  },
  {
    name: "passwordCheck",
    placeholder: "Enter your password",
    label: "Enter your password again",
    // canBeHidden: true,
    required: true,
  },
];

interface GenerateApiKeyFormData {
  label?: string;
  ip?: string;
  originUrl?: string;
}

export const generateApiKeyData: FormFieldInfo<GenerateApiKeyFormData>[] = [
  {
    name: "ip",
    placeholder: "171.5.89.55",
    label: "Enter IP",
    required: true,
  },
  {
    name: "originUrl",
    placeholder: "https://google.com/",
    label: "Enter API key allowed origin URL",
    required: true,
  },
  {
    name: "label",
    placeholder: "New API Key",
    label: "Enter  APi Key label",
    required: true,
  },
];

interface GenerateTokenFormData {
  label?: string;
  symbol?: string;
  amount?: number;
  decimals?: number;
  minTransferAmount?: number;
  maxTransferAmount?: number;
  commissionSender?: number;
  commissionReceiver?: number;
  description?: string;
  type?: string;
}

export const generateTokenData: FormFieldInfo<GenerateTokenFormData>[] = [
  {
    name: "label",
    placeholder: "Enter token label",
    label: "Enter Token Label",
    required: true,
  },
  {
    name: "symbol",
    placeholder: "Enter token symbol",
    label: "Enter symbol",
    required: true,
  },
  {
    name: "description",
    placeholder: "Enter description",
    label: "Enter description",
    required: true,
  },
  // {
  //   name: "amount",
  //   placeholder: "Enter amount",
  //   label: "Enter amount",
  //   required: true,
  // },
  // {
  //   name: "decimals",
  //   placeholder: "Enter token decimals",
  //   label: "Enter token decimals",
  //   required: true,
  // },
];

export const createPaymentRequest: FormFieldInfo<CreatePaymentRequestDto>[] = [
  // {
  //   name: "tokenId",
  //   placeholder: "Enter token ID",
  //   label: "Enter token ID",
  //   required: true,
  // },

  {
    name: "orderId",
    placeholder: "Enter order ID",
    label: "Enter order ID",
    required: true,
  },

  {
    name: "amount",
    placeholder: "Enter amount",
    label: "Enter amount",
    required: true,
  },
  // {
  //   name: "senderRef",
  //   placeholder: "Enter senderRef",
  //   label: "Enter description",
  //   required: true,
  // },
  {
    name: "description",
    placeholder: "Enter description",
    label: "Enter description",
    required: false,
  },

  {
    name: "redirectUrls.onSuccess",
    placeholder: "Enter redirectUrl onSuccess",
    label: "Enter redirectUrl onSuccess",
    required: false,
  },

  {
    name: "redirectUrls.onError",
    placeholder: "Enter redirectUrl onError",
    label: "Enter redirectUrl onError",
    required: false,
  },

  {
    name: "redirectUrls.onReject",
    placeholder: "Enter redirectUrl onReject",
    label: "Enter redirectUrl onReject",
    required: false,
  },
];

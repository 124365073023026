import { TokenEntity } from "../../types";
import { getAllTokensThunk } from "./tokens.thunks";
import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../store.redux";

export interface TokensState {
  list: TokenEntity[];
  current?: TokenEntity | null;
  // cardList?: BonusCardEntity[];
}

const initialState: TokensState = {
  list: [],
  current: null,
};

export const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    setCurrentTokenAction(s: TokensState, a: Action<{ data: TokenEntity }>) {
      s.current = a.payload.data;
    },
    clearCurrentTokenAction(s, _a) {
      s.current = null;
    },
    setTokensListAction(
      s: TokensState,
      { payload: { refresh, data } }: Action<{ data: TokenEntity[] }>,
    ) {
      s.list = refresh ? data : s.list.concat(data);
    },
  },
  extraReducers: (builder) =>
    builder.addCase(getAllTokensThunk.fulfilled, (s, a) => {
      s.list = a.payload?.update
        ? a.payload.upend
          ? s.list.concat(a.payload.data)
          : a.payload.data.concat(s.list)
        : a.payload.data;
    }),
});

export const {
  setCurrentTokenAction,
  clearCurrentTokenAction,
  setTokensListAction,
} = tokensSlice.actions;

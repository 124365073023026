import { HttpApi } from "./HttpApi";
import { ApiEventEmitterService } from "./ApiEventEmitter.service";

export namespace LocalApi {
  export class Client extends ApiEventEmitterService {
    private static _api_global_prefix = "api/refme/";
    private static _client = HttpApi.create({
      baseURL: "http://localhost:3030/" + this._api_global_prefix,
    });
    public static get ref() {
      return this._client;
    }
  }
}

import { useTheme } from "styled-components";
import FlexBox from "./Flex";
import { Text } from "./Text";
import React from "react";

const Separator = ({ text }: { text?: string }) => {
  const theme = useTheme();

  return (
    <FlexBox
      $fillWidth
      $padding={"8px 0"}
      $fxDirection={"row"}
      $alignItems={"center"}
    >
      <FlexBox
        $borderBottom={`1px solid ${theme.lightBorderColor}`}
        $flex={1}
      ></FlexBox>

      {text && (
        <Text style={{ padding: "0 8px" }} color={theme.borderColorGreyMiddle}>
          {text}
        </Text>
      )}
      <FlexBox
        $borderBottom={`1px solid ${theme.lightBorderColor}`}
        $flex={1}
      ></FlexBox>
    </FlexBox>
  );
};
export default Separator;

export function numberToBalance(n?: number | any, decimalCount: number = 2) {
  if (!isNaN(Number(n))) {
    n = Number(n);
  } else if (typeof n !== "number") {
    return "0.".padEnd(decimalCount || 2, "0");
  }

  const [amount, decimals = "0"] = n.toFixed(decimalCount)?.split(".");

  return (
    amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
    `.${decimals.padEnd(decimalCount - decimals.length, "0")}`
  );
}

import { HttpApi } from "./HttpApi";
import { EvEmitter, EventEmitter1 } from "../services/EventEmitterService.serv";

export class ApiEventEmitterService {
  private static emitter = new EventEmitter1<HttpApi.DefaultEventsMap>({
    name: ApiEventEmitterService.name,
  });

  public static get emitterRef() {
    return this.emitter;
  }

  protected static eventListeners: HttpApi.CreateOptions["eventListeners"] = {
    onUnauthorized: (error) =>
      this.emitter.emit(HttpApi.ReservedEventName.onUnauthorized, error),
    onRefreshToken: (data) =>
      this.emitter.emit(HttpApi.ReservedEventName.onRefreshToken, data),
  };

  static onUnauthorized = (
    listener: EvEmitter.EvListener<HttpApi.DefaultEventsMap["onUnauthorized"]>,
  ) => {
    return this.emitter.on(HttpApi.ReservedEventName.onUnauthorized, listener);
  };
  static onRefreshToken = (
    listener: EvEmitter.EvListener<HttpApi.DefaultEventsMap["onRefreshToken"]>,
  ) => {
    return this.emitter.on(HttpApi.ReservedEventName.onRefreshToken, listener);
  };
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FlexBox from "../Atoms/Flex";
import { Layout, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { appLinks, AppRoteObj } from "./appLinks";
import { useAuthSelector } from "../../redux/selectors.redux";
import { Text } from "../Atoms/Text";
import { MobileMenuDrawer } from "./MobileMenuDrawer";

const { Header: AntdHeader } = Layout;

const Header = () => {
  const { user } = useAuthSelector();
  const nav = useNavigate();
  const [current, setCurrent] = useState<`path_${string}` | string>(
    `path_${appLinks[0].path}`,
  );

  useEffect(() => {
    appLinks.forEach((link) => {
      if (!current && window.location.pathname.includes(link.path)) {
        setCurrent(`path_${link.path}`);
      }
    });
    // eslint-disable-next-line
  }, []);
  const isVisible = (route: AppRoteObj) =>
    route.roles?.length && user?.role
      ? route.roles?.includes(user?.role)
      : true;

  const linksArr: MenuProps["items"] = appLinks
    ?.filter(isVisible)
    .map((item) => ({
      key: "path_" + item.path,
      label: item.label.toUpperCase(),
      onClick: () => {
        if (!item?.children?.length) {
          nav(item.path);

          setCurrent(`path_${item.path}`);
        }
      },
      children: item?.children?.filter(isVisible)?.map((child) => {
        return {
          key: "path_" + child.path,
          label: child.label.toUpperCase(),
          onClick: () => {
            nav(child.path);
            setCurrent(`path_${item.path}`);
          },
        };
      }),
    }));

  return (
    <HeaderBox
      $fxDirection="row"
      $justifyContent="flex-start"
      $alignItems="center"
      $padding={"0 16px"}
      // $gap={16}
      // $padding={"16px"}
    >
      <MobileMenuDrawer />{" "}
      <FlexBox
        $padding={"8px 16px 8px 16px"}
        $fillHeight
        $justifyContent={"space-between"}
      >
        <Text color={"#000"} $size={16} $weight={700}>
          {"Refme"}
        </Text>
        <Text color={"#000"} $size={13} $weight={600}>
          {user?.role}
        </Text>
      </FlexBox>
      {/*<AntdHeader*/}
      {/*  style={{*/}
      {/*    width: "100%",*/}
      {/*    // flex: 1,*/}
      {/*    alignItems: "center",*/}
      {/*    padding: "0 16px",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Menu*/}
      {/*    theme="dark"*/}
      {/*    mode="horizontal"*/}
      {/*    selectedKeys={[current]}*/}
      {/*    defaultSelectedKeys={[current]}*/}
      {/*    items={linksArr}*/}
      {/*    style={{ flex: 1, width: "100%" }}*/}
      {/*  />*/}
      {/*</AntdHeader>*/}
    </HeaderBox>
  );
};

export default Header;

const HeaderBox = styled(FlexBox)`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  font-size: 18px;
  min-height: 60px;
  background-color: ${(p) => p.theme.mainBgColor};
  border-bottom: 1px solid ${(p) => p.theme.lightBorderColor};

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

import {
  AppDate,
  HasLabelEntity,
  HasNameEntity,
  HasQRCode,
  HasRefCode,
  HasStatus,
  HasType,
  IBase,
  MaybeNull,
  OnlyUUID,
} from "./utils.types";
import { Path } from "react-hook-form";
import { OAuth } from "./auth/o-auth.namespace";

export namespace User {
  export enum StatusEnum {
    init = "init",
    trainee = "trainee",
    junior = "junior",
    middle = "middle",
    senior = "senior",
  }

  export enum RoleEnum {
    user = "user",
    system = "system",
    ambassador = "ambassador",
    vendor = "vendor",
    admin = "admin",
    manager = "manager",
    merchant = "merchant",
  }

  export enum AccessEnum {
    active = "active",
    ban = "ban",
    freeze = "freeze",
    wait_verification = "wait_verification",
  }
  export interface EntityBase<Role extends User.RoleEnum = User.RoleEnum>
    extends IBase,
      HasNameEntity,
      HasLabelEntity,
      HasStatus<User.StatusEnum>,
      HasRefCode,
      HasType<string>,
      HasQRCode {
    image?: string;
    reference?: string;

    email?: string;
    phone?: string;

    verifiedAt?: AppDate;

    role?: Role;
    accessStatus?: User.AccessEnum;
    access?: User.AccessEnum;

    verification_token?: string;
    verification_code?: string;
  }

  export interface Entity<Role extends User.RoleEnum = User.RoleEnum>
    extends EntityBase<Role> {
    referrer?: MaybeNull<Entity>;
    referrers?: MaybeNull<Entity>[];
    oAuth?: OAuth.Profile.Entity[];
  }

  export interface UpdateDto extends HasNameEntity, HasLabelEntity {
    imgUrl?: string;
    telegram?: {
      phone?: string;
      id?: string;
      chatId?: string;
      nickname?: string;
    };
    sexType?: string;
  }

  export interface LoggedEntity extends Entity {
    session?: IBase;
  }
}

export type UserBasePaths = Path<User.EntityBase>;

export type UserSearchPath = Path<User.EntityBase & OnlyUUID>;

export type UserSortPath = Path<User.EntityBase & IBase>;

export enum RefmeUserRoleEnum {
  user = "user",
  system = "system",
  ambassador = "ambassador",
  vendor = "vendor",
  admin = "admin",
  manager = "manager",
  merchant = "merchant",
}

export enum UserStatusEnum {
  init = "init",
  trainee = "trainee",
  junior = "junior",
  middle = "middle",
  senior = "senior",
  // sinior = "sinior",
}
export enum UserAccessStatus {
  ban = "ban",
  active = "active",
  freezee = "freezee",
}

export type UserEntity<Role extends User.RoleEnum = User.RoleEnum> =
  User.Entity<Role>;

export interface UpdateUserDto extends Omit<User.EntityBase, "email"> {
  _id: string;
}

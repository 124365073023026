import React from "react";
import { useUsersSelector } from "../../redux/selectors.redux";
import { BlockTemplate } from "./BlockTemplate";
import { TableWithActions } from "../Tables/TableWithActions";
import { UserEntity, UserSortPath } from "../../types";
import FlexBox, { FlexForm } from "../Atoms/Flex";
import { Button, Radio, Select } from "antd";
import { userAccessOptions, userStatusOptions } from "../../hardData/mlmRules";
import {
  userRoleOptions,
  userSortOptions,
} from "../../hardData/users.hard-data";
import { useAppForm, useLoaders } from "../../hooks";
import { GetUsersListApiQuery } from "../../api";
import { Label } from "../FormElements/Label";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { SortParamDto } from "../../types/utils.types";
import { useAppDispatch } from "../../redux/store.redux";
import { getAllUsersThunk } from "../../redux/users/users.thunks";
import getValueByPath from "../../shared/utils/getValueByPath.helper";
import { columns } from "../table-columns";

const selects = [
  { options: userStatusOptions, name: "status", label: "Status" },
  { options: userAccessOptions, name: "accessStatus", label: "Access" },
  { options: userRoleOptions, name: "role", label: "Role" },
] as const;

const UsersListBlock = () => {
  const usersList = useUsersSelector().list;
  const dispatch = useAppDispatch();
  const loaders = useLoaders<"refresh" | "filter" | "getMore">();
  const filter = useAppForm<GetUsersListApiQuery>();
  const sort = useAppForm<SortParamDto<UserSortPath>>();

  const onFilterValid = (fData: typeof filter.formValues) => {
    dispatch(
      getAllUsersThunk({
        params: {
          ...fData,
          sortParams: [sort.formValues],
        },
        onLoading: loaders.onLoading("filter"),
      }),
    );
  };
  const onSortValid = (fData: typeof sort.formValues) => {
    dispatch(
      getAllUsersThunk({
        params: {
          ...filter.formValues,
          sortParams: [fData],
        },
        onLoading: loaders.onLoading("filter"),
      }),
    );
  };

  return (
    <>
      <BlockTemplate title={"Filter"}>
        <FlexForm
          onReset={() => {
            filter.reset();
          }}
          onSubmit={filter.handleSubmit(onFilterValid)}
          $fillWidth
          $gap={6}
        >
          <FlexBox $fxDirection={"row"} $gap={8} $flexWrap={"wrap"} $fillWidth>
            {selects.map((select) => {
              return (
                <Label key={select.label} label={select.label}>
                  <Select
                    options={select.options as never}
                    onSelect={filter.registerOnSelect(select.name)}
                    style={{ width: 125 }}
                    size={"small"}
                    allowClear
                    onClear={() => filter.unregister(select.name)}
                    value={getValueByPath({
                      data: filter.formValues,
                      path: select.name,
                    })}
                    placeholder={`Select ${select.label.toLowerCase}`}
                  />
                </Label>
              );
            })}
          </FlexBox>

          <FormFooter isLoading={loaders.isLoading.filter} />
        </FlexForm>

        <FlexForm
          onReset={() => {
            sort.reset();
          }}
          onSubmit={filter.handleSubmit(onSortValid)}
          $fillWidth
          $gap={6}
        >
          <FlexBox $fxDirection={"row"} $gap={8} $alignItems={"flex-end"}>
            <Label label={"Sort param"}>
              <Select
                options={userSortOptions}
                placeholder={"Sort param"}
                size={"small"}
                style={{ width: 125 }}
                value={sort.formValues.dataPath}
                onSelect={sort.registerOnSelect("dataPath")}
              />
            </Label>

            <Label label={"Order"}>
              <Radio.Group
                id={"order"}
                value={sort.getValues().sortOrder}
                size={"small"}
                onChange={(ev) => {
                  sort.setValue("sortOrder", ev.target.value, {
                    shouldTouch: true,
                    shouldDirty: true,
                  });
                }}
              >
                <Radio.Button value={"DESC"}>
                  <SortDescendingOutlined />
                </Radio.Button>

                <Radio.Button value={"ASC"}>
                  <SortAscendingOutlined />
                </Radio.Button>
              </Radio.Group>
            </Label>
          </FlexBox>

          <FormFooter isLoading={loaders.isLoading.filter} />
        </FlexForm>
      </BlockTemplate>

      <FlexBox height={"16px"} />

      <BlockTemplate
        title={"Users list"}
        contentContainerStyles={{
          $padding: "0 16px 16px",
          $xsStyles: { $padding: "0 0 16px" },
        }}
      >
        <TableWithActions<UserEntity>
          data={[
            ...usersList,
            // ...usersList,
            // ...usersList,
            // ...usersList,
            // ...usersList,
            // ...usersList,
            // ...usersList,
          ]}
          columns={[
            columns.nameOrLabelColumn(),

            columns.defaultColumn({
              title: "Email",
              path: "email",
              canBeCopied: true,
            }),
            columns.defaultColumn({
              title: "Phone",
              path: "phone",
              canBeCopied: true,
            }),

            columns.defaultColumn({
              title: "Ref",
              path: "reference",
              canBeCopied: true,
            }),

            columns.referenceColumn({ canBeCopied: true }),
            columns.userAccessStatusColumn(),
            columns.userStatusColumn(),
            columns.userRoleColumn(),
          ]}
        />
      </BlockTemplate>
    </>
  );
};

const FormFooter = ({
  disabled,
  isLoading,
}: {
  isLoading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <FlexBox $fxDirection={"row"} $gap={8} $justifyContent={"flex-end"}>
      <Button
        type={"default"}
        htmlType={"reset"}
        size={"small"}
        disabled={isLoading}
      >
        {"Clear"}
      </Button>
      <Button
        type={"primary"}
        htmlType={"submit"}
        size={"small"}
        loading={isLoading}
        disabled={disabled}
      >
        {"Ok"}
      </Button>
    </FlexBox>
  );
};
export default UsersListBlock;

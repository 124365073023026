import ClientApi from "./Client.api";
import { AppResponse } from "../types/utils.types";
import { TransactionEntity } from "../types/transaction.types";

export interface GetTransactionsApiQuery {
  accountId?: string;
  walletId?: string;
  tokenId?: string;
}
export interface PerToPerTransferDto {
  fromId?: string;
  toId: string;
  tokenId: string;
  amount: number | string;
}
export class TransactionsApi extends ClientApi {
  // private static _client = ClientApi.getClient();
  private static _endpoints = this.endpoints.transactions;

  public static getList = (
    _?: undefined,
    params?: GetTransactionsApiQuery,
  ): Promise<AppResponse<TransactionEntity[]>> => {
    return this._client.get(this._endpoints.getList(), { params });
  };

  public static perToPerTransfer = (
    data?: PerToPerTransferDto,
    // params?: any,
  ): Promise<AppResponse<TransactionEntity>> => {
    return this._client.post(this._endpoints.createOnePerToPer(), data);
  };
}

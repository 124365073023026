import { createSlice } from "@reduxjs/toolkit";
import { getAllBalancesThunk, getAllWalletsThunk } from "./wallets.thunks";
import { BalanceAccount } from "../../types";
import { Wallet } from "../../types/wallets.types";
import { UUID } from "../../types/utils.types";

export interface WalletsState {
  wallet: {
    list: Wallet.Entity[];
    dataMap: Record<UUID, Wallet.Entity>;
    idsMap: Record<UUID, UUID[]>;
  };
  account: {
    list: BalanceAccount.Entity[];
    dataMap: Record<UUID, BalanceAccount.Entity>;
    idsMap: Record<UUID, UUID[]>;
  };
  card: {
    list: BalanceAccount.Entity[];
    dataMap: Record<UUID, BalanceAccount.Entity>;
    idsMap: Record<UUID, UUID[]>;
  };
}
const initState: WalletsState = {
  wallet: {
    list: [],
    dataMap: {},
    idsMap: {},
  },
  account: {
    list: [],
    dataMap: {},
    idsMap: {},
  },
  card: {
    list: [],
    dataMap: {},
    idsMap: {},
  },
};
export const walletsSlice = createSlice({
  name: "wallets",
  reducers: {},
  initialState: initState,
  extraReducers: (builder) =>
    builder
      .addCase(getAllBalancesThunk.fulfilled, (st, a) => {
        const levels = a.payload.params?.levels;

        if (levels) {
          a.payload.data.forEach((item) => {
            const level = item.level;
            level && level in st && st[level].list.push(item);
          });
        }
      })
      .addCase(getAllWalletsThunk.fulfilled, (st, a) => {
        st.wallet.list = st.wallet.list.concat(a.payload.data);
      }),
});

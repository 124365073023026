import {
  CreateMlmRuleDto,
  MlmRuleDtoKeyEnum,
  MlmRuleTypeEnum,
  MlmRuleValueTypeEnum,
} from "../../types";
import { CreatedModal } from "../../providers/ModalProvider";
import { useAppForm, useLoaders } from "../../hooks";
import { useAppDispatch } from "../../redux/store.redux";
import React, { useId } from "react";
import ModalBase from "../Atoms/ModalBase";
import { FlexBox, FlexForm } from "../Atoms/Flex";
import {
  createMlmRuleThunk,
  updateMlmRuleThunk,
} from "../../redux/mlm-rules/mlm-rules.thunks";
import { AutoComplete, Button, Input, Select } from "antd";
import { Label } from "../FormElements/Label";
import {
  sourceVolumesOptions,
  userStatusOptions,
  walletStatusOptions,
} from "../../hardData/mlmRules";
import { ruleValueTypeOptions } from "../Blocks/Admin";
import { UserStatusTag } from "../Atoms/tags/user-tags";
import { WalletStatusTag } from "../Atoms/tags/bal-account-tags";
import { Text } from "../Atoms/Text";

const CreateMlmRuleModal = ({
  onClose,
  compId,
  defaultValues,
  customKeysSet,
  hasUserStatus,
  hasWalletStatus,
  hasThreshold,
}: {
  defaultValues?: Partial<CreateMlmRuleDto> & { _id?: string };
  customKeysSet?: string[];
  hasUserStatus?: boolean;
  hasWalletStatus?: boolean;

  hasThreshold?: boolean;
} & CreatedModal) => {
  const dispatch = useAppDispatch();
  const loaders = useLoaders<"create">();
  const id = useId();
  const form = useAppForm<CreateMlmRuleDto & { _id?: string }>({
    defaultValues: {
      ...defaultValues,
      valueType: MlmRuleValueTypeEnum.percentage,
    },
  });
  const FV = form.formValues;

  return (
    <ModalBase
      title={
        defaultValues?._id ? "Update referral rule" : "Create referral rule"
      }
      formId={id}
      onClose={onClose}
      compId={compId}
      isLoading={loaders.hasLoading}
    >
      <FlexForm
        $gap={8}
        // width={"360px"}
        id={id}
        onReset={onClose}
        onSubmit={form.handleSubmit((fData) => {
          return fData?._id
            ? dispatch(
                updateMlmRuleThunk({
                  data: { ...fData, _id: fData?._id },
                }),
              )
            : dispatch(
                createMlmRuleThunk({
                  data: { ...fData },
                  onLoading: loaders.onLoading("create"),
                }),
              );
        })}
      >
        {FV[MlmRuleDtoKeyEnum.type] === MlmRuleTypeEnum.referral && (
          <Label label={"Depth"}>
            <Input
              placeholder={"Depth"}
              // value={FV[MlmRuleDtoKeyEnum.depth]}
              {...(form.registerWithValue("depth") as object)}
              disabled
            />
          </Label>
        )}

        {hasUserStatus && (
          <FlexBox $fxDirection={"row"} $gap={8} $fillWidth $padding={"8px 0"}>
            <Text>{"User status"}</Text>
            <UserStatusTag status={FV.userStatus} />
          </FlexBox>
        )}
        {hasUserStatus && (
          <Label label={"User status"}>
            <Select
              options={userStatusOptions}
              value={FV[MlmRuleDtoKeyEnum.userStatus]}
              style={{ width: "100%" }}
              placeholder={"User status"}
              disabled
              onSelect={(value) => {
                form.setValue(MlmRuleDtoKeyEnum.userStatus, value, {
                  shouldTouch: true,
                  shouldDirty: true,
                });
              }}
            />
          </Label>
        )}
        {hasWalletStatus && (
          <FlexBox $fxDirection={"row"} $gap={8} $fillWidth $padding={"8px 0"}>
            <Text>{"Wallet status"}</Text>
            <WalletStatusTag status={FV.walletStatus} />
          </FlexBox>
        )}
        {hasWalletStatus && (
          <Label label={"Wallet status"}>
            <Select
              options={walletStatusOptions}
              value={FV[MlmRuleDtoKeyEnum.walletStatus]}
              style={{ width: "100%" }}
              placeholder={"Wallet status"}
              disabled
              onSelect={(value) => {
                form.setValue(MlmRuleDtoKeyEnum.walletStatus, value, {
                  shouldTouch: true,
                  shouldDirty: true,
                });
              }}
            />
          </Label>
        )}

        <Label label={"Source volume"}>
          <Select
            options={sourceVolumesOptions}
            value={FV.sourceVolume}
            placeholder={"Source volume"}
            style={{ width: "100%" }}
            allowClear
            onClear={() => {
              form.unregister(MlmRuleDtoKeyEnum.sourceVolume);
            }}
            onSelect={(value) => {
              form.setValue(MlmRuleDtoKeyEnum.sourceVolume, value, {
                shouldTouch: true,
                shouldDirty: true,
              });
            }}
          />
        </Label>

        <Label label={"Value type"}>
          <Select
            options={ruleValueTypeOptions}
            value={FV.valueType}
            onSelect={(value) => {
              form.setValue(MlmRuleDtoKeyEnum.valueType, value, {
                shouldTouch: true,
                shouldDirty: true,
              });
            }}
            style={{ width: "100%" }}
            placeholder={"Value type"}
          />
        </Label>

        <Label label={"Value"}>
          <Input
            placeholder={"Value"}
            // value={FV.value}
            {...form.registerWithValue("value")}
          />
        </Label>

        {hasThreshold && (
          <>
            <Label label={"Threshold type"}>
              <Select
                options={ruleValueTypeOptions}
                value={FV.valueType}
                onSelect={(value) => {
                  form.setValue(MlmRuleDtoKeyEnum.valueType, value, {
                    shouldTouch: true,
                    shouldDirty: true,
                  });
                }}
                style={{ width: "100%" }}
                placeholder={"Threshold type"}
              />
            </Label>

            <Label label={"Threshold"}>
              <Input
                placeholder={"Threshold"}
                value={FV.threshold}
                {...form.register("threshold")}
              />
            </Label>
          </>
        )}

        <Label label={"Custom key"}>
          <AutoComplete
            onChange={(value) => {
              form.setValue("customKey", value.target, {
                shouldTouch: true,
                shouldDirty: true,
              });
            }}
            allowClear
            // value={{ target: FV.customKey }}
            placeholder={"Custom key"}
            options={customKeysSet?.map((key) => {
              return { value: key, label: key };
            })}
            onSelect={(value) => {
              form.setValue("customKey", value.target, {
                shouldTouch: true,
                shouldDirty: true,
              });
            }}
          />
        </Label>

        <FlexBox
          $fxDirection={"row"}
          $gap={12}
          $padding={"16px 0 8px"}
          $justifyContent={"space-between"}
        >
          <Button size={"middle"} htmlType={"reset"}>
            {"Cancel"}
          </Button>
          <Button
            size={"middle"}
            loading={loaders.isLoading.create}
            type={"primary"}
            htmlType={"submit"}
          >
            {"Ok"}
          </Button>
        </FlexBox>
      </FlexForm>
    </ModalBase>
  );
};

export default CreateMlmRuleModal;

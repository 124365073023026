import { BaseTableProps } from "../components/Atoms/Table";
import {
  MlmRuleDepthEnum,
  MlmRuleDtoKeyEnum,
  MlmRuleEntity,
  MlmRuleSourceVolumeEnum,
  UserAccessStatus,
  UserStatusEnum,
} from "../types";
import { EnumToSelectOptions } from "../shared/utils";
import * as columns from "../components/table-columns/tableColumns";
import { Wallet } from "../types/wallets.types";

export const depthsOptions = MlmRuleDepthEnum;
export const userStatusOptions = EnumToSelectOptions(UserStatusEnum);
export const userAccessOptions = EnumToSelectOptions(UserAccessStatus);
export const walletStatusOptions = EnumToSelectOptions(Wallet.StatusEnum);
export const sourceVolumesOptions = EnumToSelectOptions(
  MlmRuleSourceVolumeEnum,
);

export const baseMlmRuleColumns: BaseTableProps<MlmRuleEntity>["columns"] = [
  {
    title: "Value",
    path: MlmRuleDtoKeyEnum.value,
    width: "180px",
  },
  columns.mlmRuleValueTypeColumn(),
  columns.mlmRuleSourceVolumeColumn(),
  columns.mlmRuleThresholdTypeColumn(),
  {
    title: "Threshold",
    path: MlmRuleDtoKeyEnum.threshold,
    width: "180px",
  },
  {
    title: "Custom key",
    path: MlmRuleDtoKeyEnum.customKey,
    width: "180px",
  },
  columns.mlmRuleWalletStatusColumn(),
  columns.userStatusColumn(),
  columns.deleteStatusColumn(),
  { title: "Key", path: "queryString", width: "180px" },
];

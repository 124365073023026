import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/store.redux";
import {
  getCurrentUserThunk,
  logInByEmailThunk,
} from "../../../redux/auth/auth.thunks";
import { BlockTemplate } from "../BlockTemplate";
import { FlexForm } from "../../Atoms/Flex";
import { Text } from "../../Atoms/Text";
import { logInInputsData } from "../../data/formsData";
import FormInput from "../../FormElements/FormInput";
import { Button } from "antd";
import React from "react";
import { useAppForm } from "../../../hooks";
import { AppAuth } from "../../../types/auth/app-auth.namespace";

const LogInUserBlock = () => {
  const navTo = useNavigate();

  const { registerWithValue, handleSubmit } =
    useAppForm<AppAuth.LogInByEmailDto>();
  const dispatch = useAppDispatch();

  const onSubmit = (data: AppAuth.LogInByEmailDto) => {
    dispatch(
      logInByEmailThunk({
        data,
        params: { withUnverified: true },
        onSuccess: () => {
          dispatch(getCurrentUserThunk({}));
        },
      }),
    );
  };
  return (
    <BlockTemplate title={"Welcome to Refme"}>
      <FlexForm
        $gap={16}
        onSubmit={handleSubmit(onSubmit)}
        width={"360px"}
        $maxWidth={"100%"}
        $alignItems={"stretch"}
      >
        <Text $size={15} $weight={500} $align={"center"}>
          {"Log In"}
        </Text>

        {logInInputsData.map((props, index) => (
          <FormInput
            {...props}
            key={index}
            {...registerWithValue(props.name)}
          />
        ))}

        <Button type={"primary"} htmlType={"submit"}>
          {"Log In"}
        </Button>

        <Button type={"text"} onClick={() => navTo("/register")}>
          {"Registration"}
        </Button>
      </FlexForm>
    </BlockTemplate>
  );
};

export default LogInUserBlock;

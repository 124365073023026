import { createAppAsyncThunk } from "../../shared/utils";
import UsersApi from "../../api/Users.api";

export enum UsersThunkType {
  getAll = "users/getAllThunk",
}
export const getAllUsersThunk = createAppAsyncThunk(
  UsersThunkType.getAll,
  UsersApi.getAll,
);

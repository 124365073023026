import React, { useEffect } from "react";
import { useAppDispatch } from "../../../redux/store.redux";
import { getAllOAuthConnectionsThunk } from "../../../redux/auth/o-auth/o-autn.thunks";
import FlexBox from "../../Atoms/Flex";
import { BlockTemplate } from "../BlockTemplate";
import { useOAuthSelector } from "../../../redux/selectors.redux";
import { TableWithActions } from "../../Tables/TableWithActions";
import { ObjectEntries } from "../../../shared/utils";
import { Tag } from "antd";
import { useModalService } from "../../../providers/ModalProvider";
import { CreateOAuthConnectionModal } from "./CreateOAuthConnectionModal";

export const OAuthConnectionBlock = () => {
  const state = useOAuthSelector();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllOAuthConnectionsThunk({}));
  }, [dispatch]);

  const modalS = useModalService();
  return (
    <FlexBox>
      <BlockTemplate title={"OAuth connections list"}>
        <TableWithActions
          data={state.list}
          onAddPress={() => {
            modalS.open(CreateOAuthConnectionModal);
          }}
          columns={[
            {
              title: "Label",
              getData: (data) => {
                return data.label;
              },
            },

            {
              title: "Public key",
              getData: (data) => {
                return data.publicKey;
              },
            },

            {
              title: "Label",
              getData: (data) => {
                return data.label;
              },
            },
            {
              title: "Endpoints",
              getData: (data) => {
                return (
                  <FlexBox $fxDirection={"row"} $gap={4} $flexWrap={"wrap"}>
                    {ObjectEntries(data.endpoints ?? {}).map(([key, val]) => {
                      return val && <Tag key={key}>key</Tag>;
                    })}
                  </FlexBox>
                );
              },
            },
          ]}
        />
      </BlockTemplate>
    </FlexBox>
  );
};

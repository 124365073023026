import { createSlice } from "@reduxjs/toolkit";
import { TransactionEntity } from "../../types/transaction.types";
import * as thunks from "./transactions.thunks";

export interface TransactionsState {
  list: TransactionEntity[];
}

const state: TransactionsState = {
  list: [],
};
export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: state,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(
        thunks.getAllTransactions.fulfilled,
        (st, { payload: { update, data } }) => {
          st.list = !update ? data : st.list.concat(data);
        },
      )
      .addCase(
        thunks.transferPerToPer.fulfilled,
        (st, { payload: { data } }) => {
          st.list = st.list.concat([data]);
        },
      ),
});

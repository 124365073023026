import sprite from "./spriteSvg.svg";

export const iconId = {
  EXPENSE: "minus",
  INCOME: "plus",
  TRANSFER: "transfer",
  SmallArrowDown: "SmallArrowDown",
  SmallArrowLeft: "SmallArrowLeft",
  SmallArrowRight: "SmallArrowRight",
  SmallArrowUp: "SmallArrowUp",
  StackedLineChart: "StackedLineChart",
  switchOn: "darkMode",
  switchOff: "lightMode",
  actionsH: "actionsH",
  actionsV: "actionsV",
  addToCart: "addToCart",
  AddToList: "AddToList",
  AddToListOutlined: "AddToListOutlined",
  admin: "admin",
  archive: "archive",
  arrowDown: "arrowDown",
  arrowLeft: "arrowLeft",
  arrowRight: "arrowRight",
  assignment: "assignment",
  assignmentBack: "assignmentBack",
  assignmentBackOutlined: "assignmentBackOutlined",
  assignmentError: "assignmentError",
  assignmentErrorOutlined: "assignmentErrorOutlined",
  assignmentIn: "assignmentIn",
  assignmentInOutlined: "assignmentInOutlined",
  assignmentOk: "assignmentOk",
  assignmentOkOutlined: "assignmentOkOutlined",
  assignmentOutlined: "assignmentOutlined",
  assignmentPersonOutlined: "assignmentPersonOutlined",
  bank: "bank",
  bankOutlined: "bankOutlined",
  bookMarkAdd: "bookMarkAdd",
  brand: "brand",
  burger: "burger",
  burgerOpen: "burgerOpen",
  calendar: "calendar",
  cardOk: "cardOk",
  cashFlow: "cashFlow",
  change: "change",
  chat: "chat",
  chatNew: "chatNew",
  checkBoxMinus: "checkBoxMinus",
  checkBoxOff: "checkBoxOff",
  checkBoxOn: "checkBoxOn",
  clear: "clear",
  close: "close",
  copy: "copy",
  darkMode: "darkMode",
  dblArrowRight: "dblArrowRight",
  delete: "delete",
  dollar: "dollar",
  done: "done",
  doneAll: "doneAll",
  download: "download",
  edit: "edit",
  email: "email",
  error: "error",
  feed: "feed",
  feedback: "feedback",
  filterOff: "filterOff",
  filterOn: "filterOn",
  folder: "folder",
  gallery: "gallery",
  home: "home",
  info: "info",
  inspect: "inspect",
  lightMode: "lightMode",
  link: "link",
  list: "list",
  lock: "lock",
  lockPerson: "lockPerson",
  lock_O: "lock_O",
  logOut: "logOut",
  mail: "mail",
  mailOutlined: "mailOutlined",
  menu: "menu",
  minus: "minus",
  minus2: "minus2",
  notifications: "notifications",
  notificationsO: "notificationsO",
  openInNew: "openInNew",
  pageGrid1: "pageGrid-1",
  pageGrid2: "pageGrid-2",
  partners: "partners",
  person: "person",
  person2: "person-2",
  personOutlined: "personOutlined",
  persons: "persons",
  phoneOutlined: "phoneOutlined",
  plus: "plus",
  present: "present",
  primary: "primary",
  print: "print",
  qrCodeScan: "qrCodeScan",
  receipt: "receipt",
  refresh: "refresh",
  refund: "refund",
  removeDone: "removeDone",
  rune: "tune",
  search: "search",
  send: "send",
  setProfile: "setProfile",
  settings: "settings",
  settingsO: "settingsO",
  share: "share",
  signIn: "signIn",
  signUp: "signUp",
  sort: "sort",
  statistics: "statistics",
  storage: "storage",
  storageOutlined: "storageOutlined",
  success: "success",
  time: "time",
  transactions: "transactions",
  ttn: "ttn",
  tune: "tune",
  unArchive: "unArchive",
  upload: "upload",
  visibilityOff: "visibilityOff",
  visibilityOn: "visibilityOn",
  wallet: "wallet",
  walletOutlined: "walletOutlined",
  warning: "warning",
  zoomMinus: "zoomMinus",
  zoomPlus: "zoomPlus",
  logIn: "logIn",
};

export type SpriteIconId = keyof typeof iconId & string;

export default sprite;

import { User, UserEntity } from "./user.types";
import { IBase, Keys, MaybeArr } from "./utils.types";
import { RevenueEventEntity } from "./app-events.types";
import { Wallet } from "./wallets.types";

export enum MlmRuleTypeEnum {
  volume = "volume",
  threshold = "threshold",
  referral = "referral",
  extra = "extra",
  personal = "personal",
}

export const MlmRuleDepthEnum = [0, 1, 2, 3, 4, 5, 6, 7, 8] as const;
export type MlmRuleDepthType = MaybeArr<typeof MlmRuleDepthEnum>;
export enum MlmRuleSourceVolumeEnum {
  slot = "slot",
  order = "order",
  personal = "personal",
  branch = "branch",
  network = "network",
}

export enum MlmRuleThresholdTypeEnum {
  amount = "amount",
  quantity = "quantity",
}
export enum MlmRuleValueTypeEnum {
  percentage = "percentage",
  amount = "amount",
  index = "index",
}
export enum MlmRuleDtoKeyEnum {
  type = "type",
  depth = "depth",
  thresholdType = "thresholdType",
  threshold = "threshold",
  // indexType = "indexType",
  // indexValue = "indexValue",
  valueType = "valueType",
  value = "value",
  walletStatus = "walletStatus",
  userStatus = "userStatus",
  customKey = "customKey",
  sourceVolume = "sourceVolume",
  targetVolumes = "targetVolumes",
}

export type MlmRuleDtoKey = Keys<typeof MlmRuleDtoKeyEnum>;
export interface MlmRuleBaseFields {
  type?: MlmRuleTypeEnum;

  queryString?: string;

  valueType?: MlmRuleValueTypeEnum;
  value: string | number;

  targetVolumes?: MlmRuleSourceVolumeEnum[];

  sourceVolume?: MlmRuleSourceVolumeEnum;
  thresholdType?: MlmRuleThresholdTypeEnum;
  threshold?: number | string;

  depth?: number;

  walletStatus?: Wallet.Entity["status"];
  userStatus?: User.Entity["status"];
  customKey?: string;
}
export interface MlmRuleEntity extends IBase, MlmRuleBaseFields {
  group?: MlmRulesGroupEntity;
  events?: RevenueEventEntity[];
}

export interface MlmRuleBaseDto extends MlmRuleBaseFields {
  groupId: string;
  queryString?: string;

  // [MlmRuleDtoKeyEnum.type]: MlmRuleTypeEnum;
  //
  // [MlmRuleDtoKeyEnum.sourceVolume]?: MlmRuleSourceVolumeEnum;
  // [MlmRuleDtoKeyEnum.thresholdType]?: MlmRuleThresholdTypeEnum;
  // [MlmRuleDtoKeyEnum.threshold]?: number | string;
  //
  // [MlmRuleDtoKeyEnum.valueType]: MlmRuleValueTypeEnum;
  // [MlmRuleDtoKeyEnum.value]: number | string;
  //
  // [MlmRuleDtoKeyEnum.walletStatus]?: WalletStatusEnum;
  // [MlmRuleDtoKeyEnum.userStatus]?: UserStatusEnum;
  //
  // [MlmRuleDtoKeyEnum.depth]?: MlmRuleDepthEnum;
  //
  // [MlmRuleDtoKeyEnum.customKey]?: string;
}
export interface CreateMlmRuleDto extends MlmRuleBaseDto {}

export interface UpdateMlmRuleDto
  extends Omit<CreateMlmRuleDto, "groupId" | "queryString"> {
  _id: string;
}

export interface CreateMlmRulesGroupDto {
  label?: string;
  scope?: string;
  rules?: CreateMlmRuleDto[];
}

export interface MlmRulesGroupEntity extends IBase {
  owner?: UserEntity;
  rules?: MlmRuleEntity[];
  label?: string;
  scope?: string;
}

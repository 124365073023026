import ClientApi from "../Client.api";
import { ApiAsyncResponse } from "../types.api";
import { AppAuth } from "../../types/auth/app-auth.namespace";

export class SessionsApi {
  private static api = ClientApi.getClient();
  private static endpoints = ClientApi.endpoints.sessions;

  public static startForService = (
    data?: AppAuth.Session.StartForServiceDto,
  ): ApiAsyncResponse<AppAuth.Session.Entity<AppAuth.Session.Type.service>> => {
    return this.api.post(this.endpoints.startService(), data);
  };

  public static getAll = <T extends AppAuth.Session.Type>(
    _?: undefined,
    params?: AppAuth.Session.GetAllApiQuery<T>,
  ): ApiAsyncResponse<AppAuth.Session.Entity<T>[]> => {
    return this.api.get(this.endpoints.getAll(), {
      params: {
        type: "service",
        ...params,
      },
    });
  };
}

import { EnumToSelectOptions } from "../shared/utils";
import {
  RefmeUserRoleEnum,
  UserAccessStatus,
  UserSearchPath,
  UserSortPath,
  UserStatusEnum,
} from "../types";

export const userStatusOptions = EnumToSelectOptions(UserStatusEnum);
export const userAccessOptions = EnumToSelectOptions(UserAccessStatus);
export const userRoleOptions = EnumToSelectOptions(RefmeUserRoleEnum);

const searchParams: Record<string, UserSearchPath> = {
  Email: "email",
  Phone: "phone",
  Reference: "reference",
  "First name": "name.first",
  "First second": "name.second",
  "Base label": "label.base",
};
const sortParams: Record<string, UserSortPath> = {
  ...searchParams,
  "Created at": "createdAt",
  "Deleted at": "deletedAt",
  "Updated at": "updatedAt",
};
export const userSortOptions = EnumToSelectOptions({
  ...searchParams,
  ...sortParams,
});
export const userSearchOptions = EnumToSelectOptions(searchParams);

import { useModalService } from "../../../providers/ModalProvider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMlmRulesSelector } from "../../../redux/selectors.redux";
import { useAppDispatch } from "../../../redux/store.redux";
import {
  getMlmRulesListThunk,
  removeMlmRuleThunk,
} from "../../../redux/mlm-rules/mlm-rules.thunks";
import {
  MlmRuleDtoKeyEnum,
  MlmRuleEntity,
  MlmRuleTypeEnum,
} from "../../../types";
import { BlockTemplate } from "../BlockTemplate";
import CreateMlmRuleModal from "../../modals/CreateMlmRuleModal";
import {
  deleteStatusColumn,
  mlmRuleTargetVolumesColumn,
  mlmRuleThresholdTypeColumn,
  mlmRuleValueTypeColumn,
} from "../../table-columns/tableColumns";
import { Button, Select } from "antd";
import { TableWithActions } from "../../Tables/TableWithActions";
import { useAppParams, useLoaders } from "../../../hooks";
import { sourceVolumesOptions } from "../../../hardData/mlmRules";
import FlexBox from "../../Atoms/Flex";
import { Text } from "../../Atoms/Text";
import { ReloadOutlined } from "@ant-design/icons";

export const VolumeRulesBlock = () => {
  const modalS = useModalService();
  const customKeysSet = useMlmRulesSelector().customKeysSet;
  const loaders = useLoaders<"list" | "deleting" | "updating">();
  const rules = useMlmRulesSelector().lists.volume;
  const dispatch = useAppDispatch();
  const params = useAppParams();
  const [customKey, setCustomKey] = useState<string | undefined>();

  const loadData = useCallback(() => {
    dispatch(
      getMlmRulesListThunk({
        onLoading: loaders.onLoading("list"),
        params: {
          type: MlmRuleTypeEnum.volume,
        },
      }),
    );
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [dispatch]);

  const { dataMap } = useMemo((): {
    dataMap: Map<MlmRuleEntity["sourceVolume"], MlmRuleEntity[]>;
  } => {
    const map = new Map<MlmRuleEntity["sourceVolume"], MlmRuleEntity[]>();

    rules?.forEach((rule) => {
      const mapKey = rule?.sourceVolume;
      if (!mapKey) {
        return;
      }
      const current = map.get(mapKey);
      if (current) {
        current?.push(rule);
        map.set(mapKey, current);
      } else {
        map.set(mapKey, [rule]);
      }
    });

    return {
      dataMap: map,
    };
  }, [rules]);

  return (
    <BlockTemplate
      contentContainerStyles={{
        $padding: "0 16px 16px",
        $xsStyles: { $padding: "0 0 16px" },
      }}
      header={
        <FlexBox
          $fxDirection={"row"}
          $alignItems={"center"}
          $justifyContent={"space-between"}
          $gap={10}
          style={{ fontSize: "14px", fontWeight: 600 }}
          $flexWrap={"wrap"}
        >
          <Text style={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {"Personal rules"}
          </Text>

          <Select
            value={customKey}
            style={{ width: "210px" }}
            placeholder={"Select key"}
            options={customKeysSet.map((c) => {
              return { value: c, label: c };
            })}
            onSelect={(value, option) => {
              setCustomKey(value);
            }}
          />

          <Button
            loading={loaders.isLoading.list}
            onClick={() => {
              loadData();
            }}
          >
            <ReloadOutlined size={16} />
          </Button>
        </FlexBox>
      }
    >
      {sourceVolumesOptions.map(({ value }) => {
        const data = value ? dataMap.get(value) : undefined;

        return (
          <TableWithActions<MlmRuleEntity>
            key={value}
            data={data}
            onAddPress={() => {
              params.groupId &&
                modalS.open(CreateMlmRuleModal, {
                  defaultValues: {
                    // [MlmRuleDtoKeyEnum.userStatus]: status,
                    groupId: params.groupId,
                    type: MlmRuleTypeEnum.volume,
                    sourceVolume: value,
                  },
                  hasThreshold: true,
                });
            }}
            onEdit={(data) => {
              modalS.open(CreateMlmRuleModal, {
                defaultValues: data,
                hasThreshold: true,
              });
            }}
            onRemove={(data) => {
              dispatch(removeMlmRuleThunk({ params: { id: data._id } }));
            }}
            label={"Source: " + value}
            isDeleting={loaders.isLoading.deleting}
            columns={[
              mlmRuleThresholdTypeColumn(),
              {
                title: "Threshold",
                path: MlmRuleDtoKeyEnum.threshold,
                width: "180px",
              },
              { title: "Value", path: "value" },
              mlmRuleValueTypeColumn(),
              mlmRuleTargetVolumesColumn(),
              { title: "Custom key", path: "customKey" },
              deleteStatusColumn(),
            ]}
          />
        );
      })}
    </BlockTemplate>
  );
};

import { AxiosInstance } from "axios";

import { ConfigService } from "../services/ConfigService.serv";
import APP_CONFIGS from "../configs/APP_CONFIGS";
import { ApiEventEmitterService } from "./ApiEventEmitter.service";
import { HttpApi } from "./HttpApi";

const PORT_LOCAL_API = 4500;

export enum BaseApiProviders {
  LOCALHOST = "LOCALHOST",
  RAILWAY = "RAILWAY",
  DEV = "DEV",
}
export const API_KEY = ConfigService.getApiKey();

export const BASE_API_PROVIDER = ConfigService.getApiProvider();

export default class ClientApi extends ApiEventEmitterService {
  public static g_prefix = "api/refme";
  public static endpoints = APP_CONFIGS.endpoints;
  protected static BASE_URL_LOCALHOST = `http://localhost:${PORT_LOCAL_API}/${this.g_prefix}`;
  protected static BASE_URL_RAILWAY = `https://mia-erp-dev.up.railway.app/${this.g_prefix}`;
  protected static BASE_URL_DEV = `https://mia-erp-dev.up.railway.app/${this.g_prefix}`;
  protected static apiKey = API_KEY;

  protected static _client = HttpApi.create({
    baseURL: ConfigService.isDevMode
      ? this.BASE_URL_LOCALHOST
      : this.BASE_URL_DEV,
    eventListeners: this.eventListeners,
    withCredentials: true,
  });

  // private static client = createBaseClient({
  //   apiKey: this.apiKey,
  //   baseURL: `BASE_URL_${this.BASE_API_PROVIDER}`,
  // });

  public static get hasApiKey(): boolean {
    return !!this._client.defaults.headers["X-Token"];
  }

  public static setApiKeyInterceptor() {
    this._client.interceptors.request.use(
      (req) => req,
      (err) => err,
    );
  }

  public static updateConfigs(): {
    API_KEY: string | null;
    BASE_API_PROVIDER: BaseApiProviders | null;
  } {
    const API_KEY = ConfigService.getApiKey();
    const BASE_API_PROVIDER = ConfigService.getApiProvider();

    API_KEY && this.setApiKey(API_KEY);
    BASE_API_PROVIDER && this.setBaseApiProvider(BASE_API_PROVIDER);

    return {
      API_KEY,
      BASE_API_PROVIDER,
    };
  }

  public static getClient(): AxiosInstance {
    return this._client;
  }
  public static setToken(token?: string) {
    this._client.defaults.headers.Authorization = `Bearer ${token}`;
  }

  public static unsetToken() {
    this._client.defaults.headers.Authorization = ``;
  }

  public static setApiKey(token: string) {
    this._client.defaults.headers["x-token"] = `${token}`;
  }

  public static unsetApiKey() {
    this._client.defaults.headers["x-token"] = ``;
  }

  public static setLocalhostApiProvider() {
    this._client.defaults.baseURL = this.BASE_URL_LOCALHOST;
  }

  public static setBaseApiProvider(key: keyof typeof BaseApiProviders) {
    this._client.defaults.baseURL = this[`BASE_URL_${key}`];
  }
}

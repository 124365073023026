import { FieldValues, Path, PathValue } from "react-hook-form";

function getValueByPath<
  Data extends FieldValues = any,
  EntityPath extends Path<Data> = any,
  Return = PathValue<Data, EntityPath>,
  // PathValue<Data, EntityPath>
>({
  data,
  path,
  separator = ".",
  returnDataType = "string",
}: {
  data: object | Data;
  path: EntityPath;
  separator?: "." | "/" | "_" | "-" | "*" | "+";
  returnDataType?: "string" | "number" | "object";
}): Return {
  const keys = path.split(separator);
  const [key, ...rest] = keys;
  const value = data?.[key as keyof typeof data];

  if (rest.length === 0) {
    return value;
  }

  if (typeof value !== "object") {
    return value;
  }

  return getValueByPath<(typeof data)[keyof typeof data]>({
    data: value,
    path: rest.join(separator),
    separator,
    returnDataType,
  });
}

export default getValueByPath;

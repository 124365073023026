import { useModalService } from "../../../providers/ModalProvider";
import { useEffect, useRef, useState } from "react";
import { useMlmRulesSelector } from "../../../redux/selectors.redux";
import { useAppDispatch } from "../../../redux/store.redux";
import { getMlmRulesListThunk } from "../../../redux/mlm-rules/mlm-rules.thunks";
import { MlmRuleTypeEnum } from "../../../types";
import { BlockTemplate } from "../BlockTemplate";

export const ExtraRulesBlock = () => {
  const modalS = useModalService();
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number>(0);
  const categorySet = useRef(new Set<string>());
  const rules = useMlmRulesSelector().lists;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getMlmRulesListThunk({
        refresh: true,
        params: {
          types: [MlmRuleTypeEnum.extra],
        },
      }),
    );
  }, [dispatch]);

  return <BlockTemplate title={"Extra rules"}></BlockTemplate>;
};

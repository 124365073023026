export const globals = {};

export const lightTheme = {
  accentColor: "#507DBC",
  darkAccent: "#04080F",
  mildBlue: "#A1C6EA",
  brightBlue: "#405cf5",

  mainBgColor: "#ffffff",
  secondaryBgColor: "rgba(189, 200, 255, 0.09)",

  backdropDarkColor: "rgba(0,0,0,0.15)",
  grey: "rgba(239, 245, 255, 0.694)",

  mainTextColor: "rgb(28,28,28)",
  lightBorderColor: "rgb(210,210,210)",
  borderColorGreyMiddle: "rgb(150,150,150)",
  borderRadius: "4px",

  tableHeadClr: "rgb(237,242,255)",

  colors: {
    white: "rgba(252, 253, 255, 0.937)",
    black: "#000",
    default: "rgb(154, 154, 154)",
    info: "#005186",
    success: "#08850c",
    warning: "#a28a03",
    error: "#8f1306",
    primary: "#cdcdcd",
  },
  gradients: {
    info: `linear-gradient(90deg, ${"rgba(0,81,134,0.25)"} 0%, ${"transparent"} 100%)`,
    success: `linear-gradient(90deg, ${"rgba(0,108,4,0.25)"} 0%, ${"transparent"} 100%)`,
    error: `linear-gradient(90deg, ${"rgba(143,19,6,0.25)"} 0%, ${"transparent"} 100%)`,
    warning: `linear-gradient(90deg, ${"rgba(162,138,3,0.25)"} 0%, ${"transparent"} 100%)`,
    default: `linear-gradient(90deg, ${"rgba(154,154,154,0.25)"} 0%, ${"transparent"} 100%)`,
  },
};

type ITheme = typeof lightTheme;

declare module "styled-components" {
  export interface DefaultTheme extends ITheme {}
}

import {
  UsePreloadDataHookProps,
  usePreloadInvoices,
  usePreloadMlmRules,
  usePreloadServiceSessions,
  usePreloadTokens,
  usePreloadTransactions,
  usePreloadUsers,
} from "../../hooks";

export const DataPreloader = ({
  isLogged,
  roleIs,
}: UsePreloadDataHookProps) => {
  usePreloadServiceSessions({
    isLogged,
    roleIs,
  });

  usePreloadTokens({ isLogged, roleIs });
  usePreloadInvoices({ isLogged, roleIs });
  usePreloadMlmRules({ isLogged, roleIs });
  usePreloadTransactions({ isLogged, roleIs });
  usePreloadUsers({ isLogged, roleIs });

  return null;
};

import { useModalService } from "../../../providers/ModalProvider";
import React, { useEffect, useMemo, useState } from "react";
import { useMlmRulesSelector } from "../../../redux/selectors.redux";
import { useAppDispatch } from "../../../redux/store.redux";
import {
  getMlmRulesListThunk,
  removeMlmRuleThunk,
  restoreMlmRuleThunk,
} from "../../../redux/mlm-rules/mlm-rules.thunks";
import { MlmRuleEntity, MlmRuleTypeEnum } from "../../../types";
import { BlockTemplate } from "../BlockTemplate";
import FlexBox from "../../Atoms/Flex";
import { Select } from "antd";
import { Text } from "../../Atoms/Text";
import CreateMlmRuleModal from "../../modals/CreateMlmRuleModal";
import { useAppParams, useLoaders } from "../../../hooks";
import { depthsOptions } from "../../../hardData/mlmRules";
import { TableWithActions } from "../../Tables/TableWithActions";
import {
  deleteStatusColumn,
  mlmRuleSourceVolumeColumn,
  mlmRuleThresholdTypeColumn,
  mlmRuleValueTypeColumn,
} from "../../table-columns/tableColumns";
import { isNull, isUndefined } from "lodash";

export const ReferralRulesBlock = () => {
  const dispatch = useAppDispatch();
  const loaders = useLoaders<
    "deleting" | "refreshing" | "creating" | "restoring"
  >();
  const modalS = useModalService();

  const rules = useMlmRulesSelector().lists.referral;
  const customKeysSet = useMlmRulesSelector().customKeysSet;
  const [customKey, setCustomKey] = useState<string | undefined>();

  const params = useAppParams();

  useEffect(() => {
    dispatch(
      getMlmRulesListThunk({
        refresh: true,
        onLoading: loaders.onLoading("refreshing"),
        params: {
          type: MlmRuleTypeEnum.referral,
          withDeleted: true,
        },
      }),
    );
    // eslint-disable-next-line
  }, [dispatch]);

  const { dataMap } = useMemo((): {
    dataMap: Map<number, MlmRuleEntity[]>;
  } => {
    const map = new Map<number, MlmRuleEntity[]>();

    rules?.forEach((rule) => {
      if (isUndefined(rule?.depth)) return;
      if (isNull(rule?.depth)) return;

      // const key = getKey(rule);
      if (customKey && rule.customKey !== customKey) {
        return;
      }

      const current = map.get(rule?.depth);
      if (current) {
        current?.push(rule);
        map.set(rule?.depth, current);
      } else {
        map.set(rule?.depth, [rule]);
      }
    });

    return {
      dataMap: map,
    };
  }, [customKey, rules]);

  return (
    <BlockTemplate
      contentContainerStyles={{
        $padding: "0 16px 16px",
        $xsStyles: { $padding: "0 0 16px" },
      }}
      header={
        <FlexBox
          $fxDirection={"row"}
          $alignItems={"center"}
          $justifyContent={"space-between"}
          style={{ fontSize: "14px", fontWeight: 600 }}
        >
          <Text style={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {"Referral rules"}
          </Text>

          <Select
            value={customKey}
            style={{ width: "210px" }}
            placeholder={"Select custom key"}
            allowClear
            onClear={() => {
              setCustomKey(undefined);
            }}
            options={customKeysSet.map((key) => {
              return { value: key, label: key };
            })}
            onSelect={(value) => {
              setCustomKey(value);
            }}
          />
        </FlexBox>
      }
    >
      {depthsOptions.map((depth) => {
        const data = dataMap.get(depth);

        return (
          <FlexBox $maxHeight={"400px"} overflow={"hidden"} $fillWidth>
            <TableWithActions
              key={depth}
              data={data}
              setOpen={!!data?.length}
              label={`Depth: ${depth === 0 ? "origin" : depth}`}
              isDeleting={loaders.isLoading.deleting}
              isRestoring={loaders.isLoading.restoring}
              onRefresh={() => {}}
              onAddPress={() => {
                params.groupId &&
                  modalS.open(CreateMlmRuleModal, {
                    defaultValues: {
                      depth,
                      groupId: params.groupId,
                      type: MlmRuleTypeEnum.referral,
                    },
                    customKeysSet,
                  });
              }}
              onEdit={(data) => {
                modalS.open(CreateMlmRuleModal, {
                  defaultValues: data,
                  customKeysSet,
                });
              }}
              onRemove={(data) => {
                dispatch(removeMlmRuleThunk({ params: { id: data._id } }));
              }}
              onRestore={(data) => {
                dispatch(restoreMlmRuleThunk({ params: { id: data._id } }));
              }}
              columns={[
                { title: "Value", path: "value" },
                mlmRuleValueTypeColumn(),
                mlmRuleSourceVolumeColumn(),
                mlmRuleThresholdTypeColumn(),
                { title: "Threshold", path: "threshold" },
                { title: "Custom key", path: "customKey" },
                deleteStatusColumn(),
              ]}
            />
          </FlexBox>
        );
      })}
    </BlockTemplate>
  );
};

import React, { useState } from "react";
import {
  useTokensSelector,
  useTransactionsSelector,
} from "../../redux/selectors.redux";
import { useAppDispatch } from "../../redux/store.redux";
import { setCurrentTokenAction } from "../../redux/tokens/tokens.slice";
import { TokenEntity } from "../../types";
import BaseTable from "../Atoms/Table";
import { numberToBalance, toAppDate } from "../../shared/utils";
import FlexBox from "../Atoms/Flex";
import { Text } from "../Atoms/Text";
import { BlockTemplate } from "./BlockTemplate";
import { Select, SelectProps } from "antd";
import { getAllTransactions } from "../../redux/transactions/transactions.thunks";

export const TokenTransactionsBlock = () => {
  const dispatch = useAppDispatch();
  const { list } = useTokensSelector();
  const transactions = useTransactionsSelector().list;

  const [current, setCurrent] = useState<string | number | undefined>(0);

  const onSelectCurrentHandler = (token?: TokenEntity) => {
    token && dispatch(setCurrentTokenAction({ data: token }));

    dispatch(getAllTransactions({}));
  };

  const selectOptions: SelectProps["options"] = list.map((token, index) => {
    return {
      key: token._id,
      value: index,
      label: (
        <FlexBox $alignItems={"flex-start"}>
          <Text $size={13} $weight={600}>
            {token.label}
          </Text>

          <FlexBox $fxDirection={"row"} $gap={4}>
            <Text $size={8} $weight={500}>
              {token.label}
              {" | "}
              {token.symbol}
              {" | "}
              <Text $size={8}>{token?.type ?? "bonus"}</Text>
            </Text>
          </FlexBox>
        </FlexBox>
      ),
    };
  });

  return (
    <BlockTemplate title={"Transactions"}>
      <FlexBox margin={"0 auto 16px 0"} $gap={4}>
        <Text $size={13} $weight={500}>
          {"Token"}
        </Text>

        <Select
          size={"large"}
          style={{ width: 200 }}
          placeholder={"Select token"}
          options={selectOptions}
          value={current}
          allowClear
          onClear={() => {
            onSelectCurrentHandler();
            setCurrent(undefined);
          }}
          onChange={(value) => {
            setCurrent(value);
            if (typeof value === "number") {
              onSelectCurrentHandler(list[value]);
            }
          }}
        />
      </FlexBox>

      <BaseTable
        data={transactions}
        columns={[
          {
            title: "Token symbol",
            width: "185px",
            getData(data) {
              return `${data?.token?.symbol}/${data?.token?.symbol}`;
            },
          },

          {
            title: "Amount",
            width: "250px",
            align: "end",
            getData(d) {
              return numberToBalance(d?.amount, 5);
            },
          },
          {
            title: "Saldo",
            align: "end",
            width: "250px",
            getData(d) {
              return numberToBalance(d?.saldo);
            },
          },
          {
            title: "From",
            path: "status",
            width: "150px",
          },
          {
            title: "To",
            getData(d) {
              return d.from.level;
            },
            width: "150px",
          },

          {
            title: "Status",
            path: "status",
            width: "150px",
          },

          {
            title: "Type",
            path: "type",
            width: "150px",
          },

          {
            title: "Description",
            path: "description",
            width: "200px",
          },

          {
            title: "Created at",
            width: "125px",
            getData(data) {
              return data.createdAt ? toAppDate(data.createdAt) : "---";
            },
          },
          {
            title: "Updated at",
            width: "125px",
            getData(data) {
              return data.updatedAt ? toAppDate(data.updatedAt) : "---";
            },
          },
        ]}
      />
    </BlockTemplate>
  );
};

"use client";
import React, { forwardRef, HTMLInputTypeAttribute } from "react";
import styled from "styled-components";
import { P } from "../Atoms/Text";
import FlexBox from "../Atoms/Flex";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import { ComponentSize } from "../components.types";
import { Property } from "csstype";
import {
  UseAppFormRegisterWithValue,
  UseAppFormRegisterWithValueReturn,
} from "../../hooks";
import { Input } from "antd";

type SizeStyles = {
  padding?: Property.Padding;
  minHeight?: Property.MinHeight;
  fontSize?: Property.FontSize;
};
const sizesMap: Record<ComponentSize, SizeStyles> = {
  small: {
    minHeight: "30px",
    padding: "2px 8px",
    fontSize: "16px",
  },
  middle: {
    minHeight: "36px",
    padding: "2px 10px",
    fontSize: "15px",
  },
  large: {
    minHeight: "42px",
    padding: "2px 12px",
    fontSize: "16px",
  },
};

function getInputSizeStyles(size?: ComponentSize): SizeStyles {
  return size ? sizesMap?.[size] : sizesMap?.middle;
}

const FormInput = <FormData extends FieldValues = any>(
  {
    name,
    placeholder,
    //   form,
    register,
    type = "text",
    shouldUnregister,
    required,
    size,
    registerWithValue,
    ...props
  }: {
    name?: Path<FormData>;
    placeholder?: string;
    register?: UseFormRegister<FormData>;
    registerWithValue?: UseAppFormRegisterWithValue<FormData>;
    shouldUnregister?: boolean;
    required?: boolean;
    size?: ComponentSize;
    //   form?: UseFormReturn<FormData | any | undefined>;
    type?: HTMLInputTypeAttribute;
  } & Partial<UseAppFormRegisterWithValueReturn<FormData>>,
  ref?: React.ForwardedRef<HTMLInputElement>,
) => {
  //   const hasError = !!(name && form?.formState?.errors[name]);

  return (
    <>
      <FlexBox $fillWidth style={{ minWidth: "100%" }}>
        {!register ? (
          <Input
            type={type}
            required={required}
            placeholder={placeholder ?? ""}
            style={getInputSizeStyles(size)}
            {...props}
            name={name}
            onChange={(ev) => {
              props.onChange && props.onChange(ev);
            }}
          />
        ) : (
          <FormStInput
            //   $hasError={hasError}
            type={type}
            required={required}
            placeholder={placeholder ?? ""}
            ref={ref}
            {...(name
              ? register(name as never, { shouldUnregister, required })
              : null)}
            {...props}
            // ref={ref}
            style={getInputSizeStyles(size)}
          />
        )}
      </FlexBox>

      {/* {hasError && (
        <ErrorText>{form.formState.errors[name]?.message as string}</ErrorText>
      )} */}
    </>
  );
};

export default forwardRef(FormInput);

export const ErrorText = styled(P)`
  font-size: 12px;
  color: ${(p) => p.theme.colors.error};
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
`;

export const FormStInput = styled.input<{
  $hasError?: boolean;
}>`
  width: 100%;

  border: 1px solid
    ${(p) => (p.$hasError ? p.theme.colors.error : p.theme.lightBorderColor)};
  border-radius: ${(p) => p.theme.borderRadius};
  outline: none;
  background-color: ${(p) => p.theme.secondaryBgColor};
  color: ${(p) => p.theme.assignmentText};

  font-family: inherit;

  font-weight: 500;

  &::placeholder {
    font-size: 16px;
    color: ${(p) => p.theme.colors.default};
  }

  &:disabled {
    color: ${(p) => p.theme.darkerGreyText};
  }
`;

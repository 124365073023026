import { createAppAsyncThunk } from "../../../shared/utils";
import AuthApi from "../../../api/auth/Auth.api";

enum OAutnThunkType {
  getAll = "o_auth/connections/getAllThunk",
  create = "o_auth/connections/createOneThunk",
}

export const getAllOAuthConnectionsThunk = createAppAsyncThunk(
  OAutnThunkType.getAll,
  AuthApi.oAuth.connections.getAll,
);
export const createOAuthConnectionThunk = createAppAsyncThunk(
  OAutnThunkType.create,
  AuthApi.oAuth.connections.create,
);

import { BalanceAccount } from "../types";

export const UA = {
  confirmation: "Узгодження",
  content: "Вміст",
  info: "Інформація",
  summary: "Підсумок",
  "Log in with": "Вхід через",
  Quantity: "Кількість",
  Total: "Сума",
  total: "сума",
  "q-ty": "к-ть",

  [`card_type_${BalanceAccount.TypesEnum.reserve}`]: "Reserved",
  [`card_type_${BalanceAccount.TypesEnum.debit}`]: "Available",
  [`card_type_${BalanceAccount.TypesEnum.hold}`]: "Hold",
  [`card_type_${BalanceAccount.TypesEnum.deposit}`]: "Deposit",
  [`card_type_${BalanceAccount.TypesEnum.credit}`]: "Credit",

  [`card_status_${BalanceAccount.AccessEnum.active}`]: "Active",
  [`card_status_${BalanceAccount.AccessEnum.freeze}`]: "Freeze",
  [`card_status_${BalanceAccount.AccessEnum.closed}`]: "Closed",

  goods: "Товари",
  services: "Послуги",
  shipments: "Відвантаження",

  email: "Емейл",
  profile: "Профіль",

  Undefined: "Не визначено",

  Personal: "Персональні",
  Referral: "Рефереальні",
  Extra: "Екстра",

  "Total amount": "Загальна кількість",
  "Current balance": "Актуальний баланс",
  Assignment: " Призначення",

  Confirm: "Підтвердити",
  Cancel: "Скаcувати",

  // form
  "Enter your name": " Введіть своє ім’я",
  Name: "Ім'я",

  "Second name": "Прізвище",
  "Enter your second name": "Введіть своє прізвище",

  Email: "Електронна адреса",
  "Enter your email": "Введіть електронну адресу",

  Password: "Пароль",
  "Enter your password": "Введіть ваш пароль",
  "Enter your password again": "Повторіть пароль",
  "Enter your new password": "Введіть ваш новий пароль",
  Registration: "Реєстрація",
  "Sign up": "Зареєструватись",

  "Requirement permissions": "Необхідні довзволи",

  "Already have an account?": "Вже є акаунт?",

  "Sign in": "Увійти",
  "Do not have an account?": "Немає облікового запису?",
  "Debit requests": "Запити на списання",

  Amount: "Сума",
  "Active till": "Активний до",
  "Loading...": "Завантаження...",
  Profile: "Акаунт",
  Edit: "Редагувати",
  Settings: "Налаштування",
  Language: "Мова",
  Ukrainian: "Українська",
  "Change password": "Зміна паролю",
  "Delete account": "Видалити акаунт",
  "Current version": " Поточна версія",
  and: "і",
  Search: "Пошук",
  Editing: "Редагування",
  Referral_Person: "Рефереал",
  "Share bonuses": "Поділитись бонусами",
  "User reference": "ID користувача",
  "Enter bonuses amount": "Введіть суму бонусів",
  "Enter payment assignment": "Вкажіть призначення переказу",
  "Enter text": "Введіть текст",
  "Minimum 10 symbols": "Мінімум 10 символів",
  "Payment request": "Заявка на виплату",
  "Invite friends": "Запросити друзів",
  Date: "Дата",
  "Send request": "Подати заявку",
  "Page not found": "Сторінку не знайдено",
  "To main page": "Повернутись на головну",
  "My referral network": "Моя реферальна мережа",

  Support: "Підтримка",
  "We are online 24/7": "Ми на зв’язку 24/7",
  "Please write a message and our manager will get in touch with you":
    "Напишіть повідомлення і наш менеджер з вами зв’яжеться",

  "My referral code": "  Мій реферальний код",
  "To register new customer please share this link with them or give them this QR-code to scan":
    "Для реєстрації нового користувача поділться із ним посиланням або дайте зісканувати QR-code",
  "Scan QR-code": "Скануйте QR-code",
  Account: "Рахунок",
  "Terms of Use": "Правила користування ",

  "Privacy Policy": " Політика конфіденційності",
  "To transfer, enter the user ID and the amount to withdraw bonuses":
    "Для переказу введіть ID користувача та суму для списання бонусів",

  "The page you were looking for has been deleted, renamed or lost in space":
    "Сторінку, яку ви шукали, видалено, перейменовано або загублено в просторі",

  "Forgot password": "Забули пароль?",
  "Send code": "Відправити код",

  "Enter the email address you provided during registration and we'll send you instructions on how to change your password.":
    "Введіть адресу електронної пошти, яку ви вказали під час реєстрації, і ми надішлемо вам інструкції щодо зміни пароля.",

  "Set new password": "Встановити новий пароль",
  "Reset password": "Скинути пароль",
  "It should contain not less than 6 symbols":
    "Він має містити не менше 6 символів",

  "To apply for payment, enter the date and amount and click 'Submit application'":
    "Щоб подати заявку на виплату, вкажіть дату та суму та натисніть 'Подати заявку'",
  Feedback: "Зворотній зв’язок",
  Back: "Назад",
  "Nothing found": "Нічого не знайдено",

  "Not active": "Не активна",
  Reserve: "Резерв",

  Active: "Активна",
  "Share link": "Поділитись посиланням",

  "Bonus invoice": "Списання бонусів",
  Reference: "Референс",

  writeOff: "Списання",
  transfer: "Переказ",
  accrual: "Надходження коштів",
  activation: "Активація",
  payout: "Виплата",

  "Load more": "Завантажити ще",
  "You have been registered successfully": "Ви успішно зареєстровані",
  "Confirm your email to complete registration":
    "Підтвердьте свою електронну пошту, щоб завершити реєстрацію",
  "Trevor Peterson": "Марк Радчук",
  "Please choose reason of your feedback:":
    "Виберіть причину зворотнього зв'язку:",
  "Please choose the status of the invoice":
    "Виберіть статус запиту на списання",
  Refresh: "Оновити",
  // invoices statuses

  wait_confirm: "Очікує підтвердження",
  bonus_writeOff: "Списання бонусів",
  rejected: "Відхилено",
  accepted: "Підтверджено",
  error: "Помилка",
  fail: "Провал",
  draft: "Проект",
  hold: "На утриманні",
  determined: "Визначений",

  credit: "Списання бонусів",
  debit: "Нарахування бонусів",
};

import { createAppAsyncThunk } from "../../shared/utils";
import AuthApi from "../../api/auth/Auth.api";
import { AppAuth } from "../../types/auth/app-auth.namespace";

export enum SessionsThunkType {
  getAll = "auth/sessions/getAllThunk",
  getAllForService = "auth/sessions/service/getAllThunk",
  getAllForToken = "auth/sessions/token/getAllThunk",
  getAllForUser = "auth/sessions/user/getAllThunk",
  createForService = "auth/sessions/createForServiceThunk",
}

function buildGetAllSessionsThunk<T extends AppAuth.Session.Type>(type: T) {
  return createAppAsyncThunk(
    Object.values(SessionsThunkType).find((key) => key.includes(`/${type}/`)) ??
      SessionsThunkType.getAll,
    (...args: Parameters<typeof AuthApi.sessions.getAll>) => {
      const [arg, p, ...rest] = args;

      return AuthApi.sessions.getAll<T>(arg, { ...p, type }, ...rest);
    },
  );
}

export const getAllSessionsThunks = {
  forUser: buildGetAllSessionsThunk(AppAuth.Session.Type.user),
  forService: buildGetAllSessionsThunk(AppAuth.Session.Type.service),
  forToken: buildGetAllSessionsThunk(AppAuth.Session.Type.token),
};
export const getAllUserSessionsThunk = getAllSessionsThunks.forUser;
export const getAllServiceSessionsThunk = getAllSessionsThunks.forService;
export const getAllTokenSessionsThunk = getAllSessionsThunks.forToken;

export const createServiceSessionThunk = createAppAsyncThunk(
  SessionsThunkType.createForService,
  AuthApi.sessions.startForService,
);

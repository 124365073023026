import FlexBox from "../../components/Atoms/Flex";
import BaseTable from "../../components/Atoms/Table";
import React, { useCallback, useMemo, useState } from "react";
import {
  CreateMlmRuleDto,
  MlmRuleBaseDto,
  MlmRuleDtoKeyEnum,
  MlmRulesGroupEntity,
  MlmRuleTypeEnum,
} from "../../types";
import { BlockTemplate } from "../../components/Blocks/BlockTemplate";
import { Tabs, TabsProps } from "antd";
import { ObjectEntries } from "../../shared/utils";
import { useMlmRulesSelector } from "../../redux/selectors.redux";
import { useAppDispatch } from "../../redux/store.redux";
import { useAppParams, useAppRouter, usePreloadMlmRules } from "../../hooks";
import CreateMlmReferralRuleBlock from "../../components/Blocks/Admin/CreateMlmReferralRuleBlock";
import CreateMlmExtraRuleBlock from "../../components/Blocks/Admin/CreateMlmExtraRuleBlock";
import {
  createMlmRuleThunk,
  getMlmRulesListThunk,
} from "../../redux/mlm-rules/mlm-rules.thunks";
import { baseMlmRuleColumns } from "../../hardData/mlmRules";

export interface CreateMlmRuleBlockProps {
  set?: Partial<MlmRulesGroupEntity>;
  type: MlmRuleTypeEnum;
  onSetItem?: (item: CreateMlmRuleDto, type?: MlmRuleTypeEnum) => void;
}

type FormState = Partial<
  Record<MlmRuleTypeEnum, Record<string, MlmRuleBaseDto & { key?: string }>>
>;

type MlmRulesPageTabName = MlmRuleTypeEnum | "All";

const MlmRulesPage = () => {
  const params = useAppParams();
  const { setHash, hash } = useAppRouter();

  const dispatch = useAppDispatch();
  const { lists: rules, list } = useMlmRulesSelector();

  const [currentType, setCurrentType] = useState<MlmRulesPageTabName>("All");

  usePreloadMlmRules({
    isLogged: true,
  });

  const loadData = (type?: MlmRuleTypeEnum) => {
    dispatch(
      getMlmRulesListThunk({
        params: {
          types: type ? [type] : undefined,
          groupId: params?.groupId,
        },
      }),
    );
  };

  const onSaveRule = useCallback(
    (item: MlmRuleBaseDto) => {
      return dispatch(
        createMlmRuleThunk({
          data: { ...item },
        }),
      );
    },
    [dispatch],
  );

  const registerFormDataSetter = useCallback(
    (type: MlmRuleTypeEnum) => {
      return async (item: MlmRuleBaseDto) => {
        const formData = {
          ...item,
          type,
        };

        onSaveRule(formData)?.then(() => {
          // const queryString = genMlmRuleQueryString(formData);
          //
          // form.setValue(`${type}.${queryString}`, {
          //   ...formData,
          //   queryString,
          // } as never);
        });
      };
    },
    [onSaveRule],
  );

  const tableData = useMemo(() => {
    return currentType in rules ? rules?.[currentType as never] : list;
  }, [currentType, list, rules]);

  const renderTabs = useMemo(() => {
    const noDataDescription = "Ви ще не створили жодного правила";
    const base_table_data = (
      <BlockTemplate title={"Rules list"}>
        <BaseTable
          data={tableData}
          noDataDescription={noDataDescription}
          columns={[
            {
              title: "Depth",
              path: MlmRuleDtoKeyEnum.depth,
              width: "80px",
            },
            ...(baseMlmRuleColumns ?? []),
          ]}
        />
      </BlockTemplate>
    );
    const tabs: Partial<Record<typeof currentType, React.ReactNode>> = {
      All: base_table_data,
      [MlmRuleTypeEnum.referral]: (
        <FlexBox $gap={16}>
          {base_table_data}

          <CreateMlmReferralRuleBlock
            onSetItem={registerFormDataSetter(MlmRuleTypeEnum.referral)}
            type={MlmRuleTypeEnum.referral}
          />
        </FlexBox>
      ),
      [MlmRuleTypeEnum.extra]: (
        <FlexBox $gap={16}>
          <BlockTemplate title={"Rules list"}>
            <BaseTable
              data={tableData}
              columns={baseMlmRuleColumns}
              noDataDescription={noDataDescription}
            />
          </BlockTemplate>

          <CreateMlmExtraRuleBlock
            type={MlmRuleTypeEnum.extra}
            onSetItem={registerFormDataSetter(MlmRuleTypeEnum.extra)}
          />
        </FlexBox>
      ),
    };

    return tabs;
  }, [registerFormDataSetter, tableData]);

  const tabs: TabsProps["items"] = useMemo(() => {
    const array = ObjectEntries(renderTabs).map(([key, node], index) => {
      return {
        key: key || "",
        label: key.toUpperCase(),
        children: node ?? null,
      };
    });

    return array;
  }, [renderTabs]);

  return (
    <FlexBox $gap={16}>
      <Tabs
        defaultActiveKey="1"
        items={tabs}
        activeKey={currentType}
        onChange={(v) => {
          loadData(v !== "All" ? (v as never) : undefined);

          setCurrentType(v as never);
          setHash(v);
        }}
      />
    </FlexBox>
  );
};

export default MlmRulesPage;

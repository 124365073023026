import {
  MlmRuleDepthEnum,
  MlmRuleSourceVolumeEnum,
  MlmRuleValueTypeEnum,
  UserStatusEnum,
} from "../types";
import { EnumToSelectOptions } from "../shared/utils";
import { pick } from "lodash";
import { Wallet } from "../types/wallets.types";

const Depth = MlmRuleDepthEnum.map((depth) => {
  return {
    [depth.toString()]: {
      value: depth,
      label: "Depth " + depth.toString(),
    },
  };
});

const ValueTypes = EnumToSelectOptions(MlmRuleValueTypeEnum);

const AccountStatus = EnumToSelectOptions(Wallet.StatusEnum);
const UserStatus = EnumToSelectOptions(UserStatusEnum);
const VolumeTypes_Level = EnumToSelectOptions(
  pick(MlmRuleSourceVolumeEnum, ["slot", "order"]),
);

const ThresholdTypes_Level = EnumToSelectOptions(MlmRuleSourceVolumeEnum);

export const SelectOptions = {
  Depth,
  ValueTypes,
  AccountStatus,
  UserStatus,
  VolumeTypes_Level,
  ThresholdTypes_Level,
};

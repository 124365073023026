import { FlexLabel, FlexProps } from "../Atoms/Flex";
import { Text } from "../Atoms/Text";
import { HTMLAttributes } from "react";

export const Label = ({
  children,
  label,
  helper,
  status,
  ...props
}: {
  children?: React.ReactNode;
  label?: React.ReactNode;
  helper?: React.ReactNode;
  status?: "info" | "error" | "success";
  required?: boolean;
} & FlexProps &
  HTMLAttributes<HTMLLabelElement>) => {
  return (
    <FlexLabel $fxDirection={"column"} {...props}>
      <Text
        style={{ padding: "4px 2px" }}
        $size={13}
        $weight={600}
        $align={"left"}
      >
        {label}
      </Text>

      {children}

      {helper && (
        <Text style={{ padding: "4px 2px" }} $size={11} $align={"left"}>
          {helper}
        </Text>
      )}
    </FlexLabel>
  );
};

import React from "react";
import { ContainerSmall } from "../components/Atoms/Container";
import RegisterUserBlock from "../components/Blocks/Auth/RegisterUserBlock";

const RegisterPage = () => {
  return (
    <ContainerSmall $gap={24}>
      <RegisterUserBlock />
    </ContainerSmall>
  );
};

export default RegisterPage;

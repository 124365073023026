import APP_CONFIGS from "../../configs/APP_CONFIGS";
import ClientApi from "../Client.api";
import { AppResponse, UUID } from "../../types/utils.types";
import { Wallet } from "../../types/wallets.types";
import { BalanceAccountsApi } from "./BalanceAccounts.api";

export class WalletsApi {
  private static api = ClientApi.getClient();
  private static endpoints = APP_CONFIGS.endpoints.wallets;
  public static balances = BalanceAccountsApi;

  public static getAll = (
    _data?: undefined,
    params?: {
      offset?: number;
      limit?: number;

      statuses?: Wallet.StatusEnum[];
      access?: Wallet.AccessEnum[];
      ids?: UUID[];

      ownerId?: UUID;
      holderId?: UUID;
      tokenId?: UUID;
    },
  ): Promise<AppResponse<Wallet.Entity[]>> => {
    return this.api.get(this.endpoints.getList(), { params });
  };

  public static create = (
    data?: Wallet.Dto,
  ): Promise<AppResponse<Wallet.Entity[]>> => {
    return this.api.post(this.endpoints.create(), data);
  };
}

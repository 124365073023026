import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./theme";
import { persistor, store } from "./redux/store.redux";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ModalProvider from "./providers/ModalProvider";
import App from "./App";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  // ! <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={lightTheme}>
        <ModalProvider>
          <ConfigProvider>
            <App />
          </ConfigProvider>
        </ModalProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  // ! </React.StrictMode>
);

import styled, { css } from "styled-components";
import { Property } from "csstype";
import { Link } from "react-router-dom";

type FontWeight = 300 | 400 | 500 | 600 | 700 | 800 | 900;

enum FontFamilyNames {
  Comfortaa = "Comfortaa",
  ComfortaaMedium = "ComfortaaMedium",
  ComfortaaBold = "ComfortaaBold",
  Montserrat = "Montserrat",
  MontserratMedium = "MontserratMedium",
  MontserratSemiBold = "MontserratSemiBold",
  MontserratBold = "MontserratBold",
  MontserratBlack = "MontserratBlack",
  RalewayBold = "RalewayBold",
  RalewayExtraBold = "RalewayExtraBold",
  RalewayBlack = "RalewayBlack",
  Roboto = "Roboto",
  RobotoMedium = "RobotoMedium",
  RobotoBold = "RobotoBold",
  RobotoBlack = " RobotoBlack",
  Inter = "Inter",
  Manrope = "Manrope",
}
type FontType = keyof typeof FontFamilyNames | FontFamilyNames;

export interface IBaseTextProps {
  fontFamily?: FontType;
  fontSize?: string;
  fontWeight?: FontWeight;
  fontStyle?: Property.FontStyle;
  textTransform?: Property.TextTransform;
  textDecoration?: Property.TextDecoration;
}

export interface TextProps {
  $family?: FontType;
  $size?: number;
  $lineHeight?: Property.LineHeight;
  $weight?: FontWeight;
  $align?: Property.TextAlign;
  $textTransform?: Property.TextTransform;
  $textDecoration?: Property.TextDecoration;
}

// ! DEFAULT VALUES
export const BaseText = styled.span<IBaseTextProps>`
  //font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  text-decoration: ${({ textDecoration }) => textDecoration};
  /* font-weight думаю треба явно задавати щоб перевизначити браузерні */ // +++
  font-weight: ${({ fontWeight }) => fontWeight};

  text-transform: ${({ textTransform }) => textTransform};
  color: ${({ color }) => color};
`;

export const Text = styled.span<TextProps>`
  //font-family: ${({ $family = "Inter" }) => $family};
  font-size: ${({ $size = "14" }) => $size}px;
  line-height: ${({ $lineHeight = 1.5 }) => $lineHeight};
  /* font-weight думаю треба явно задавати щоб перевизначити браузерні */ // +++
  font-weight: ${({ $weight = 400 }) => $weight};
  text-decoration: ${({ $textDecoration = 400 }) => $textDecoration};
  text-align: ${({ $align = "left" }) => $align};
  text-transform: ${({ $textTransform }) => $textTransform};
  color: ${({ color }) => color || "inherit"};

  cursor: inherit;
`;

export interface BaseTypographyProps {
  $family?: FontType;
  $size?: number;
  $lineHeight?: Property.LineHeight;
  $weight?: FontWeight;
  $align?: Property.TextAlign;
  $textTransform?: Property.TextTransform;
  $fontStyle?: Property.FontStyle;
  $textDecoration?: Property.TextDecoration;
  color?: Property.Color;

  $padding?: Property.Padding;
  margin?: Property.Margin;
}

export interface TypographyProps extends BaseTypographyProps {
  $xsStyles?: BaseTypographyProps;
  $xlStyles?: BaseTypographyProps;
}

const XsStyles = css<TypographyProps>`
  @media (max-width: 960px) {
    font-family: ${({ $xsStyles, $family = "Inter" }) =>
      $xsStyles?.$family || $family};
    font-style: ${({ $xsStyles, $fontStyle = "normal" }) =>
      $xsStyles?.$fontStyle || $fontStyle};
    font-size: ${({ $xsStyles, $size = 24 }) => $xsStyles?.$size || $size}px;
    line-height: ${({ $xsStyles, $lineHeight = 1.1 }) =>
      $xsStyles?.$lineHeight || $lineHeight};
    font-weight: ${({ $xsStyles, $weight = 700 }) =>
      $xsStyles?.$weight || $weight};
    text-align: ${({ $xsStyles, $align = "center" }) =>
      $xsStyles?.$align || $align};
    text-transform: ${({ $xsStyles, $textTransform }) =>
      $xsStyles?.$textTransform || $textTransform};
    color: ${({ $xsStyles, color }) => $xsStyles?.color || color};
    text-decoration: ${({ $xsStyles, $textDecoration = "none" }) =>
      $xsStyles?.$textDecoration || $textDecoration};

    padding: ${({ $xsStyles, $padding = 0 }) =>
      $xsStyles?.$padding || $padding}px;
    margin: ${({ $xsStyles, margin = 0 }) => $xsStyles?.margin || margin}px;
  }
`;

const XlStyles = css<TypographyProps>`
  @media (min-width: 960px) {
    font-family: ${({ $xlStyles, $family = "Manrope" }) =>
      $xlStyles?.$family || $family};
    font-style: ${({ $xlStyles, $fontStyle = "normal" }) =>
      $xlStyles?.$fontStyle || $fontStyle};
    font-size: ${({ $xlStyles, $size = 24 }) => $xlStyles?.$size || $size}px;
    line-height: ${({ $xlStyles, $lineHeight = 1.1 }) =>
      $xlStyles?.$lineHeight || $lineHeight};
    font-weight: ${({ $xlStyles, $weight = 700 }) =>
      $xlStyles?.$weight || $weight};
    text-align: ${({ $xlStyles, $align = "center" }) =>
      $xlStyles?.$align || $align};
    text-transform: ${({ $xlStyles, $textTransform }) =>
      $xlStyles?.$textTransform || $textTransform};
    color: ${({ $xlStyles, color }) => $xlStyles?.color || color};
    text-decoration: ${({ $xlStyles, $textDecoration = "none" }) =>
      $xlStyles?.$textDecoration || $textDecoration};

    padding: ${({ $xlStyles, $padding = 0 }) =>
      $xlStyles?.$padding || $padding}px;
    margin: ${({ $xlStyles, margin = 0 }) => $xlStyles?.margin || margin}px;
  }
`;

const BaseTextProps = css<TypographyProps>`
  font-family: ${({ $family = "inherit" }) => $family};
  font-style: ${({ $fontStyle = "normal" }) => $fontStyle};
  font-size: ${({ $size = 14 }) => $size}px;
  line-height: ${({ $lineHeight = 1.1 }) => $lineHeight};
  font-weight: ${({ $weight = 400 }) => $weight};
  text-align: ${({ $align = "left" }) => $align};
  text-transform: ${({ $textTransform = "none" }) => $textTransform};
  color: ${({ color = "inherit" }) => color};
  text-decoration: ${({ $textDecoration = "none" }) => $textDecoration};
  padding: ${({ $padding = 0 }) => $padding}px;
  margin: ${({ margin }) => margin}px;

  ${({ $xsStyles }) => {
    if ($xsStyles) return XsStyles;
  }};
  ${({ $xlStyles }) => {
    if ($xlStyles) return XlStyles;
  }};
`;

export const BaseLink = styled.a<TextProps>`
  ${BaseTextProps};
  font-size: inherit;
  cursor: pointer;

  &[aria-disabled] {
    pointer-events: none;
    opacity: 0.75;
  }
`;

export const BaseNextLink = styled(Link)<TextProps>`
  ${BaseTextProps};
  text-decoration: none;
  color: inherit;
  font-style: normal;
  cursor: pointer;

  &[aria-disabled] {
    pointer-events: none;
    opacity: 0.75;
  }
`;

export const H1 = styled.h1<TextProps>`
  ${BaseTextProps};
  font-weight: ${({ $weight = 700 }) => $weight};
`;

export const H2 = styled.h2<TextProps>`
  ${BaseTextProps};
  font-weight: ${({ $weight = 600 }) => $weight};
`;

export const H3 = styled.h3<TextProps>`
  ${BaseTextProps};
`;

export const P = styled.p<TextProps>`
  ${BaseTextProps};
`;

export const BaseLabel = styled.label<TextProps>`
  ${BaseTextProps};
  cursor: pointer;
`;

import { useAppDispatch } from "../../redux/store.redux";
import {
  useMlmRulesSelector,
  useTokensSelector,
  useUsersSelector,
} from "../../redux/selectors.redux";
import { useState } from "react";
import { TempRevenueEvent } from "../../types/app-events.types";
import { useAppForm } from "../../hooks";
import { BlockTemplate } from "./BlockTemplate";
import FlexBox, { FlexForm, SimpleGridBox } from "../Atoms/Flex";
import Separator from "../Atoms/Separator";
import { Label } from "../FormElements/Label";
import { AutoComplete, Input, InputNumber, Select } from "antd";
import { Text } from "../Atoms/Text";
import { trackRevenueEventThunk } from "../../redux/events/revenue-events.thunks";
import { TableWithActions } from "../Tables/TableWithActions";
import { tempRevenueColumns } from "../../hardData/events";
import { toReqData } from "../../shared/utils";
import { CreatedModal, useModalService } from "../../providers/ModalProvider";
import ModalBase from "../Atoms/ModalBase";
import { nanoid } from "@reduxjs/toolkit";

const EmitRevenueEventBlock = () => {
  const dispatch = useAppDispatch();
  const modalS = useModalService();
  const [events, setEvents] = useState<TempRevenueEvent[]>([]);
  const [currentEv, setCurrentEv] = useState<TempRevenueEvent>();

  return (
    <BlockTemplate
      title={"Emit revenue event"}
      contentContainerStyles={{
        $padding: "0 16px 16px",
        $xsStyles: { $padding: "0 0 16px" },
      }}
    >
      <TableWithActions<TempRevenueEvent>
        columns={tempRevenueColumns}
        data={events}
        keyExtractor={(row) => row.tempid}
        onEdit={(row) => {
          modalS.open(CreateRevenueEventModal, {
            currentEv: row,
          });
        }}
        onRemove={(row) => {
          setEvents((prev) => prev?.filter((el) => el.tempid !== row.tempid));
        }}
        onAddPress={() => {
          modalS.open(CreateRevenueEventModal, {
            onOk: (data) => {
              setEvents((prev) => [...prev, data]);
            },
          });
        }}
      />
    </BlockTemplate>
  );
};

const CreateRevenueEventModal = ({
  currentEv,
  onOk,
  onClose,
  compId,
}: {
  currentEv?: TempRevenueEvent;
  onOk?: (data: TempRevenueEvent) => void;
} & CreatedModal) => {
  const dispatch = useAppDispatch();
  const tokensList = useTokensSelector().list;
  const tokensOptions = tokensList.map((el, index) => ({
    label: `${el.label} ${el.symbol}`,
    value: index,
  }));

  const customKeysMap = useMlmRulesSelector().customKeysMap;
  const customKeysOptions = Object.keys(customKeysMap).map((key) => {
    return {
      value: key,
      label: key,
    };
  });

  const usersList = useUsersSelector().list;
  const usersOptions = usersList.map((user, index) => {
    return {
      value: index,
      label: user.email,
    };
  });

  const form = useAppForm<TempRevenueEvent>({
    values: { tempid: nanoid(8), ...currentEv },
  });
  const fv = form.formValues;

  const onEmitCurrentPress = (fData: TempRevenueEvent) => {
    dispatch(
      trackRevenueEventThunk({
        data: toReqData({
          ...fData,
          user: {
            email: fData.user?.email,
            phone: fData.user?.phone,
            reference: fData.user?.reference ?? "",
          },
          cashback: {
            ...fData.cashback,
            tokenId: fData.cashback?.tokenId,
          },
        }),
        onSuccess: () => {
          onClose && onClose();
          // form.reset();
        },
      }),
    );
  };
  return (
    <ModalBase title={"Create revenue event"} {...{ onClose, compId }}>
      <FlexForm
        $gap={6}
        onSubmit={form.handleSubmit((fData) => {
          if (onOk) {
            onOk(fData);
            onClose && onClose();
          } else {
            onEmitCurrentPress(fData);
          }
        })}
      >
        <FlexBox $gap={6} $maxWidth={"100%"}>
          <Label label={"User"}>
            <Select
              options={usersOptions}
              placeholder={"Select token"}
              allowClear
              onChange={(value) => {
                const user = usersList[value];
                if (user) {
                  form.setValue("user", user, {
                    shouldTouch: true,
                    shouldDirty: true,
                  });
                } else {
                  form.unregister("user");
                }
              }}
            />
          </Label>

          <Label label={"Offer ID"}>
            <Input
              value={fv.offerId}
              {...(form.register("offerId", { required: true }) as any)}
            />
          </Label>

          <Label label={"Label"}>
            <Input
              // value={fv.label}
              {...(form.registerWithValue("label", {
                maxLength: 32,
                required: true,
              }) as any)}
            />
          </Label>

          <SimpleGridBox
            $fxDirection={"row"}
            $gap={8}
            $templateColumns={["1.5fr", "1fr"]}
          >
            <Label label={"Quantity"}>
              <InputNumber
                style={{ width: "100%" }}
                // value={fv.quantity}
                {...form.registerWithValue("quantity", {
                  onBlur: () => {
                    if (form.formValues.price) {
                      form.setValue(
                        "total",
                        Number(
                          (form.formValues.quantity ?? 0) *
                            (form.formValues.price ?? 0),
                        ),
                      );
                    }
                  },
                })}
                onChange={(value) => {
                  form.setValue("quantity", Number(value), {
                    shouldTouch: true,
                    shouldDirty: true,
                  });
                }}
              />
            </Label>

            <Label label={"Unit"}>
              <Input
                // value={fv.unit}
                {...form.registerWithValue("unit")}
              />
            </Label>
          </SimpleGridBox>

          <SimpleGridBox
            $fxDirection={"row"}
            $gap={8}
            $templateColumns={["1fr", "1fr"]}
          >
            <Label label={"Price"}>
              <InputNumber
                style={{ width: "100%" }}
                value={fv.price}
                {...form.register("price", {
                  required: true,
                  onBlur: () => {
                    if (form.formValues.quantity) {
                      form.setValue(
                        "total",
                        Number(
                          form.formValues.quantity *
                            (form.formValues.price ?? 0),
                        ),
                      );
                    }
                  },
                })}
                onChange={(value) => {
                  form.setValue("price", Number(value), {
                    shouldTouch: true,
                  });
                }}
              />
            </Label>

            <Label label={"Total"}>
              <InputNumber
                style={{ width: "100%" }}
                // value={fv.quantity}
                {...form.registerWithValue("total", {
                  onBlur: () => {
                    if (form.formValues.quantity) {
                      form.setValue(
                        "price",
                        Number(
                          (form.formValues.total ?? 0) /
                            form.formValues.quantity,
                        ),
                      );
                    } else if (form.formValues.price) {
                      form.setValue(
                        "quantity",
                        Number(
                          (form.formValues.total ?? 0) / form.formValues.price,
                        ),
                      );
                    }
                  },
                })}
                onChange={(value) => {
                  form.setValue("total", Number(value), {
                    shouldTouch: true,
                    shouldDirty: true,
                  });
                }}
              />
            </Label>
          </SimpleGridBox>

          <Label label={"Token"}>
            <Select
              options={tokensOptions}
              placeholder={"Select token"}
              allowClear
              onChange={(value) => {
                const token = tokensList[value];
                if (token) {
                  form.setValue("token", token);
                } else {
                  form.unregister("token");
                }
              }}
            />
          </Label>
          <Label label={"Currency"}>
            <Input
              // value={fv?.currency}
              {...(form.registerWithValue("currency") as any)}
            />
          </Label>

          <Label label={"Category"}>
            <Input
              // value={fv.category}
              {...(form.registerWithValue("category") as any)}
            />
          </Label>

          <Separator text={"Order"} />

          <Label label={"Order ID"}>
            <Input
              // value={fv.order?.id}
              {...form.registerWithValue("order.id")}
            />
          </Label>
          <Label label={"Order total"}>
            <Input
              // value={fv.order.total}
              {...form.registerWithValue("order.total", {
                valueAsNumber: true,
              })}
              disabled={!fv.order?.id}
              type={"number"}
            />
          </Label>

          <Separator text={"Cashback"} />

          <Label label={"Offset"}>
            <SimpleGridBox
              $fxDirection={"row"}
              $gap={8}
              $templateColumns={["1fr", "1fr"]}
            >
              <Label label={"Amount"}>
                <Input
                  // value={fv.cashback?.offsetAmount}
                  {...form.registerWithValue("cashback.offsetAmount")}
                />
              </Label>

              <Label label={"Percentage"}>
                <Input
                  // value={fv.cashback?.offsetPercentage}
                  {...form.registerWithValue("cashback.offsetPercentage")}
                />
              </Label>
            </SimpleGridBox>
          </Label>

          <Label label={"Custom key"}>
            <AutoComplete
              // value={fv.cashback?.customKey}

              {...form.registerWithValue("cashback.customKey")}
              options={customKeysOptions}
              onChange={(value) => {
                form.setValue("cashback.customKey", value, {
                  shouldTouch: true,
                  shouldDirty: true,
                });
              }}
            />
          </Label>

          <Label label={"Token"}>
            <Select
              options={tokensOptions}
              placeholder={"Select token"}
              allowClear
              onChange={(value) => {
                const token = tokensList[value];
                if (token) {
                  form.setValue("cashback.tokenId", token?._id);
                } else {
                  form.unregister("cashback.tokenId");
                }
              }}
            />
          </Label>

          <Label label={"Type"}>
            <Input
              // value={fv.cashback?.type}
              {...form.registerWithValue("cashback.type")}
            />
          </Label>
        </FlexBox>

        {/*<Separator text={"Actions"} />*/}

        {/*<Dropdown*/}
        {/*  placement={"top"}*/}
        {/*  menu={{*/}
        {/*    items: [*/}
        {/*      {*/}
        {/*        key: "emit",*/}
        {/*        type: "group",*/}
        {/*        label: "Emit",*/}
        {/*      },*/}
        {/*      {*/}
        {/*        key: "current",*/}
        {/*        label: <Option label={"Current"} />,*/}
        {/*        onClick: onEmitCurrentPress,*/}
        {/*      },*/}
        {/*      {*/}
        {/*        key: "all",*/}
        {/*        disabled: true,*/}
        {/*        label: <Option label={"All"} />,*/}
        {/*        onClick: () => {},*/}
        {/*      },*/}
        {/*      // {*/}
        {/*      //   key: "list",*/}
        {/*      //   type: "group",*/}
        {/*      //   label: "List",*/}
        {/*      // },*/}
        {/*      // {*/}
        {/*      //   key: "add_one",*/}
        {/*      //   label: <Option label={"Add"} />,*/}
        {/*      //   onClick: onAddEventToListPress,*/}
        {/*      // },*/}
        {/*      // {*/}
        {/*      //   key: "reset_all",*/}
        {/*      //   label: <Option label={"Clear"} />,*/}
        {/*      //   // onClick: () => setEvents([]),*/}
        {/*      // },*/}
        {/*      {*/}
        {/*        key: "form",*/}
        {/*        type: "group",*/}
        {/*        label: "Form",*/}
        {/*      },*/}

        {/*      {*/}
        {/*        key: "reset_form",*/}
        {/*        label: <Option label={"Reset"} />,*/}
        {/*        onClick: () => form.reset(),*/}
        {/*      },*/}
        {/*    ],*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Button>*/}
        {/*    {"Actions"}*/}

        {/*    <PlusOutlined />*/}
        {/*  </Button>*/}
        {/*</Dropdown>*/}
      </FlexForm>
    </ModalBase>
  );
};

const Option = ({ label }: { label?: string }) => {
  return (
    <FlexBox
      $fillWidth
      $fxDirection={"row"}
      $alignItems={"center"}
      $justifyContent={"space-between"}
      $padding={"0 0 0 8px"}
    >
      <Text>{label}</Text>
    </FlexBox>
  );
};
export default EmitRevenueEventBlock;

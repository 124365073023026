import {
  getCurrentUserThunk,
  logInByEmailThunk,
  logoutUserThunk,
} from "./auth.thunks";
import { RefmeUserRoleEnum, UserEntity } from "../../types";
import { createSlice } from "@reduxjs/toolkit";
import { Keys } from "../../types/utils.types";
import ToastService from "../../services/ToastService.serv";
import { getRoles } from "../../shared/utils";
import { Action, ActionPayload } from "../store.redux";
import { AppAuth } from "../../types/auth/app-auth.namespace";

export interface AuthState {
  user?: UserEntity;
  session?: AppAuth.Session.Entity;
  isLogged?: boolean;
  roleIs: Partial<Record<Keys<typeof RefmeUserRoleEnum>, boolean>>;
  access_token?: string | null;
  refresh_token?: string | null;
  // cardList?: BonusCardEntity[];
}

const initialState: AuthState = {
  access_token: null,
  refresh_token: null,
  user: undefined,
  session: undefined,
  roleIs: {},
};

export type SetAuthTokensActionPayload = ActionPayload<{
  _id: string;
  access_token: string;
  refresh_token?: string;
}>;

const Msgs = {
  logInError: "Opps, something went wrong when try to login",
  logOutError: "Opps, something went wrong when try to logout",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUserAction(s, a: Action<{ data: AppAuth.LoggedUser }>) {
      s.access_token = a.payload?.data.access_token;
      s.refresh_token = a.payload?.data.refresh_token;
      s.user = a.payload.data;
      s.isLogged = true;
      const roleIs = getRoles(a.payload.data);
      if (roleIs) {
        s.roleIs = roleIs;
      }
    },

    clearUserAction: logOutUserReducer,
    setAuthTokensAction: (
      st,
      {
        payload: { access_token, refresh_token },
      }: Action<SetAuthTokensActionPayload>,
    ) => {
      return Object.assign(st, { access_token, refresh_token });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logInByEmailThunk.fulfilled, (s, a) => {
        const { access_token, refresh_token, ...data } = a.payload.data;

        s.access_token = access_token;
        s.refresh_token = refresh_token;
        s.isLogged = true;
        s.user = data;

        ToastService.info("Logged in");
      })
      .addCase(logInByEmailThunk.rejected, (s, action) => {
        ToastService.error(action.error.message || Msgs.logInError);
      })
      .addCase(getCurrentUserThunk.fulfilled, (s, a) => {
        const { access_token } = a.payload.data;

        s.user = a.payload.data;
        s.roleIs = getRoles(a.payload.data) ?? {};
        if (access_token) {
          s.access_token = access_token;
        }
      })

      .addCase(logoutUserThunk.fulfilled, (s) => {
        ToastService.success("Log out success");
        return logOutUserReducer(s);
      })
      .addCase(logoutUserThunk.rejected, (s, action) => {
        ToastService.error(action.error?.message || Msgs.logInError);
        return logOutUserReducer(s);
      }),
});
function logOutUserReducer(s: AuthState) {
  return Object.assign(s, initialState);
}
export const { setUserAction, setAuthTokensAction, clearUserAction } =
  authSlice.actions;

import { MlmRuleTypeEnum, RefmeUserRoleEnum } from "../types";
import { Keys } from "../types/utils.types";

export const API_BASE_ROUTES = {
  APP: "APP",
  AUTH: `/auth`,
  SESSIONS: `/auth/sessions`,
  USERS: `/users`,
  TRANSACTIONS: `/transactions`,
  CARDS: `/cards`,
  INVOICES: `/invoices`,
  TOKENS: `/tokens`,
  MLM_RULES: `/mlm-rules`,
  WALLETS: `/wallets`,
  BALANCES: `/balance-accounts`,
  EVENTS: `/events`,
  EVENTS_SSE: `/events/sse`,
  DEVICES: `/auth/devices`,
  O_AUTH: `/auth/o-auth`,
  O_AUTH_CONNECTIONS: `/auth/o-auth/connections`,
};

export enum Endpoints {
  getAll = "getAll",
  list = "list",
  getList = "getList",
  one = "one",
  create = "create",
  update = "update",
  getOne = "getOne",
}
type BaseEndpointsInput = Record<Endpoints | string, string>;
function createEndpoints<ValuesMap extends BaseEndpointsInput>(
  entryPoint: Keys<typeof API_BASE_ROUTES>,
  input: ValuesMap,
) {
  const baseUr = API_BASE_ROUTES[entryPoint] ?? entryPoint;
  type KeyType = Keys<ValuesMap>;

  return Object.assign(
    {},
    ...Object.entries(input).map(([key, value]) => {
      return {
        [key]: () => baseUr + (value.startsWith("/") ? value : "/" + value),
      };
    }),
  ) as Record<KeyType, () => string>;
}

const base = API_BASE_ROUTES;

const auth = {
  register: (role: RefmeUserRoleEnum = RefmeUserRoleEnum.ambassador) =>
    `${base.AUTH}/signUp/${role}`,
  ...createEndpoints("AUTH", {
    logIn: `logIn`,
    logOut: `logOut`,
    getCurrent: `getCurrent`,
    refresh: `refresh-token`,
  }),

  devices: createEndpoints("DEVICES", {
    register: "register",
    attach: "attach",
  }),

  o_auth: {
    ...createEndpoints("O_AUTH", {
      logOut: "logOut",
      logIn: "logIn",
      accept: "accept",

      getAuthUrl: "getAuthUrl",
      callback: "callback",
      refresh: "refresh",
      current: "current",
      save_tokens: "save_tokens",
    }),

    connections: createEndpoints("O_AUTH_CONNECTIONS", {
      getOne: "getOne",
      getAll: "getAll",
      create: "create",
    }),
  },
};

const users = {
  getAll: () => `${base.USERS}/list`,
  getById: () => `${base.USERS}/one`,
  getReferrals: (id?: string) => `${base.USERS}/${id}/descendants`,
  update: () => `${base.USERS}/profile/update`,
};

const tokens = createEndpoints("TOKENS", {
  getAll: `/list`,
  getOne: `/one`,
  emitBonus: `/emit/bonus`,
  emitFiat: `/emit/fiat`,
});

const sessions = createEndpoints("SESSIONS", {
  getAll: "list",
  startService: "start/service",
});
const wallets = createEndpoints("WALLETS", Endpoints);
const balances = {
  ...createEndpoints("BALANCES", Endpoints),
  createAmbassador: () => `${base.BALANCES}/create-ambassador-accounts`,
};

const invoices = {
  paymentRequest: () => `${base.INVOICES}/payment-request`,
  transferRequest: () => `${base.INVOICES}/transfer-request`,
  getList: () => `${base.INVOICES}/list`,
};

const transactions = {
  getList: () => `${base.TRANSACTIONS}/list`,
  createOnePerToPer: () => `${base.TRANSACTIONS}/create/perToPer/one`,
};

const mlm_rules = {
  getAll: () => `${base.MLM_RULES}/list`,
  createRuleByType: (type?: MlmRuleTypeEnum) =>
    `${base.MLM_RULES}/create/${type}`,
  create: () => `${base.MLM_RULES}/create`,
  update: () => `${base.MLM_RULES}/update`,
  delete: (id?: string) => `${base.MLM_RULES}/one/${id}`,
  restore: (id?: string) => `${base.MLM_RULES}/restore/one/${id}`,
  deleteRules: () => `${base.MLM_RULES}/many`,
  deleteMany: () => `${base.MLM_RULES}/many/rules`,

  groups: {
    getAll: () => `${base.MLM_RULES}/groups/list`,
    create: () => `${base.MLM_RULES}/groups/create`,
    delete: (id?: string) => `${base.MLM_RULES}/groups/${id}`,
    restore: (id?: string) => `${base.MLM_RULES}/groups/restore/one/${id}`,
  },
};

const events = {
  getRevenuesList: () => `${base.EVENTS}/list/revenue`,
  trackRevenue: () => `${base.EVENTS}/track/revenue`,
  track: () => `${base.EVENTS}/track`,
  getList: () => `${base.EVENTS}/list`,
};

const APP_CONFIGS = {
  endpoints: {
    auth,
    users,
    tokens,
    sessions,
    balances,
    invoices,
    transactions,
    mlm_rules,
    events,
    wallets,
  },
};

export default APP_CONFIGS;

import { useModalService } from "../../../providers/ModalProvider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMlmRulesSelector } from "../../../redux/selectors.redux";
import { useAppDispatch } from "../../../redux/store.redux";
import {
  getMlmRulesListThunk,
  removeMlmRuleThunk,
} from "../../../redux/mlm-rules/mlm-rules.thunks";
import { MlmRuleEntity, MlmRuleTypeEnum } from "../../../types";
import { BlockTemplate } from "../BlockTemplate";
import FlexBox from "../../Atoms/Flex";
import { Text } from "../../Atoms/Text";
import { Button, Select } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import CreateMlmRuleModal from "../../modals/CreateMlmRuleModal";
import { useAppParams, useLoaders } from "../../../hooks";
import { TableWithActions } from "../../Tables/TableWithActions";
import {
  deleteStatusColumn,
  mlmRuleSourceVolumeColumn,
  mlmRuleTargetVolumesColumn,
  mlmRuleThresholdTypeColumn,
  mlmRuleUserStatusColumn,
  mlmRuleValueTypeColumn,
  mlmRuleWalletStatusColumn,
} from "../../table-columns/tableColumns";

const dataKeys = ["userStatus", "walletStatus"] as const;
const labels: Record<"userStatus" | "walletStatus", string> = {
  walletStatus: "Wallet status",
  userStatus: "User status",
};
export const PersonalRulesBlock = () => {
  const modalS = useModalService();
  const customKeysSet = useMlmRulesSelector().customKeysSet;
  const rules = useMlmRulesSelector().lists.personal;
  const dispatch = useAppDispatch();
  const params = useAppParams();
  const loaders = useLoaders<"list" | "deleting" | "updating">();
  const [customKey, setCustomKey] = useState<string | undefined>();

  // const rulesWithKey = useMemo(
  //   () => rules?.map((r) => ({ ...r, key: getRuleKey(r) }) || []),
  //   [rules],
  // );

  const loadData = useCallback(() => {
    dispatch(
      getMlmRulesListThunk({
        onLoading: loaders.onLoading("list"),
        params: {
          type: MlmRuleTypeEnum.personal,
        },
      }),
    );
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [dispatch]);

  const { dataMap } = useMemo((): {
    dataMap: Map<"userStatus" | "walletStatus", MlmRuleEntity[]>;
  } => {
    const map = new Map<"userStatus" | "walletStatus", MlmRuleEntity[]>();

    rules?.forEach((rule) => {
      dataKeys.forEach((dataKey) => {
        const value = rule?.[dataKey];
        if (!value) {
          return;
        }
        const current = map.get(dataKey);
        if (current) {
          current?.push(rule);
          map.set(dataKey, current);
        } else {
          map.set(dataKey, [rule]);
        }
      });
    });

    return {
      dataMap: map,
    };
  }, [rules]);

  return (
    <BlockTemplate
      contentContainerStyles={{
        $padding: "0 16px 16px",
        $xsStyles: { $padding: "0 0 16px" },
      }}
      header={
        <FlexBox
          $fxDirection={"row"}
          $alignItems={"center"}
          $justifyContent={"space-between"}
          $gap={10}
          style={{ fontSize: "14px", fontWeight: 600 }}
          $flexWrap={"wrap"}
        >
          <Text style={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {"Personal rules"}
          </Text>

          <Select
            value={customKey}
            style={{ width: "210px" }}
            placeholder={"Select key"}
            options={customKeysSet.map((c) => {
              return { value: c, label: c };
            })}
            onSelect={(value, option) => {
              setCustomKey(value);
            }}
          />

          <Button
            loading={loaders.isLoading.list}
            onClick={() => {
              loadData();
            }}
          >
            <ReloadOutlined size={16} />
          </Button>
        </FlexBox>
      }
    >
      {dataKeys.map((dataKey) => {
        const data = dataMap.get(dataKey);

        return (
          <TableWithActions<MlmRuleEntity>
            key={dataKey}
            data={data}
            onAddPress={() => {
              params.groupId &&
                modalS.open(CreateMlmRuleModal, {
                  defaultValues: {
                    groupId: params.groupId,
                    type: MlmRuleTypeEnum.personal,
                  },
                });
            }}
            onEdit={(data) => {
              modalS.open(CreateMlmRuleModal, {
                defaultValues: data,
              });
            }}
            onRemove={(data) => {
              dispatch(removeMlmRuleThunk({ params: { id: data._id } }));
            }}
            label={labels?.[dataKey]}
            isDeleting={loaders.isLoading.deleting}
            columns={[
              mlmRuleWalletStatusColumn({
                title: "Status",
                isVisible: dataKey === "walletStatus",
              }),
              mlmRuleUserStatusColumn({
                title: "Status",
                isVisible: dataKey === "userStatus",
              }),
              { title: "Value", path: "value", align: "right" },
              mlmRuleValueTypeColumn(),

              mlmRuleTargetVolumesColumn(),
              mlmRuleSourceVolumeColumn(),
              mlmRuleThresholdTypeColumn(),
              { title: "Threshold", path: "threshold" },
              { title: "Custom key", path: "customKey" },
              deleteStatusColumn(),
            ]}
          />
        );
      })}
    </BlockTemplate>
  );
};

import type { Params } from "@remix-run/router";
import { useParams } from "react-router";

type UseParamsReturn<
  ParamsOrKey extends string | Record<string, string | undefined> = string,
> = Readonly<
  [ParamsOrKey] extends [string] ? Params<ParamsOrKey> : Partial<ParamsOrKey>
>;
export type AppUrlParams = "groupId" | "id" | "label" | "types" | "type";
export const useAppParams = () => {
  return useParams<AppUrlParams>();
};

import { useNavigate } from "react-router-dom";
import FlexBox from "../components/Atoms/Flex";
import { BlockTemplate } from "../components/Blocks/BlockTemplate";
import { Button } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import Separator from "../components/Atoms/Separator";
import { Text } from "../components/Atoms/Text";

const NotFoundPage = () => {
  const navTo = useNavigate();

  const onGoBackPress = () => {
    window.history.back();
  };

  // const history = useHistory();
  return (
    <FlexBox
      $flex={1}
      height={"100vh"}
      margin={"auto"}
      $fillWidth
      $alignItems={"center"}
      $justifyContent={"center"}
      $padding={"16px"}
      $maxWidth={"360px"}
    >
      <BlockTemplate title={"Page not found"}>
        <FlexBox $fillWidth $padding={"16px 0"} margin={"auto"} $gap={16}>
          <Text
            $size={60}
            $weight={900}
            style={{ width: "100%", textAlign: "center" }}
          >
            404
          </Text>

          <Button type={"primary"} onClick={() => navTo("/")}>
            {"Go main"}

            <ArrowRightOutlined />
          </Button>

          <Separator text={"Or"} />

          <Button type={"default"} onClick={onGoBackPress}>
            <ArrowLeftOutlined />

            {"Go back"}
          </Button>
        </FlexBox>
      </BlockTemplate>
    </FlexBox>
  );
};

export default NotFoundPage;

import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth/auth.slice";
import persistReducer from "redux-persist/es/persistReducer";
import { persistorConfigs } from "./configs.redux";
import { tokensSlice } from "./tokens/tokens.slice";
import { mlmRulesSlice } from "./mlm-rules/mlm-rules.slice";
import { invoicesSlice } from "./invoices/invoices.slice";
import { authSessionsSlice } from "./auth-sessions/auth-sessions.slice";
import { transactionsSlice } from "./transactions/transactions.slice";
import { usersSlice } from "./users/users.slice";
import { eventsSlice } from "./events/events.slice";
import { walletsSlice } from "./wallets/wallets.slice";
import { oAuthSlice } from "./auth/o-auth/o-auth.slice";

const reducersMap = {
  [authSlice.name]: persistReducer(persistorConfigs.auth, authSlice.reducer),
  [tokensSlice.name]: persistReducer(
    persistorConfigs.tokens,
    tokensSlice.reducer,
  ),
  [oAuthSlice.name]: oAuthSlice.reducer,
  [mlmRulesSlice.name]: persistReducer(
    persistorConfigs.mlm_rules,
    mlmRulesSlice.reducer,
  ),
  [invoicesSlice.name]: invoicesSlice.reducer,
  [authSessionsSlice.name]: persistReducer(
    persistorConfigs.authSessions,
    authSessionsSlice.reducer,
  ),
  [transactionsSlice.name]: persistReducer(
    persistorConfigs.transactions,
    transactionsSlice.reducer,
  ),
  [usersSlice.name]: persistReducer(persistorConfigs.users, usersSlice.reducer),
  [eventsSlice.name]: persistReducer(
    persistorConfigs.events,
    eventsSlice.reducer,
  ),
  [walletsSlice.name]: persistReducer(
    persistorConfigs.wallets,
    walletsSlice.reducer,
  ),
};

const rootReducer = combineReducers(reducersMap);

export type RootReducerType = typeof rootReducer;

export default rootReducer;

import { RefmeUserRoleEnum } from "../../../types";
import { createApiCall, GetResponseCallback } from "../../../api";
import { BlockTemplate } from "../BlockTemplate";
import React, { useState } from "react";
import ToastService from "../../../services/ToastService.serv";
import { FlexForm } from "../../Atoms/Flex";
import { registerInputsData } from "../../data/formsData";
import FormInput from "../../FormElements/FormInput";
import { Button, Select } from "antd";
import AuthApi from "../../../api/auth/Auth.api";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Text } from "../../Atoms/Text";
import { AppAuth } from "../../../types/auth/app-auth.namespace";

const roles: {
  label: string;
  value: RefmeUserRoleEnum;
  getResCb?: GetResponseCallback<
    AppAuth.RegisterByEmailDto,
    { email?: string }
  >;
}[] = [
  {
    label: "Vendor",
    value: RefmeUserRoleEnum.vendor,
  },
  {
    label: "Manager",
    value: RefmeUserRoleEnum.manager,
  },
  {
    label: "Admin",
    value: RefmeUserRoleEnum.admin,
  },
];

const RegisterUserBlock = () => {
  const navTo = useNavigate();
  const { register, handleSubmit, reset } =
    useForm<AppAuth.RegisterByEmailDto>();

  const [role, setRole] = useState(roles[0].value);

  const onSubmit = (data: AppAuth.RegisterByEmailDto) => {
    createApiCall(AuthApi.register, {
      data: {
        email: data.email,
        password: data.password,
      },
      params: { role: role, admin_secret_key: data?.admin?.private_key },
      logError: true,
      onError: (e) => {
        ToastService.error(
          e?.response?.data?.message ??
            "Opps, something went wrong when try to register",
        );
      },
      onSuccess: (d) => {
        ToastService.success("Register success");
        reset();
        navTo(`/logIn?email=${d?.data?.email}&status=success&role=${role}`);
      },
    });
  };
  return (
    <BlockTemplate title={'Welcome to "Refme admin"'}>
      <FlexForm
        $gap={16}
        onSubmit={handleSubmit(onSubmit, (errors, event) => {
          console.error(errors);
        })}
        width={"360px"}
        $maxWidth={"100%"}
        $alignItems={"stretch"}
      >
        <Text $size={15} $weight={500} $align={"center"}>
          {"Registration"}
        </Text>

        {registerInputsData.map((props, index) => (
          <FormInput {...props} key={index} register={register} />
        ))}

        <Select options={roles} onChange={setRole} value={role} />
        {role === RefmeUserRoleEnum.admin && (
          <FormInput
            {...{
              placeholder: "Super admin permission",
              name: "admin.private_key",
              shouldUnregister: true,
              required: true,
              type: "password",
            }}
            register={register}
          />
        )}

        <Button type={"primary"} htmlType={"submit"}>
          {"Accept"}
        </Button>

        <Button type={"text"} onClick={() => navTo("/logIn")}>
          {"Log In"}
        </Button>
      </FlexForm>
    </BlockTemplate>
  );
};

export default RegisterUserBlock;

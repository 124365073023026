export function ObjectKeys<
  Obj extends object = object,
  Key extends keyof Obj = keyof Obj,
>(obj: Obj): Key[] {
  return Object.keys(obj) as Key[];
}
export function ObjectValues<
  Obj extends object = any,
  Key extends keyof Obj = keyof Obj,
  Value extends Obj[Key] = Obj[Key],
>(obj: Obj): Value[] {
  return Object.values(obj) as Value[];
}

export function ObjectEntries<
  Obj extends object = any,
  Key extends keyof Obj = keyof Obj,
  Value extends Obj[Key] = Obj[Key],
>(obj: Obj): [Key, Value][] {
  return Object.entries(obj) as [Key, Value][];
}

export function EnumToSelectOptions<
  Obj extends Record<string, string | number> = Record<string, string | number>,
  // Value = any,
>(
  obj: Obj,
  gteLabel?: (o: keyof Obj) => React.ReactNode,
  // gteValue?: (key: keyof Obj, value: Obj[keyof Obj]) => Value,
): {
  label: keyof Obj | React.ReactNode;
  value: Obj[keyof Obj];
}[] {
  return ObjectEntries<Obj>(obj).map(([k, value]) => {
    return {
      label: gteLabel ? gteLabel(k) : k,
      value: value,
    };
  });
}

// export function ObjectFromEntries<
//   Obj extends object = any,
//   Key extends keyof Obj = keyof Obj,
//   Value extends Obj[Key] = Obj[Key],
// >(obj: Obj): [Key, Value][] {
//   return Object.entries(obj) as [Key, Value][];
// }
export function ObjectFromEntries<Key extends string, T>(
  entries: Iterable<readonly [Key, T]>,
): {
  [key in Key]: T;
} {
  return Object.fromEntries(entries) as { [key in Key]: T };
}

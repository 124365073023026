import {
  FieldValues,
  Path,
  PathValue,
  useForm,
  UseFormRegisterReturn,
  UseFormReturn,
} from "react-hook-form";
import { UseFormProps } from "react-hook-form/dist/types";
import { SelectHandler } from "rc-select/lib/Select";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

export type UseRegisterOnSelect<F extends FieldValues = FieldValues> = <
  Name extends Path<F>,
>(
  name: Name,
) => SelectHandler<PathValue<F, Name>>;

export type UseAppFormRegisterWithValue<F extends FieldValues = FieldValues> = <
  Name extends Path<F>,
>(
  name: Name,
  options?: RegisterOptions<F, Name>,
) => UseFormRegisterReturn<Path<F>> & { value: PathValue<F, Name> | undefined };

export type UseAppFormRegisterWithValueReturn<
  F extends FieldValues = FieldValues,
> = ReturnType<UseAppFormRegisterWithValue<F>>;

export type UseAppFormReturn<F extends FieldValues = FieldValues> = ReturnType<
  typeof useAppForm<F>
>;

export const useAppForm = <F extends FieldValues = FieldValues>(
  args?: UseFormProps<F>,
): UseFormReturn<F> & {
  registerOnSelect: UseRegisterOnSelect<F>;
  registerWithValue: UseAppFormRegisterWithValue<F>;
  formValues: F;
} => {
  const form = useForm<F>(args);
  const formValues = form.watch();

  return {
    formValues,
    ...form,
    registerOnSelect: (name) => {
      return (value) => {
        form.setValue(name, value, {
          shouldTouch: true,
          shouldDirty: true,
        });
      };
    },
    register: (name, options) => {
      const { onChange, ...r } = form.register(name, options);

      return {
        ...r,
        // value: getValueByPath({ data: formValues, path: name }) ?? undefined,
        onChange: (event: React.ChangeEvent<HTMLInputElement> | any) => {
          return onChange(event).then((res) => {
            form.setValue(
              event.target?.name as any,
              event.target?.value as any,
              {
                shouldTouch: true,
              },
            );
            return res;
          });
        },
      };
    },
    registerWithValue: (name, options) => {
      const { onChange, ...r } = form.register(name, options);
      const value = form.getValues(name);
      return {
        ...r,

        value: (value as any) ?? undefined,
        onChange: (event: React.ChangeEvent<HTMLInputElement> | any) => {
          return onChange(event).then((res) => {
            form.setValue(
              event.target?.name as any,
              event.target?.value as any,
              {
                shouldTouch: true,
                shouldDirty: true,
              },
            );
            return res;
          });
        },
      };
    },
  };
};

import { DecimalValue, HasType, IBase } from "./utils.types";
import { UserEntity } from "./user.types";

export namespace Token {
  export enum TypeEnum {
    fiat = "fiat",
    crypto = "crypto",
    bonus = "bonus",
  }
  export interface Entity extends IBase, HasType<TypeEnum> {
    owner?: UserEntity;

    symbol?: string;

    icon?: string;

    label?: string;

    description?: string;

    amount?: DecimalValue;

    decimals?: number;

    minTransferAmount?: DecimalValue;
    maxTransferAmount?: DecimalValue;

    commissionSender?: number;
    commissionReceiver?: number;

    privateKey?: string;
    publicKey?: string;
  }

  export interface EmitDto {
    label?: string;
    symbol?: string;
    amount?: number;
    decimals?: number;
    minTransferAmount?: number;
    maxTransferAmount?: number;
    commissionSender?: number;
    commissionReceiver?: number;
    description?: string;
    type?: TypeEnum;
  }
}
export type TokenEntity = Token.Entity;

export type EmitTokenDto = Token.EmitDto;

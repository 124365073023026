import React from "react";
import FormInput from "../FormElements/FormInput";
import { generateTokenData } from "../data/formsData";
import { FlexBox, FlexForm } from "../Atoms/Flex";
import { Alert, Button, Radio } from "antd";
import {
  useAuthSelector,
  useTokensSelector,
} from "../../redux/selectors.redux";
import { useAppDispatch } from "../../redux/store.redux";
import { EmitTokenDto, Token, TokenEntity } from "../../types";
import { BlockTemplate } from "./BlockTemplate";
import { Label } from "../FormElements/Label";
import {
  deleteStatusColumn,
  tokenTypeColumn,
} from "../table-columns/tableColumns";
import { TableWithActions } from "../Tables/TableWithActions";
import {
  emitBonusTokenThunk,
  emitFiatTokenThunk,
} from "../../redux/tokens/tokens.thunks";
import { BaseTableProps } from "../Atoms/Table";
import { EnumToSelectOptions } from "../../shared/utils";
import { useAppForm } from "../../hooks";
import { Text } from "../Atoms/Text";
import { CreatedModal, useModalService } from "../../providers/ModalProvider";
import ModalBase from "../Atoms/ModalBase";

interface EmitTokenFormData extends EmitTokenDto {}

const tokenTypeOptions = EnumToSelectOptions(Token.TypeEnum);
const TokensListBlock = () => {
  const { roleIs } = useAuthSelector();
  const modalS = useModalService();

  const tokens = useTokensSelector().list;

  return (
    <FlexBox $gap={16}>
      <BlockTemplate
        contentContainerStyles={{
          $padding: "0 16px 16px",
          $xsStyles: { $padding: "0 0 16px" },
        }}
        header={
          <FlexBox
            $fillWidth
            $fxDirection={"row"}
            $justifyContent={"space-between"}
            $alignItems={"center"}
          >
            <Text $weight={600}>{"Tokens list"}</Text>

            {!roleIs.admin && !!tokens.length && (
              <Alert message={"Tokens limit is reached"} type={"warning"} />
            )}
          </FlexBox>
        }
      >
        <TableWithActions
          data={tokens}
          columns={tokenBaseTableColumns}
          onAddPress={() => {
            modalS.open(EmitTokenModal);
          }}
        />
      </BlockTemplate>
    </FlexBox>
  );
};

const tokenBaseTableColumns: BaseTableProps<TokenEntity>["columns"] = [
  {
    title: "Label",
    path: "label",
    width: "125px",
  },

  {
    title: "Symbol",
    path: "symbol",
    width: "80px",
  },

  tokenTypeColumn(),

  {
    title: "Description",
    path: "description",
    width: "200px",
  },
  { title: "Amount", path: "amount", width: "190px" },
  { title: "Decimals", path: "decimals", width: "90px" },
  {
    title: "Public key",
    path: "publicKey",
    width: "180px",
    canBeCopied: true,
  },
  { title: "ID", path: "_id", width: "320px" },
  deleteStatusColumn(),
];
export default TokensListBlock;

export const EmitTokenModal = (props: CreatedModal) => {
  const { roleIs } = useAuthSelector();

  const dispatch = useAppDispatch();
  const { registerWithValue, handleSubmit, reset, setValue } =
    useAppForm<EmitTokenFormData>({
      defaultValues: { type: Token.TypeEnum.bonus },
    });

  const emitBonusTokenKey = (data: EmitTokenFormData) => {
    dispatch(
      emitBonusTokenThunk({
        data,
        onSuccess: () => {
          reset();
        },
      }),
    );
  };
  const emitFiatTokenKey = (data: EmitTokenFormData) => {
    dispatch(
      emitFiatTokenThunk({
        data,
        onSuccess: () => {
          reset();
        },
      }),
    );
  };
  return (
    <ModalBase title={"Emit token"} {...props}>
      {
        <FlexForm
          onReset={() => {
            props.onClose && props.onClose();
          }}
          onSubmit={handleSubmit((fData) => {
            if (fData.type === Token.TypeEnum.bonus) {
              emitBonusTokenKey(fData);
            } else if (fData.type === Token.TypeEnum.fiat) {
              emitFiatTokenKey(fData);
            }
            console.log(fData);
          })}
          $gap={16}
          width={"360px"}
          $maxWidth={"100%"}
        >
          <FlexBox $gap={6}>
            {roleIs.admin && (
              <Radio.Group
                style={{
                  margin: "auto",
                }}
                onChange={(ev) => {
                  ev.target.value &&
                    setValue("type", ev.target.value as never, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                }}
                defaultValue={Token.TypeEnum.bonus}
              >
                {tokenTypeOptions.map((option) => {
                  return (
                    <Radio.Button
                      key={option.value}
                      disabled={option.value === Token.TypeEnum.crypto}
                      value={option.value}
                    >
                      {option.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            )}

            {generateTokenData.map((item) => {
              return (
                <Label
                  key={item.label}
                  label={item.placeholder}
                  required={item.required}
                >
                  <FormInput
                    key={item.name}
                    {...item}
                    {...registerWithValue(item.name)}
                  />
                </Label>
              );
            })}
          </FlexBox>

          <FlexBox $gap={8} $padding={"16px 0 8px"}>
            <Button htmlType="submit" type={"primary"}>
              {"Emit"}
            </Button>

            <Button htmlType="reset" type={"default"}>
              {"Cancel"}
            </Button>
          </FlexBox>
        </FlexForm>
      }
    </ModalBase>
  );
};

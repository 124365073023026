import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FlexBox from "../Atoms/Flex";
import { Button, Layout, Menu, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { appLinks, AppRoteObj } from "./appLinks";
import { LogoutOutlined } from "@ant-design/icons";
import { Text } from "../Atoms/Text";
import { logoutUserThunk } from "../../redux/auth/auth.thunks";
import { useAppDispatch } from "../../redux/store.redux";
import { useAuthSelector } from "../../redux/selectors.redux";

const { Sider } = Layout;

const SideBar = () => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuthSelector();

  const [current, setCurrent] = useState<`path_${string}` | string>(
    `path_${appLinks[0].path}`,
  );

  useEffect(() => {
    appLinks.forEach((link) => {
      setCurrent(`path_${link.path}`);
    });
  }, [current]);
  const isVisible = (route: AppRoteObj) =>
    route.roles?.length && user?.role
      ? route.roles?.includes(user?.role)
      : true;

  const sider_items: MenuProps["items"] = appLinks
    ?.filter(isVisible)
    .map(({ icon, label, path, children }) => {
      return {
        key: `option_${path}`,
        icon: React.createElement(icon),
        label,
        onClick: !children?.length
          ? () => {
              nav(path);
            }
          : undefined,

        children: children
          ?.filter(isVisible)
          ?.map(({ icon, label, path }, j) => {
            return {
              key: `sub_${path}`,
              label: label,
              icon: React.createElement(icon),
              onClick: () => {
                nav(path);
              },
            };
          }),
      };
    });

  return (
    <FlexBox
      height={"100%"}
      $flex={1}
      $justifyContent="space-between"
      $alignItems="flex-start"
      $padding={"16px 0"}
    >
      <Sider width={200} style={{ height: "100%", background: "#fff" }}>
        <FlexBox
          $padding={"0 16px"}
          $fillHeight
          $justifyContent={"space-between"}
          $fxDirection={"row"}
          $alignItems={"baseline"}
        >
          <Text $size={16} $weight={700}>
            {"Refme"}
          </Text>
          <Text $size={13} $weight={600}>
            {user?.role}
          </Text>
        </FlexBox>

        <Menu
          mode="inline"
          defaultSelectedKeys={[current]}
          defaultOpenKeys={[current]}
          style={{ height: "100%", borderRight: 0 }}
          items={sider_items}
        />
      </Sider>

      <FlexBox $fillWidth $padding={"0 16px"}>
        <Button
          type={"text"}
          onClick={() => {
            dispatch(logoutUserThunk({}));
          }}
        >
          <LogoutOutlined />

          <Text>{"Log out"}</Text>
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export const SideBarContainer = styled(FlexBox)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;

  width: max-content;

  //min-width: 190px;
  max-height: 100%;
  height: 100vh;

  font-size: 18px;
  min-height: 60px;
  background-color: ${(p) => p.theme.mainBgColor};
  //border-right: 1px solid ${(p) => p.theme.lightBorderColor};

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export default SideBar;

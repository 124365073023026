import FlexBox from "../components/Atoms/Flex";
import { AppQuery, useAppRouter, useLoaders } from "../hooks";
import { Button, Tabs, TabsProps } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ObjectEntries } from "../shared/utils";

import { PartialRecord } from "../types/utils.types";
import BaseTable from "../components/Atoms/Table";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { BlockTemplate } from "../components/Blocks/BlockTemplate";
import { useAppDispatch } from "../redux/store.redux";
import {
  getAllBalancesThunk,
  getAllWalletsThunk,
} from "../redux/wallets/wallets.thunks";
import { useWalletsSelector } from "../redux/selectors.redux";
import { Wallet } from "../types/wallets.types";
import { BalanceAccount } from "../types";

export const WalletsPage = () => {
  return (
    <FlexBox>
      <WalletsBlock />
    </FlexBox>
  );
};

type WalletsLoaderName = "wallets" | "accountsMap";
type WalletsLoadersReturn = ReturnType<
  typeof useLoaders<
    WalletsLoaderName,
    {
      wallets: Wallet.Entity[];
      accountsMap: PartialRecord<string, BalanceAccount.Entity[]>;
    }
  >
>;
export const WalletsBlock = () => {
  const { hash, query, push } = useAppRouter<AppQuery, keyof typeof tabs>();

  const loaders = useLoaders<
    WalletsLoaderName,
    {
      wallets: Wallet.Entity[];
      accountsMap: PartialRecord<string, BalanceAccount.Entity[]>;
    }
  >({ wallets: { content: "Loading wallets" } });

  const tabs = {
    Wallets_list: <WalletsListTab loaders={loaders} />,
    Wallet_accounts: <WalletAccountsListTab loaders={loaders} />,
    Wallet_details: (
      <BlockTemplate title={"Wallet"}>
        <FlexBox $padding={"24px"}>{"Wallet details"}</FlexBox>
      </BlockTemplate>
    ),
  };
  const items: TabsProps["items"] = ObjectEntries(tabs).map(([key, item]) => {
    return {
      key: key || "",
      label: key?.replaceAll("_", " ").toUpperCase(),
      children: item,
    };
  });

  return (
    <Tabs
      items={items}
      activeKey={hash}
      onChange={(v) => {
        if (v === "Wallets_list") {
          push({ query: {}, hash: v });
        } else {
          push({ query, hash: v });
        }
      }}
    />
  );
};

const WalletsListTab = ({ loaders }: { loaders: WalletsLoadersReturn }) => {
  const dispatch = useAppDispatch();
  const wallets = useWalletsSelector().wallet.list;

  const loadData = useCallback(() => {
    dispatch(
      getAllWalletsThunk({
        onLoading: loaders.onLoading("wallets"),
      }),
    );
  }, [dispatch, loaders]);

  useEffect(() => {
    if (!wallets) {
      loadData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BlockTemplate title={"Wallets"}>
      <BaseTable
        data={wallets}
        columns={[
          {
            title: "Type",
            width: "100px",
            getData: (data) => {
              return data?.type;
            },
          },
          {
            title: "Status",
            width: "100px",
            getData: (data) => {
              return data?.status;
            },
          },
          {
            title: "Access status",
            width: "150px",
            getData: (data) => {
              return data?.accessStatus;
            },
          },
          {
            title: "Token",
            width: "150px",
            getData: (data) => {
              return data.token?.label + ` [ ${data?.token?.symbol} ]`;
            },
          },
          {
            title: "Connected accounts",
            width: "150px",
            getData: (data) => {
              return data?.accounts?.length;
            },
          },
          {
            title: "ID",
            width: "200px",
            getData: (data) => {
              return data?._id;
            },
          },
        ]}
      />
    </BlockTemplate>
  );
};

const WalletAccountsListTab = ({
  loaders: { ...loaders },
  walletIds,
}: {
  loaders: WalletsLoadersReturn;
  walletIds?: string[];
}) => {
  const { push, query } = useAppRouter();
  const dispatch = useAppDispatch();
  const {
    wallet: { list: wallets },
    account: { idsMap: accountsIdsMap, dataMap: accountsDataMap },
  } = useWalletsSelector();

  const [currentIndex, setCurrentIndex] = useState(() => {
    const ind = wallets?.findIndex((el) => el._id === query.walletId);

    return ind !== undefined && ind >= 0 ? ind : 0;
  });

  const currentWallet = useMemo(() => {
    const ind = wallets?.findIndex((el) => el._id === query.walletId);

    const index = ind !== undefined && ind >= 0 ? ind : 0;
    return wallets?.[index];
  }, [query.walletId, wallets]);
  const registerOnChange = (direction: "prev" | "next") => {
    const incr: Record<"prev" | "next", 1 | -1> = {
      prev: -1,
      next: 1,
    };

    return () => {
      if (!wallets) {
        return;
      }
      const newIndx =
        currentIndex + incr[direction] < 0
          ? currentIndex
          : currentIndex + incr[direction];

      if (newIndx !== currentIndex) {
        const id = wallets?.[newIndx]?._id;
        if (id) {
          setCurrentIndex(newIndx);

          push({
            query: {
              walletId: id,
            },
          });

          loadData(id);
        }
      }
    };
  };

  const loadData = useCallback(
    (id: string) => {
      dispatch(
        getAllBalancesThunk({
          params: {
            walletsIds: [id],
          },
          onLoading: loaders.onLoading("wallets"),
        }),
      );
    },
    [dispatch, loaders],
  );

  const tableData = useMemo(() => {
    return currentWallet
      ? accountsIdsMap?.[currentWallet?._id].map((accId) => {
          return accountsDataMap?.[accId];
        })
      : undefined;
  }, [accountsDataMap, accountsIdsMap, currentWallet]);

  useEffect(() => {
    if (currentWallet && !tableData?.length) {
      loadData(currentWallet._id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BlockTemplate title={"Wallet accounts"}>
      <FlexBox
        $padding={"16px"}
        $fxDirection={"row"}
        $gap={12}
        $alignItems={"center"}
        $fillWidth
      >
        <Button onClick={registerOnChange("prev")} disabled={currentIndex <= 0}>
          <ArrowLeftOutlined />
        </Button>
        <Button
          onClick={registerOnChange("next")}
          disabled={currentIndex + 1 >= (wallets?.length ?? 0)}
        >
          <ArrowRightOutlined />
        </Button>

        <FlexBox
          margin={"0 0 0 auto"}
          $padding={"0 16px"}
          $fxDirection={"row"}
          $gap={12}
        >
          {currentWallet?._id}
        </FlexBox>
      </FlexBox>

      <BaseTable
        data={tableData}
        columns={[
          {
            title: "Type",
            width: "150px",
            getData: (data) => {
              return data?.type;
            },
          },
          {
            title: "Access status",
            width: "150px",
            getData: (data) => {
              return data?.accessStatus;
            },
          },
          {
            title: "Token",
            width: "150px",
            getData: (data) => {
              return data?.token?.symbol;
            },
          },
          {
            title: "ID",
            width: "200px",
            getData: (data) => {
              return data?._id;
            },
          },
          // {
          //   title: "Connected card",
          //   width: "200px",
          //   getData: (data) => {
          //     return data?.accounts?.length;
          //   },
          // },
        ]}
      />
    </BlockTemplate>
  );
};

import { UA } from "./ua";
import { isObject } from "lodash";

export type LangTextKey = Exclude<keyof typeof UA | string, number>;
export type LangKey = "ua" | "en" | "de" | "pl";
export interface LangPack extends Record<LangKey, string> {}
export interface LangDir extends Record<LangTextKey, string> {}

const langDirectory: Record<LangKey, LangDir> = {
  ua: UA,
  en: UA,
  de: UA,
  pl: UA,
};

export const t = (key: LangTextKey | string) => {
  return key in langDirectory.ua ? langDirectory.ua[key as LangTextKey] : key;
};

export function getTranslatedString(
  langPack: LangDir | string,
  langKey: LangKey,
) {
  try {
    if (typeof langPack === "string") {
      return JSON.parse(langPack)[langKey];
    } else if (isObject(langPack)) {
      return langPack[langKey];
    } else {
      return langPack;
    }
  } catch (e) {
    console.error("getTranslatedString error", e);
    console.log({ langPack, langKey });
    return "Translate error";
  }
}

import { useState } from "react";
import { Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import SideBar from "./SideBar";
import FlexBox from "../Atoms/Flex";

export const MobileMenuDrawer = () => {
  // const [open, setOpen] = useState(false);
  // const [childrenDrawer, setChildrenDrawer] = useState(false);

  const [drawers, setDrawers] = useState<Record<number, boolean>>({});
  const showDrawer = (level: number) => {
    setDrawers((prev) => {
      return { ...prev, [level]: !prev?.[level] };
    });
  };
  const registerDrawer = (level: number) => {
    return () => showDrawer(level);
  };

  return (
    <>
      <Button type={"text"} onClick={registerDrawer(1)}>
        <MenuOutlined width={56} height={56} />
      </Button>

      <Drawer
        // title="Multi-level drawer"
        closable={false}
        height={"100%"}
        width={"250px"}
        styles={{
          body: { padding: "0", height: "100%" },
          mask: { maxWidth: "100vw" },
        }}
        onClose={registerDrawer(1)}
        open={drawers?.[1]}
        placement={"left"}
      >
        <FlexBox height={"100%"} $justifyContent={"space-between"}>
          <SideBar />

          <FlexBox $padding={"0 16px 16px"}>
            <Button type="default" onClick={registerDrawer(1)}>
              Close
            </Button>
          </FlexBox>
        </FlexBox>
      </Drawer>
    </>
  );
};

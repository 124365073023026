import { createSlice } from "@reduxjs/toolkit";
import {
  MlmRuleEntity,
  MlmRulesGroupEntity,
  MlmRuleTypeEnum,
} from "../../types";
import { Action } from "../store.redux";
import { ObjectEntries, ObjectKeys, ObjectValues } from "../../shared/utils";
import * as thunks from "./mlm-rules.thunks";

export interface MlmRulesState {
  lists: Record<MlmRuleTypeEnum, MlmRuleEntity[]>;
  list: MlmRuleEntity[];

  groups: MlmRulesGroupEntity[];
  customKeysMap: Record<string, any>;
  customKeysSet: string[];
}

const state: MlmRulesState = {
  lists: Object.assign(
    {},
    ...ObjectValues(MlmRuleTypeEnum).map((val) => ({ [val]: [] })),
  ),
  list: [],
  groups: [],
  customKeysMap: {},
  customKeysSet: [],
};
export const mlmRulesSlice = createSlice({
  name: "mlm_rules",
  initialState: state,
  reducers: {
    setCustomKeysSetAction: (
      s,
      {
        payload: { update, data = [], type },
      }: Action<{
        data: MlmRuleEntity[];
        type?: MlmRuleTypeEnum;
      }>,
    ) => {},
    setMlmRulesListAction: (
      s,
      {
        payload: { update, data = [], type },
      }: Action<{
        data: MlmRuleEntity[];
        type?: MlmRuleTypeEnum;
      }>,
    ) => {
      if (type) {
        s.lists[type] = !update ? data : s.lists?.[type]?.concat(data);
      } else {
        s.list = !update ? data : s.list?.concat(data);
      }
    },
    resetMlmRulesStateAction(
      s,
      {
        payload: { type },
      }: Action<{
        type?: MlmRuleTypeEnum;
      }>,
    ) {
      if (type) {
        s.lists[type] = [];
        return s;
      } else {
        for (const key of ObjectKeys(s.lists)) {
          s.lists[key] = [];
        }
        s.list = [];
        s.groups = [];
        return s;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(thunks.getAllMlmRuleGroupsThunk.fulfilled, (s, a) => {
        s.groups = a.payload.update
          ? a.payload.data.concat(s.groups)
          : a.payload.data;
      })
      .addCase(thunks.createMlmRulesGroupThunk.fulfilled, (s, a) => {
        s.groups.unshift(a.payload.data);
      })
      .addCase(thunks.removeMlmRulesGroupThunk.fulfilled, (st, a) => {
        st.groups = st.groups.map((el) => {
          if (el._id !== a.payload.params?.id) {
            return el;
          } else {
            el.deletedAt = new Date().toISOString();
            return el;
          }
        });
      })
      .addCase(thunks.restoreMlmRulesGroupThunk.fulfilled, (st, a) => {
        st.groups = st.groups.map((el) => {
          if (el._id !== a.payload.params?.id) {
            return el;
          } else {
            el.deletedAt = undefined;
            return el;
          }
        });
      })
      .addCase(thunks.updateMlmRuleThunk.fulfilled, (st, a) => {
        if (a.payload.data?.type) {
          st.lists[a.payload.data?.type] = st.lists[a.payload.data?.type].map(
            (item) => {
              return item._id === a.payload.data._id ? a.payload.data : item;
            },
          );
        }
        return st;
      })
      .addCase(thunks.removeMlmRuleThunk.fulfilled, (st, a) => {
        ObjectEntries(st.lists).forEach(([type, arr]) => {
          st.lists[type] = arr.map((el) => {
            if (el._id !== a.payload.params?.id) {
              return el;
            } else {
              el.deletedAt = new Date().toISOString();
              return el;
            }
          });
        });
      })
      .addCase(thunks.restoreMlmRuleThunk.fulfilled, (st, a) => {
        ObjectEntries(st.lists).forEach(([type, arr]) => {
          st.lists[type] = arr.map((el) => {
            if (el._id !== a.payload.params?.id) {
              return el;
            } else {
              el.deletedAt = undefined;
              return el;
            }
          });
        });
      })

      .addCase(
        thunks.getMlmRulesListThunk.fulfilled,
        (st, { payload: { update, data = [], params } }) => {
          if (params?.type) {
            st.lists[params?.type] = update ? data?.concat(data) : data;

            data.forEach(({ customKey }) => {
              if (customKey) {
                st.customKeysMap[customKey] =
                  st.customKeysMap?.[customKey] ?? undefined;
                st.customKeysSet = Array.from(
                  new Set(st.customKeysSet).add(customKey),
                );
              }
            });

            return st;
          }

          if (params?.types?.length) {
            if (!update) {
              params?.types.forEach((type) => {
                st.lists[type] = [];
              });
            }

            data.forEach((rule) => {
              if (rule.type) {
                st.lists[rule.type] = st.lists?.[rule.type]?.concat([rule]);
              } else {
                st.list = st.list?.concat([rule]);
              }

              if (rule?.customKey) {
                st.customKeysMap[rule.customKey] =
                  st.customKeysMap?.[rule.customKey] ?? undefined;
                st.customKeysSet = Array.from(
                  new Set(st.customKeysSet).add(rule?.customKey),
                );
              }
            });
          } else {
            st.list = update ? data?.concat(st.list) : data;

            data.forEach(({ customKey }) => {
              if (customKey) {
                st.customKeysMap[customKey] =
                  st.customKeysMap?.[customKey] ?? undefined;
                st.customKeysSet = Array.from(
                  new Set(st.customKeysSet).add(customKey),
                );
              }
            });
          }
        },
      ),
});

export const { setMlmRulesListAction, resetMlmRulesStateAction } =
  mlmRulesSlice.actions;

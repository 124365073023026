import { createAppAsyncThunk } from "../../shared/utils";
import { TrackEventsApi } from "../../api";

export enum RevenueEventsThunkType {
  track = "events/trackThunk",
  getAll = "events/getAllThunk",
}

export const getRevenueEventsListThunk = createAppAsyncThunk(
  RevenueEventsThunkType.getAll,
  TrackEventsApi.getRevenuesList,
);
export const trackRevenueEventThunk = createAppAsyncThunk(
  RevenueEventsThunkType.track,
  TrackEventsApi.trackRevenue,
);

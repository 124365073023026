import React, { useMemo, useState } from "react";
import { Tabs, TabsProps } from "antd";
import { ObjectKeys } from "../../shared/utils";
import CreateInvoiceBlock from "../Blocks/CreateInvoiceBlock";
import InvoicesListBlock from "../Blocks/InvoicesListBlock";

const tabsMap = {
  List: <InvoicesListBlock />,
  Payment_request: <CreateInvoiceBlock />,
};
const InvoicesTabs = () => {
  const [currentTab, setCurrentTab] = useState<keyof typeof tabsMap | string>();

  const tabs: TabsProps["items"] = useMemo(() => {
    return ObjectKeys(tabsMap).map((key, index) => {
      return {
        key: key || "",
        label: key?.replaceAll("_", " ").toUpperCase(),
        children: tabsMap?.[key] ?? null,
      };
    });
  }, []);

  return (
    <Tabs
      defaultActiveKey="1"
      items={tabs}
      activeKey={currentTab}
      onChange={(v) => {
        setCurrentTab(v);
      }}
    />
  );
};

export default InvoicesTabs;

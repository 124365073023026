import FlexBox from "../../components/Atoms/Flex";
import { MlmRuleGroupsBlock } from "../../components/Blocks/Admin/MlmRuleGroupsBlock";
import { Tabs, TabsProps } from "antd";
import { ObjectEntries } from "../../shared/utils";
import { CreateMlmRulesGroupBlock } from "../../components/Blocks/Admin/CreateMlmRulesGroupBlock";
import { useAppRouter } from "../../hooks";

export interface MlmRulesGroupPageProps {}

const tabs = {
  Groups: <MlmRuleGroupsBlock />,
  Create: <CreateMlmRulesGroupBlock />,
};
const items: TabsProps["items"] = ObjectEntries(tabs).map(([key, item]) => {
  return {
    key,
    label: key,
    children: item,
  };
});
const MlmRuleGroupsPage = () => {
  const { setHash, hash } = useAppRouter();
  return (
    <FlexBox $fillWidth>
      <Tabs items={items} activeKey={hash} onChange={setHash} />
    </FlexBox>
  );
};

export default MlmRuleGroupsPage;

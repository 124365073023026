import {
  CreateMlmRuleDto,
  MlmRuleDtoKeyEnum,
  MlmRuleValueTypeEnum,
} from "../../../types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BlockTemplate } from "../BlockTemplate";
import FlexBox, { FlexForm } from "../../Atoms/Flex";
import { Text } from "../../Atoms/Text";
import { Button, Input, InputNumber, Select, Switch } from "antd";
import { CreateMlmRuleBlockProps } from "../../../AppPages/admin/MlmRulesPage";
import { Label } from "../../FormElements/Label";
import { SelectOptions } from "../../../hardData/select-options.hard-data";

const CreateMlmReferralRuleBlock = ({
  set,
  onSetItem,
  type,
}: CreateMlmRuleBlockProps) => {
  const [canReset, setCanReset] = useState(true);
  const form = useForm<CreateMlmRuleDto>({
    defaultValues: { value: 0 },
  });
  const fv = form.watch();

  const onValid = (fData: CreateMlmRuleDto) => {
    onSetItem && onSetItem(fData, type);
    canReset && form.reset();
  };

  return (
    <BlockTemplate title={"Create mlm level rule"}>
      <FlexForm
        $fillWidth
        margin={"auto"}
        $maxWidth={"360px"}
        onSubmit={form.handleSubmit(onValid)}
        $gap={16}
        $alignItems={"center"}
      >
        <FlexBox $maxWidth={"360px"} $fillWidth $gap={8}>
          <Label label={"Rule depth"}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Select rule depth"}
              options={SelectOptions.Depth}
              allowClear
              value={fv?.[MlmRuleDtoKeyEnum.depth]}
              onChange={(value) => {
                form.setValue(MlmRuleDtoKeyEnum.depth, value, {
                  shouldTouch: true,
                });
              }}
            />
          </Label>

          <Label label={"Set volume params"}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Select volume type"}
              options={SelectOptions.VolumeTypes_Level}
              allowClear
              value={fv?.[MlmRuleDtoKeyEnum.thresholdType]}
              onChange={(value) => {
                form.setValue(MlmRuleDtoKeyEnum.thresholdType, value, {
                  shouldTouch: true,
                });
              }}
            />
          </Label>

          {fv[MlmRuleDtoKeyEnum.thresholdType] && (
            <Label label={"Volume"}>
              <InputNumber
                {...form.register(MlmRuleDtoKeyEnum.threshold)}
                style={{ width: "100%" }}
                placeholder={"Enter volume amount"}
                defaultValue="50"
                step="50"
                min="0"
                stringMode
                onChange={(value) => {
                  value &&
                    form.setValue(MlmRuleDtoKeyEnum.threshold, value, {
                      shouldTouch: true,
                    });
                }}
              />
            </Label>
          )}

          <Label label={"Balance account status"}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Select balance account status"}
              options={SelectOptions.AccountStatus}
              allowClear
              value={fv?.[MlmRuleDtoKeyEnum.walletStatus]}
              onChange={(value) => {
                form.setValue(MlmRuleDtoKeyEnum.walletStatus, value, {
                  shouldTouch: true,
                });
              }}
            />
          </Label>

          <Label label={"User status"}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Select user status"}
              options={SelectOptions.UserStatus}
              allowClear
              value={fv?.[MlmRuleDtoKeyEnum.userStatus]}
              onChange={(value) => {
                form.setValue(MlmRuleDtoKeyEnum.userStatus, value, {
                  shouldTouch: true,
                });
              }}
            />
          </Label>

          <Label label={"Custom slot category"}>
            <Input
              {...form.register("customKey", {
                setValueAs: (v) => v.replace(/\s/g, "_"),
              })}
              style={{ width: "100%" }}
              placeholder={"Enter custom slot category (3-32)"}
              allowClear
              maxLength={32}
              value={fv?.[MlmRuleDtoKeyEnum.customKey]}
              onChange={(ev) => {
                const { value } = ev.target;
                form.setValue("customKey", value as never);
              }}
            />
          </Label>
          <Label label={"Amount or percentage"}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Select value type"}
              options={SelectOptions.ValueTypes}
              allowClear
              value={fv?.[MlmRuleDtoKeyEnum.valueType]}
              onChange={(value) => {
                form.setValue(MlmRuleDtoKeyEnum.valueType, value, {
                  shouldTouch: true,
                });
              }}
            />
          </Label>

          {fv.valueType && (
            <Label label={"Value"}>
              <InputNumber
                style={{ width: "100%" }}
                placeholder={"Enter value"}
                {...form.register("value", { valueAsNumber: true })}
                step={
                  fv?.[MlmRuleDtoKeyEnum.valueType] ===
                  MlmRuleValueTypeEnum.percentage
                    ? "0.00001"
                    : undefined
                }
                min="0"
                max={
                  fv?.[MlmRuleDtoKeyEnum.valueType] ===
                  MlmRuleValueTypeEnum.percentage
                    ? "100"
                    : undefined
                }
                value={fv?.[MlmRuleDtoKeyEnum.value]}
                required
                status={form.formState.errors.queryString ? "error" : ""}
                onChange={(value) => {
                  value &&
                    form.setValue(MlmRuleDtoKeyEnum.value, value, {
                      shouldTouch: true,
                    });
                }}
                stringMode={
                  fv?.[MlmRuleDtoKeyEnum.valueType] !==
                  MlmRuleValueTypeEnum.percentage
                }
              />
            </Label>
          )}
        </FlexBox>

        <FlexBox $fillWidth $gap={16} $maxWidth={"360px"}>
          <FlexBox
            $fxDirection={"row"}
            $alignItems={"center"}
            $gap={12}
            $padding={"8px 0"}
            $fillWidth
            $justifyContent={"flex-start"}
          >
            <Switch
              checked={canReset}
              onChange={(checked) => {
                setCanReset(checked);
              }}
            />
            <Text>{"Reset after save"}</Text>
          </FlexBox>

          <Button type={"primary"} htmlType={"submit"}>
            {"Add one"}
          </Button>
        </FlexBox>
      </FlexForm>
    </BlockTemplate>
  );
};

export default CreateMlmReferralRuleBlock;

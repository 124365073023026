import APP_CONFIGS from "../configs/APP_CONFIGS";
import ClientApi from "./Client.api";
import {
  RefmeUserRoleEnum,
  UpdateUserDto,
  UserAccessStatus,
  UserEntity,
  UserSearchPath,
  UserSortPath,
  UserStatusEnum,
} from "../types";
import {
  AppResponse,
  HasAccessStatus,
  HasEmail,
  HasLabelEntity,
  HasNameEntity,
  HasPhone,
  HasRole,
  HasSortParams,
  HasStatus,
  PaginationDto,
  SearchParamsDto,
} from "../types/utils.types";

export interface GetUsersListApiQuery
  extends HasRole<RefmeUserRoleEnum>,
    HasAccessStatus<UserAccessStatus>,
    HasStatus<UserStatusEnum>,
    PaginationDto,
    HasNameEntity,
    HasLabelEntity,
    HasEmail,
    HasPhone,
    HasSortParams<UserSortPath>,
    SearchParamsDto<UserSearchPath> {}
export interface GetUserQpiQuery {
  id?: string;
}

export default class UsersApi {
  private static api = ClientApi.getClient();
  private static endpoints = APP_CONFIGS.endpoints.users;
  private static limit = 10;

  public static getAll = (
    _?: undefined,
    params?: GetUsersListApiQuery,
  ): Promise<AppResponse<UserEntity[]>> => {
    return this.api.get(this.endpoints.getAll(), {
      params: {
        limit: this.limit,
        ...params,
      },
    });
  };

  public static getUser = (
    _?: undefined,
    params?: GetUserQpiQuery,
  ): Promise<AppResponse<UserEntity>> => {
    return this.api.get(this.endpoints.getById(), { params });
  };

  public static geReferralsByUserId = (
    data?: { id: string },
    params?: GetUsersListApiQuery,
  ): Promise<AppResponse<UserEntity[]>> => {
    return this.api.get(this.endpoints.getReferrals(data?.id), {
      params: {
        limit: this.limit,
        ...params,
      },
    });
  };

  public static updateProfile = (data?: UpdateUserDto) => {
    return this.api.patch(this.endpoints.update(), data);
  };
}

import React from "react";
import FormInput from "../../FormElements/FormInput";
import { editUserInfoInfo } from "../../data/formsData";
import { FlexForm } from "../../Atoms/Flex";
import { BlockTemplate } from "../BlockTemplate";
import { Button } from "antd";
import { useAppForm } from "../../../hooks";

const EditUserInfoBlock = () => {
  const {
    registerWithValue,
    handleSubmit,
    // watch,
    // formState: { errors },
  } = useAppForm();
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <BlockTemplate title={"Edit personal info"}>
      <FlexForm onSubmit={handleSubmit(onSubmit)} $gap={16}>
        {editUserInfoInfo.map((item) => (
          <FormInput
            key={item.name}
            {...item}
            {...registerWithValue(item.name)}
          />
        ))}

        <Button type={"primary"} size={"middle"} htmlType={"submit"}>
          {"Save"}
        </Button>
      </FlexForm>
    </BlockTemplate>
  );
};

export default EditUserInfoBlock;

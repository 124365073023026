import React from "react";
import UsersListBlock from "../components/Blocks/UsersListBlock";
import FlexBox from "../components/Atoms/Flex";
import { usePreloadUsers } from "../hooks";
import { useAuthSelector } from "../redux/selectors.redux";

const UsersPage = () => {
  const auth = useAuthSelector();
  usePreloadUsers({
    roleIs: auth.user?.role ? { [auth.user?.role]: true } : undefined,
    isLogged: auth.isLogged,
  });

  return (
    <FlexBox>
      <UsersListBlock />
    </FlexBox>
  );
};

export default UsersPage;

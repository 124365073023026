import React, { useMemo } from "react";

import GenerateApiKeys from "../components/Blocks/GenerateApiKeys";
import TokensListBlock from "../components/Blocks/TokensListBlock";
import FlexBox from "../components/Atoms/Flex";
import EditPasswordBlock from "../components/Blocks/Profile/EditPasswordBlock";
import EditUserInfoBlock from "../components/Blocks/Profile/EditUserInfoBlock";
import { Tabs, TabsProps } from "antd";
import { ObjectKeys } from "../shared/utils";
import { AppQuery, useAppRouter } from "../hooks";
import { Values } from "../types/utils.types";

const enum TabNameEnum {
  Tokens = "Tokens",
  Api_Keys = "Api_Keys",
  Edit_Password = "Edit_Password",
  Edit_User_Info = "Edit_User_Info",
}

const ProfilePage = () => {
  const { setHash, hash } = useAppRouter<
    AppQuery,
    Values<typeof TabNameEnum> | string
  >();

  const tabs: TabsProps["items"] = useMemo(() => {
    const tabsMap = {
      Tokens: <TokensListBlock />,
      Api_Keys: <GenerateApiKeys />,
      Edit_Password: <EditPasswordBlock />,
      Edit_User_Info: <EditUserInfoBlock />,
    };
    return ObjectKeys(tabsMap).map((key, index) => {
      return {
        key: key || "",
        label: key?.replaceAll("_", " ").toUpperCase(),
        children: tabsMap?.[key] ?? null,
      };
    });
  }, []);

  return (
    <FlexBox $gap={24}>
      <Tabs
        // defaultActiveKey="1"
        items={tabs}
        activeKey={hash}
        onChange={(v) => {
          setHash(v);
        }}
      />
    </FlexBox>
  );
};

export default ProfilePage;

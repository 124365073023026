import ClientApi from "../Client.api";
import { IBase } from "../../types/utils.types";
import { UserEntity } from "../../types";
import { ApiAsyncResponse } from "../types.api";
import { AppAuth } from "../../types/auth/app-auth.namespace";

export namespace Device {
  export interface Entity extends IBase {
    user?: UserEntity;
    session?: AppAuth.Session.Entity;
    trackId?: string;
  }

  export interface AttachDto {}
  export interface RegisterDto {
    timeZone?: string;
    locale?: string;
    os?: string;
    aspectRatio?: string;
  }
  export class Api {
    private static _client = ClientApi.getClient();
    private static _ends = ClientApi.endpoints.auth.devices;

    public static attach = (
      data?: AttachDto,
    ): ApiAsyncResponse<Device.Entity> => {
      return this._client.post(this._ends.attach(), data);
    };
  }
}

import { MlmRulesApi } from "../../api";
import { createAppAsyncThunk } from "../../shared/utils";

enum MlmRulesThunk {
  groupsCreate = "mlm-rules/groups/create",
  groupsGetAll = "mlm-rules/groups/getAll",
  updateGroup = "mlm-rules/groups/update",
  removeGroup = "mlm-rules/groups/remove",
  restoreGroup = "mlm-rules/groups/restore",

  getAll = "mlm-rules/getAll",
  create = "mlm-rules/create",
  update = "mlm-rules/update",
  remove = "mlm-rules/remove",
  restore = "mlm-rules/restore",
}

export const getAllMlmRuleGroupsThunk = createAppAsyncThunk(
  MlmRulesThunk.groupsGetAll,
  MlmRulesApi.groups.getAll,
);

export const createMlmRulesGroupThunk = createAppAsyncThunk(
  MlmRulesThunk.groupsCreate,
  MlmRulesApi.groups.create,
);
export const removeMlmRulesGroupThunk = createAppAsyncThunk(
  MlmRulesThunk.removeGroup,
  MlmRulesApi.groups.remove,
);
export const restoreMlmRulesGroupThunk = createAppAsyncThunk(
  MlmRulesThunk.restoreGroup,
  MlmRulesApi.groups.restore,
);

export const getMlmRulesListThunk = createAppAsyncThunk(
  MlmRulesThunk.getAll,
  MlmRulesApi.getRulesList,
);
export const createMlmRuleThunk = createAppAsyncThunk(
  MlmRulesThunk.create,
  MlmRulesApi.createRule,
);
export const updateMlmRuleThunk = createAppAsyncThunk(
  MlmRulesThunk.update,
  MlmRulesApi.updateRule,
);
export const removeMlmRuleThunk = createAppAsyncThunk(
  MlmRulesThunk.remove,
  MlmRulesApi.removeRule,
);
export const restoreMlmRuleThunk = createAppAsyncThunk(
  MlmRulesThunk.restore,
  MlmRulesApi.restoreRule,
);

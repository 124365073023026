import { HasRole } from "../../types/utils.types";

export type RoleIsInput<Role extends string = string> = HasRole<Role>;

export type RoleIsReturn<Role extends string = string> =
  | {
      [key in Role]?: boolean;
    }
  | undefined;
export function getRoles<Role extends string = string>(
  user?: RoleIsInput<Role>,
): RoleIsReturn<Role> {
  return user?.role
    ? ({
        [user?.role as string]: true,
      } as {
        [key in Role]?: boolean;
      })
    : undefined;
}

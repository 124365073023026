import * as icons from "@ant-design/icons";
import { User } from "../../types";

export type AppRoteObj = {
  label: string;
  path: string;
  icon: any;
  roles?: User.Entity["role"][];
  children?: AppRoteObj[];
};
export const appLinks: AppRoteObj[] = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: icons.UserOutlined,
  },
  {
    label: "Transactions",
    path: "/transactions",
    icon: icons.BankOutlined,
  },
  { label: "Events", path: "/events", icon: icons.BlockOutlined },
  { label: "Invoices", path: "/invoices", icon: icons.UnorderedListOutlined },
  { label: "Users", path: "/users", icon: icons.UsergroupAddOutlined },
  // {
  //   label: "Users",
  //   path: "/users",
  //   icon: icons.UsergroupAddOutlined,
  //   children: [
  //     {
  //       label: "Wallets",
  //       path: "/users/wallets",
  //       icon: icons.WalletOutlined,
  //     },
  //   ],
  // },
  { label: "Wallets", path: "/wallets", icon: icons.WalletOutlined },
  { label: "Profile", path: "/profile", icon: icons.UserOutlined },
  {
    label: "OAuth",
    path: "/o-auth",
    roles: [User.RoleEnum.admin, User.RoleEnum.vendor],
    icon: icons.SecurityScanOutlined,
    children: [
      {
        label: "Connections list",
        path: "/o-auth/connections",
        roles: [User.RoleEnum.admin, User.RoleEnum.vendor],
        icon: icons.UnorderedListOutlined,
      },
      {
        label: "Create connection",
        path: "/o-auth/connections/create",
        roles: [User.RoleEnum.admin, User.RoleEnum.vendor],
        icon: icons.UnorderedListOutlined,
      },
    ],
  },
  {
    label: "Admin",
    path: "/admin",
    roles: [User.RoleEnum.admin],
    icon: icons.SecurityScanOutlined,
    children: [
      {
        label: "Mlm rules",
        path: "/admin/mlm-rules/groups",
        roles: [User.RoleEnum.admin],
        icon: icons.UnorderedListOutlined,
      },
    ],
  },
];

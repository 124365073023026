import { createAppAsyncThunk } from "../../shared/utils";
import { TransactionsApi } from "../../api";

enum TransactionThunkEnum {
  getAll = "transactions/getAllThunk",
  p_to_p_transfer = "transactions/p_to_p_transferThunk",
}

export const getAllTransactions = createAppAsyncThunk(
  TransactionThunkEnum.getAll,
  TransactionsApi.getList,
);
export const transferPerToPer = createAppAsyncThunk(
  TransactionThunkEnum.p_to_p_transfer,
  TransactionsApi.perToPerTransfer,
);

import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../store.redux";
import { RevenueEventEntity } from "../../types/app-events.types";
import * as thunk from "./revenue-events.thunks";

export interface EventsState {
  revenues: RevenueEventEntity[];
}

const state: EventsState = {
  revenues: [],
};
export const eventsSlice = createSlice({
  name: "events",
  initialState: state,
  reducers: {
    setRevenuesListAction: (
      s: EventsState,
      { payload: { refresh, data } }: Action<{ data: RevenueEventEntity[] }>,
    ) => {
      s.revenues = refresh ? data : data.concat(s.revenues);
    },
    addRevenueToListAction: (
      s: EventsState,
      { payload: { data } }: Action<{ data: RevenueEventEntity }>,
    ) => {
      s.revenues = [data, ...s.revenues];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        thunk.getRevenueEventsListThunk.fulfilled,
        (st, { payload: { update, data } }) => {
          st.revenues = update ? data.concat(st.revenues) : data;
        },
      )
      .addCase(
        thunk.trackRevenueEventThunk.fulfilled,
        (st, { payload: { data } }) => {
          st.revenues = [data, ...st.revenues];
        },
      ),
});

export const { setRevenuesListAction, addRevenueToListAction } =
  eventsSlice.actions;

import React from "react";
import { CreatedModal } from "../../providers/ModalProvider";
import { useTheme } from "styled-components";
import FlexBox from "./Flex";
import { Text } from "./Text";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const ModalBase = ({
  formId,
  children,
  title,
  onClose,
  onCancelPress,
  onOkPress,
  isLoading,
  hideFooter,
}: {
  formId?: string;
  title?: string;
  onCancelPress?: () => void;
  onOkPress?: () => void;
  children?: React.ReactNode;
  isLoading?: boolean;
  hideFooter?: boolean;
} & CreatedModal) => {
  const theme = useTheme();
  return (
    <FlexBox
      $maxWidth={"100%"}
      $maxHeight={"100%"}
      width={"360px"}
      background={theme.mainBgColor}
      $borderRadius={"8px"}
      overflow={"hidden"}
    >
      <FlexBox
        $padding={"16px 16px 8px"}
        $fxDirection={"row"}
        $justifyContent={"space-between"}
        $alignItems={"center"}
      >
        <Text $weight={600} $size={14}>
          {title}
        </Text>

        <button
          type={"button"}
          style={{ border: 0, background: "unset", padding: 6 }}
          onClick={onClose}
        >
          <CloseOutlined size={18} />
        </button>
      </FlexBox>

      <FlexBox $padding={"8px 16px"} overflow={"auto"} $flex={"1"}>
        {children}
      </FlexBox>

      {hideFooter && (
        <FlexBox
          $padding={"8px 16px 16px"}
          $fxDirection={"row"}
          $justifyContent={"flex-end"}
          $alignItems={"center"}
          $gap={8}
        >
          <Button
            form={formId}
            size={"middle"}
            htmlType={formId ? "reset" : "button"}
            onClick={onCancelPress || onClose}
            loading={isLoading}
          >
            {"Cancel"}
          </Button>
          <Button
            loading={isLoading}
            form={formId}
            size={"middle"}
            htmlType={formId ? "submit" : "button"}
            onClick={onOkPress}
            type={"primary"}
          >
            {"Ok"}
          </Button>
        </FlexBox>
      )}
    </FlexBox>
  );
};
export default ModalBase;

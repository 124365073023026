import React from "react";
import { ContainerSmall } from "../components/Atoms/Container";
import { BaseLink } from "../components/Atoms/Text";

const SuccessRegisterPage = () => {
  return (
    <ContainerSmall>
      <BaseLink href="/logIn">Log In</BaseLink>
    </ContainerSmall>
  );
};

export default SuccessRegisterPage;

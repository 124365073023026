import styled from "styled-components";
import sprite, { SpriteIconId } from "../../shared/sprite";
import { Property } from "csstype";

const getIcon = (icon?: SpriteIconId) => `${sprite}#icon-${icon}`;
export interface SvgIconProps extends React.SVGAttributes<SVGElement> {
  $size?: Property.Width | Property.Height;
  $maxSize?: Property.MaxWidth | Property.MaxHeight;
  $icon?: SpriteIconId;
}

const SvgIcon: React.FC<SvgIconProps> = ({ $icon, ...props }) => {
  return (
    <Svg {...{ ...props }}>
      <use
        style={{ width: "100%", height: "100%" }}
        href={getIcon($icon)}
      ></use>
    </Svg>
  );
};

const Svg = styled.svg<SvgIconProps>`
  position: relative;

  width: ${({ $size }) => $size ?? "24px"};
  min-width: ${({ $size }) => $size ?? "24px"};
  max-width: ${({ $maxSize }) => $maxSize ?? "100%"};
  max-height: ${({ $maxSize }) => $maxSize ?? "100%"};
  //height: ${({ $size }) => $size ?? "24px"};

  object-position: center;

  //max-width: 100%;

  //align-items: center;
  //justify-content: center;

  object-fit: cover;

  aspect-ratio: 1/1;

  pointer-events: none;
`;
// transition: all ${({ theme }) => theme.ti};
export default SvgIcon;

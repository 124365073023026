import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer, { RootReducerType } from "./rootReducer.redux";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV === "development",
});

export type AppDispatch = typeof store.dispatch;

export type ActionPayloadBase<Type = any> = { refresh?: boolean } & Type;

export type ActionPayload<Type = any, Extra = any> = {
  refresh?: boolean;
  update?: boolean;
  upend?: boolean;
  prepend?: boolean;
  extra?: Extra;
} & Type;

export type Action<Payload, T extends string = string> = {
  payload: ActionPayload<Payload>;
  type: T;
};

export interface ThunkPayload<
  SD = any,
  RD = any,
  E = any | unknown,
  MD = any,
  Params = any,
  Extra = any,
  InitData = any,
> {
  initialData?: InitData;
  data?: SD;
  params?: Params;
  extra?: Extra;
  refresh?: boolean;
  update?: boolean;
  onSuccess?: (data: RD, meta?: MD) => void;
  onError?: (error: E) => void;
  onLoading?: (loading: boolean) => void;
}

export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<RootReducerType>;

export const persistor = persistStore(store);

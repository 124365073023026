import styled, { css } from "styled-components";
import { Property } from "csstype";
import { Link } from "react-router-dom";

export interface FlexBaseProps {
  $flex?: Property.Flex;
  $flexWrap?: Property.FlexWrap;
  $fxDirection?: Property.FlexDirection;
  $alignItems?: Property.AlignItems;
  $justifyContent?: Property.JustifyContent;
  alignSelf?: Property.AlignSelf;
  $gap?: number;

  $maxWidth?: Property.MaxWidth;
  $maxHeight?: Property.MaxHeight;

  $minWidth?: Property.MinWidth;
  $minHeight?: Property.MinHeight;

  width?: Property.Width;
  height?: Property.Height;
  $padding?: Property.Padding;
  margin?: Property.Margin;
  $fillWidth?: boolean;
  $fillHeight?: boolean;

  $borderBottom?: Property.BorderBottom;
  $borderRadius?: Property.BorderRadius;
  $border?: Property.Border;
  background?: Property.Background;
  overflow?: Property.Overflow;
}

export interface FlexProps extends FlexBaseProps {
  $xsStyles?: FlexBaseProps;
  $xlStyles?: FlexBaseProps;
}

const XsStyles = css<FlexProps>`
  @media (max-width: 960px) {
    flex-direction: ${({ $xsStyles, $fxDirection = "column" }) =>
      $xsStyles?.$fxDirection || $fxDirection};
    align-items: ${({ $xsStyles, $alignItems = "flex-start" }) =>
      $xsStyles?.$alignItems || $alignItems};
    justify-content: ${({ $xsStyles, $justifyContent = "flex-start" }) =>
      $xsStyles?.$justifyContent || $justifyContent};
    flex: ${({ $xsStyles, $flex = "" }) => $xsStyles?.$flex || $flex};
    flex-wrap: ${({ $xsStyles, $flexWrap = "" }) =>
      $xsStyles?.$flexWrap || $flexWrap};
    border-bottom: ${({ $xsStyles, $borderBottom = "none" }) =>
      $xsStyles?.$borderBottom || $borderBottom};
    border: ${({ $xsStyles, $border = "none" }) =>
      $xsStyles?.$border || $border};
    gap: ${({ $xsStyles, $gap = 0 }) => $xsStyles?.$gap || $gap}px;
    padding: ${({ $xsStyles, $padding = 0 }) =>
      $xsStyles?.$padding || $padding};
    margin: ${({ $xsStyles, margin = 0 }) => $xsStyles?.margin || margin};
    align-self: ${({ $xsStyles, alignSelf = "none" }) =>
      $xsStyles?.alignSelf || alignSelf};
    max-width: ${({ $xsStyles, $maxWidth = null }) =>
      $xsStyles?.$maxWidth || ($maxWidth ? $maxWidth : "none")};
    max-height: ${({ $xsStyles, $maxHeight = null }) =>
      $xsStyles?.$maxWidth || ($maxHeight ? $maxHeight : "none")};
    width: ${({ $xsStyles, width = "auto", $fillWidth }) =>
      ($xsStyles?.$fillWidth ? "100%" : $xsStyles?.width) ||
      ($fillWidth ? "100%" : width)};
    height: ${({ $xsStyles, height = "auto", $fillHeight }) =>
      ($xsStyles?.$fillHeight ? "100%" : $xsStyles?.height) ||
      ($fillHeight ? "100%" : height)};
    background: ${({ $xsStyles, background = "none" }) =>
      $xsStyles?.background || background};
    border-radius: ${({ $xsStyles, $borderRadius = "none" }) =>
      $xsStyles?.$borderRadius || $borderRadius};
    overflow: ${({ $xsStyles, overflow = "" }) =>
      $xsStyles?.overflow || overflow};
  }
`;

const XlStyles = css<FlexProps>`
  @media (min-width: 960px) {
    flex-direction: ${({ $xlStyles, $fxDirection = "column" }) =>
      $xlStyles?.$fxDirection || $fxDirection};
    align-items: ${({ $xlStyles, $alignItems = "flex-start" }) =>
      $xlStyles?.$alignItems || $alignItems};
    justify-content: ${({ $xlStyles, $justifyContent = "flex-start" }) =>
      $xlStyles?.$justifyContent || $justifyContent};
    flex: ${({ $xlStyles, $flex = "" }) => $xlStyles?.$flex || $flex};
    flex-wrap: ${({ $xlStyles, $flexWrap = "" }) =>
      $xlStyles?.$flexWrap || $flexWrap};
    border-bottom: ${({ $xlStyles, $borderBottom = "none" }) =>
      $xlStyles?.$borderBottom || $borderBottom};
    border: ${({ $xlStyles, $border = "none" }) =>
      $xlStyles?.$border || $border};
    gap: ${({ $xlStyles, $gap = 0 }) => $xlStyles?.$gap || $gap}px;
    padding: ${({ $xlStyles, $padding = 0 }) =>
      $xlStyles?.$padding || $padding};
    margin: ${({ $xlStyles, margin = 0 }) => $xlStyles?.margin || margin};
    align-self: ${({ $xlStyles, alignSelf = "none" }) =>
      $xlStyles?.alignSelf || alignSelf};
    max-width: ${({ $xlStyles, $maxWidth = null }) =>
      $xlStyles?.$maxWidth || ($maxWidth ? $maxWidth : "none")};
    max-height: ${({ $xlStyles, $maxHeight = null }) =>
      $xlStyles?.$maxWidth || ($maxHeight ? $maxHeight : "none")};
    width: ${({ $xlStyles, width = "auto", $fillWidth }) =>
      ($xlStyles?.$fillWidth ? "100%" : $xlStyles?.width) ||
      ($fillWidth ? "100%" : width)};
    height: ${({ $xlStyles, height = "auto", $fillHeight }) =>
      ($xlStyles?.$fillHeight ? "100%" : $xlStyles?.height) ||
      ($fillHeight ? "100%" : height)};
    background: ${({ $xlStyles, background = "none" }) =>
      $xlStyles?.background || background};
    border-radius: ${({ $xlStyles, $borderRadius = "none" }) =>
      $xlStyles?.$borderRadius || $borderRadius};
    overflow: ${({ $xlStyles, overflow = "" }) =>
      $xlStyles?.overflow || overflow};
  }
`;
export const FlexBoxBaseCss = css<FlexProps>`
  display: flex;
  flex-direction: ${({ $fxDirection = "column" }) => $fxDirection};
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  align-self: ${({ alignSelf }) => alignSelf};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};
  gap: ${({ $gap = 0 }) => $gap}px;
  flex: ${({ $flex = "" }) => $flex};

  padding: ${({ $padding = 0 }) => $padding};
  margin: ${({ margin }) => margin};

  max-width: ${({ $maxWidth = null }) => ($maxWidth ? $maxWidth : "")};
  max-height: ${({ $maxHeight = null }) => ($maxHeight ? $maxHeight : "")};

  min-width: ${({ $minWidth = null }) => ($minWidth ? $minWidth : "")};
  min-height: ${({ $minHeight = null }) => ($minHeight ? $minHeight : "")};

  width: ${({ width = "auto", $fillWidth }) => ($fillWidth ? "100%" : width)};
  height: ${({ height = "auto", $fillHeight }) =>
    $fillHeight ? "100%" : height};

  background: ${({ background = "none" }) => background};
  border: ${({ $border = "none" }) => $border};
  border-bottom: ${({ $borderBottom = "" }) => $borderBottom};
  border-radius: ${({ $borderRadius = "none" }) => $borderRadius};
  overflow: ${({ overflow }) => overflow};

  ${({ $xsStyles }) => {
    if ($xsStyles) return XsStyles;
  }};
  ${({ $xlStyles }) => {
    if ($xlStyles) return XlStyles;
  }};
`;

export const FlexBox = styled.div<FlexProps>`
  ${FlexBoxBaseCss};
`;

export interface GridBoxProps extends FlexProps {
  $templateColumns?: string[];
  $templateRows?: string[];
  $autoColumns?: string[];
  $autoRows?: string[];
}

export const SimpleGridBox = styled.div<GridBoxProps>`
  ${FlexBoxBaseCss};
  display: grid;
  grid-template-columns: ${(p) => p.$templateColumns?.join(" ")};
  grid-template-rows: ${(p) => p.$templateRows?.join(" ")};

  grid-auto-columns: ${(p) => p.$autoColumns?.join(" ")};
  grid-auto-rows: ${(p) => p.$autoRows?.join(" ")};
`;

export const FlexForm = styled.form<FlexProps>`
  width: 480px;
  max-width: 100%;
  ${FlexBoxBaseCss};
`;

export const FlexFieldSet = styled.fieldset<FlexProps>`
  ${FlexBoxBaseCss};
  border: 0;
  margin: 0;
  padding: 0;
`;

export const FlexLink = styled(Link)<FlexProps>`
  ${FlexBoxBaseCss};
`;

export const FlexLi = styled.li<FlexProps>`
  ${FlexBoxBaseCss};
`;

export const FlexUl = styled.ul<FlexProps>`
  ${FlexBoxBaseCss};
`;
export const FlexLabel = styled.label<FlexProps>`
  ${FlexBoxBaseCss};
`;
export const BaseFlexLink = styled(Link)<FlexProps>`
  ${FlexBoxBaseCss};
  text-decoration: none;
  color: inherit;
  font-style: normal;
  cursor: pointer;
`;

export default FlexBox;

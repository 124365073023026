import { UserEntity } from "../../types";
import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../store.redux";
import { SliceMap } from "../type";
import * as thunks from "./users.thunks";

export interface UsersState extends SliceMap<UserEntity, string, string> {}

const state: UsersState = {
  list: [],
  keysMap: {},
  dataMap: {},
  ids: [],
};
export const usersSlice = createSlice({
  name: "users",
  initialState: state,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(
      thunks.getAllUsersThunk.fulfilled,
      (
        st,
        { payload: { upend, prepend, data } }: Action<{ data: UserEntity[] }>,
      ) => {
        st.list = upend
          ? st.list.concat(data)
          : prepend
            ? data.concat(st.list)
            : data;
      },
    ),
});

// export const { setUsersListAction } = usersSlice.actions;

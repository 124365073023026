import React, { useRef, useState } from "react";
import { InvoiceEntity, RefmeInvoiceStatusEnum } from "../../types";
import { format } from "date-fns";
import { numberToBalance } from "../../shared/utils";
import { BlockTemplate } from "./BlockTemplate";
import { useInvoicesSelector } from "../../redux/selectors.redux";
import createApiCall from "../../api/createApiCall.api";
import InvoicesApi from "../../api/Invoices.api";
import FlexBox from "../Atoms/Flex";
import { Select } from "antd";
import { DefaultOptionType } from "rc-select/es/Select";
import { useAppDispatch } from "../../redux/store.redux";
import { setInvoicesListAction } from "../../redux/invoices/invoices.slice";
import { TableWithActions } from "../Tables/TableWithActions";

export interface InvoicesListBlockProps {
  list?: InvoiceEntity[];
}
const statusesArr = Object.values(RefmeInvoiceStatusEnum);

const InvoicesListBlock = () => {
  const dispatch = useAppDispatch();

  const { list: invoices } = useInvoicesSelector();
  const limitRef = useRef(5);

  const [limit, setLimit] = useState<number>(0);

  const [currentStatus, setCurrentStatus] = useState<
    RefmeInvoiceStatusEnum | undefined
  >(statusesArr[0]);

  const handleSetCurrentStatus = (status: RefmeInvoiceStatusEnum) => {
    return createApiCall(InvoicesApi.getAll, {
      params: {
        statuses: [status],
        limit: limit,
      },
      onSuccess: ({ data }) => {
        dispatch(
          setInvoicesListAction({
            refresh: true,
            data: data.map((el) => ({ ...el, queryString: el._id })),
          }),
        );

        setCurrentStatus(status);
      },
    });
  };

  return (
    <BlockTemplate
      title={"Invoices list"}
      contentContainerStyles={{
        $padding: "0 16px 16px",
        $xsStyles: { $padding: "0 0 16px" },
      }}
    >
      <FlexBox
        $fillWidth
        $justifyContent={"flex-start"}
        $fxDirection={"row"}
        $gap={8}
      >
        <Select<RefmeInvoiceStatusEnum>
          placeholder={"Select status"}
          options={statusesArr.map(
            (tab): DefaultOptionType & { value: RefmeInvoiceStatusEnum } => {
              return {
                label: tab.toUpperCase().replaceAll("_", " "),
                value: tab,
              };
            },
          )}
          value={currentStatus}
          size={"small"}
          style={{ width: 150 }}
          onChange={handleSetCurrentStatus}
        />
      </FlexBox>

      <TableWithActions
        data={invoices}
        onLimitChange={setLimit}
        limit={limit}
        columns={[
          {
            title: "Reference",
            path: "ref",
            width: "90px",
          },
          {
            title: "Amount",
            getData(d) {
              return numberToBalance(d?.amount, d?.token?.decimals);
            },
            width: "225px",
            align: "end",
          },
          {
            title: "Token",
            getData(data) {
              return data?.token?.symbol;
            },
            width: "90px",
          },
          {
            title: "Receiver ref",
            path: "receiverRef",
            width: "120px",
          },

          {
            title: "Email",
            path: "email",
            width: "150px",
          },

          {
            title: "Phone",
            path: "phone",
            width: "120px",
          },

          {
            title: "Status",
            path: "status",
            width: "150px",
          },

          {
            title: "Type",
            path: "type",
            width: "150px",
          },

          {
            title: "Description",
            path: "description",
            width: "200px",
          },

          {
            title: "Created at",
            getData(data) {
              return data.createdAt
                ? format(new Date(data.createdAt), "dd.MM.yyyy hh:mm")
                : "---";
            },
            width: "90px",
          },
          {
            title: "Updated at",
            getData(data) {
              return data.updatedAt
                ? format(new Date(data.updatedAt), "dd.MM.yyyy hh:mm")
                : "---";
            },
            width: "90px",
          },
        ]}
      />
    </BlockTemplate>

    // <BaseBox $gap={24} $alignItems="center">
    //   <Title>{"Invoices list"}</Title>
    //
    //
    //
    //   <Title>{"Create invoice"}</Title>
    //
    //
    // </BaseBox>
  );
};

export default InvoicesListBlock;

type ConsoleType = typeof console;

type LogType = keyof Pick<ConsoleType, "error" | "debug" | "log" | "warn">;

export class ClientLogger {
  private _ctx: string = ClientLogger.name;
  private _prefix?: string = "";
  private _fullPrefix =
    `[${this._ctx}]`.padEnd(12, " ") +
    (this._prefix ? `[${this._prefix}]`.padEnd(10, " ") : "");
  constructor(ctx: string, prefix?: string) {
    this._ctx = ctx;
    this._prefix = prefix;
    this._fullPrefix =
      `[${this._ctx}]`.padEnd(12, " ") +
      (this._prefix ? `[${this._prefix}]`.padEnd(10, " ") : "");
  }

  setPrefix(pr: string) {
    this._prefix = pr;
  }

  get error(): ConsoleType["error"] {
    return (...args) => console.error(`${this._getLogType("error")}`, ...args);
  }

  get warn(): ConsoleType["warn"] {
    return (...args) => console.warn(`${this._getLogType("warn")}`, ...args);
  }

  get debug(): ConsoleType["debug"] {
    return (...args) => console.debug(`${this._getLogType("debug")}`, ...args);
  }

  get log(): ConsoleType["log"] {
    return (...args) => {
      console.log(`${this._getLogType("log")}`, ...args);
    };
  }

  private _getLogType = (type: LogType) =>
    `[${type.toUpperCase()}]`.padEnd(10, " ") + this._fullPrefix;
}

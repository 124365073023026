import { ApiEndpoint } from "./createApiCall.api";
import { RevenueEventEntity, TrackRevenueDto } from "../types/app-events.types";
import ClientApi from "./Client.api";

export interface GetRevenuesListQuery {
  ruleId?: string;
  orderId?: string;
  userId?: string;
  category?: string;
  label?: string;
}

export class TrackEventsApi extends ClientApi {
  private static _endps = this.endpoints.events;
  public static readonly getRevenuesList: ApiEndpoint<
    never,
    GetRevenuesListQuery,
    RevenueEventEntity[]
  > = (_, params) => {
    return this._client.get(this._endps.getRevenuesList(), { params });
  };

  public static readonly trackRevenue: ApiEndpoint<
    TrackRevenueDto,
    never,
    RevenueEventEntity
  > = (data, _) => {
    return this._client.post(this._endps.trackRevenue(), data);
  };
}

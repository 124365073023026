import styled from "styled-components";
import { BaseButton } from "./BaseButton";
import { Button, ConfigProvider } from "antd";
import React from "react";
import { ButtonProps } from "antd/es/button/button";
import * as Icon from "@ant-design/icons";
import SvgIcon from "./SvgIcon";
import { FlexBoxBaseCss, FlexProps } from "./Flex";
import { StorageService } from "../../services/StorageService.serv";
// import { Button } from "antd";

export const AccentBtn = styled(BaseButton)`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.borderRadius};
  box-shadow:
    rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0,
    rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  color: ${(p) => p.theme.colors.black};

  min-height: 44px;
  line-height: 1.15;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  font-weight: 500;
  font-size: 16px;

  width: 100%;
  transition:
    all 0.2s,
    box-shadow 0.08s ease-in;

  &[disabled] {
    opacity: 0.8;
    cursor: default;
  }

  &:focus {
    box-shadow:
      rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
      rgba(50, 50, 93, 0.2) 0 6px 15px 0,
      rgba(0, 0, 0, 0.1) 0 2px 2px 0,
      rgba(50, 151, 211, 0.3) 0 0 0 4px;
  }
`;

export const BorderButton = styled(AccentBtn)`
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.borderRadius};

  background-color: transparent;

  color: ${(p) => p.theme.colors.white};
`;

const Restore = ({ ...props }: ButtonProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            // colorPrimary: `linear-gradient(135deg, ${colors1.join(", ")})`,
            // colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(colors1).join(", ")})`,
            // colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(colors1).join(", ")})`,
            // lineWidth: 0,
            colorPrimary: `${"#facc07"}`,
            colorPrimaryActive: `${"#cea700"}`,
            colorPrimaryHover: `${"#ffd410"}`,
            // background: !props.disabled ?  : undefined,
          },
        },
      }}
    >
      <Button size={"small"} type={"primary"} {...props}>
        {"Restore"}

        {/*<SvgIcon $icon={"refresh"} $size={"18px"} fill={"currentColor"} />*/}

        <Icon.UndoOutlined />
      </Button>
    </ConfigProvider>
  );
};

const OpenInNew = ({ ...props }: ButtonProps) => {
  return (
    <Button
      size={"small"}
      type={"primary"}
      style={{
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        gap: 8,
      }}
      {...props}
      icon={
        <SvgIcon $icon={"openInNew"} $size={"18px"} fill={"currentColor"} />
      }
    >
      {"Open"}
    </Button>
  );
};

const Refresh = ({ ...props }: ButtonProps) => {
  return (
    <Button
      size={"small"}
      type={"default"}
      {...props}
      style={{
        padding: 2,
        ...props.style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      // icon={!props?.loading && <Icon.RedoOutlined />}
    >
      <SvgIcon $icon={"refresh"} $size={"18px"} fill={"currentColor"} />
    </Button>
  );
};

const Base = styled.button<FlexProps>`
  ${FlexBoxBaseCss};

  display: flex;
  align-items: center;
  gap: 8px;

  border: 1px solid transparent;
  background-color: transparent;
  padding: 0;
`;

const AddOne = ({ ...props }: ButtonProps) => {
  return (
    <Button size={"small"} type={"primary"} {...props}>
      {"Add"}

      <Icon.PlusOutlined />
    </Button>
  );
};

const Delete = ({ ...props }: ButtonProps) => {
  return (
    <Button size={"small"} type={"primary"} danger {...props}>
      {"Remove"}

      <Icon.DeleteOutlined />
    </Button>
  );
};
const Edit = ({ ...props }: ButtonProps) => {
  return (
    <Button size={"small"} {...props}>
      {"Edit"}

      <Icon.EditOutlined />
    </Button>
  );
};
const Copy = ({
  children,
  copyValue,
  ...props
}: ButtonProps & { copyValue?: string }) => {
  return (
    <Button
      size={"small"}
      type={"text"}
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        position: "relative",
        display: "flex",
        ...props.style,
      }}
      {...props}
      disabled={!copyValue}
      onClick={(ev) => {
        ev.stopPropagation();

        copyValue && StorageService.copyText(copyValue);
      }}
    >
      {copyValue && <Icon.CopyOutlined />}
      {children ?? "---"}
    </Button>
  );
};

export const AppButton = {
  Restore,
  Base,
  OpenInNew,
  AddOne,
  Delete,
  Edit,
  Refresh,
  Copy,
};

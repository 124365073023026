import TokensApi from "../../api/Tokens.api";
import { createAppAsyncThunk } from "../../shared/utils";

enum TokensThunkType {
  getList = "tokens/getListThunk",
  emitBonus = "tokens/emit/bonus/Thunk",
  emitFiat = "tokens/emit/fiat/Thunk",
}

export const getAllTokensThunk = createAppAsyncThunk(
  TokensThunkType.getList,
  TokensApi.getAll,
);
export const emitBonusTokenThunk = createAppAsyncThunk(
  TokensThunkType.emitBonus,
  TokensApi.emitBonus,
);
export const emitFiatTokenThunk = createAppAsyncThunk(
  TokensThunkType.emitFiat,
  TokensApi.emitFiat,
);

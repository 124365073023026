import ClientApi from "../Client.api";
import { RefmeUserRoleEnum } from "../../types";
import { AppResponse } from "../../types/utils.types";
import { SessionsApi } from "./Sessions.api";
import { Device } from "./Devices.api";
import { OAuthApi } from "./OAuth.api";
import { AppAuth } from "../../types/auth/app-auth.namespace";

export default class AuthApi {
  private static api = ClientApi.getClient();
  private static endpoints = ClientApi.endpoints.auth;

  public static sessions = SessionsApi;
  public static devices = Device.Api;
  public static oAuth = OAuthApi;

  // public static register = (data?: RegisterData) => {
  //   return this.api.post(this.endpoints.register(), data);
  // };

  // public static registerVendor = (data?: RegisterFormData) => {
  //   return this.api.post(this.endpoints.registerVendor(), data);
  // };
  //
  // public static registerManager = (data?: RegisterFormData) => {
  //   return this.api.post(this.endpoints.registerManager(), data);
  // };

  public static register = (
    data?: AppAuth.RegisterByEmailDto,
    params?: AppAuth.RegisterApiQuery,
  ): Promise<AppResponse<{ email?: string }>> => {
    if (params?.role === RefmeUserRoleEnum.admin) {
      if (!params.admin_secret_key) {
        return Promise.reject({
          response: { data: { message: "Not found admin private_key" } },
        });
      }
    }

    return this.api.post(this.endpoints.register(params?.role), data, {
      params,
    });
  };

  public static logIn = (
    data?: AppAuth.LogInByEmailDto,
    params?: AppAuth.LogInByEmailQuery,
  ): Promise<AppResponse<AppAuth.LoggedUser>> => {
    return this.api
      .post<
        any,
        AppResponse<AppAuth.LoggedUser>
      >(this.endpoints.logIn(), data, { params })
      .then((response) => {
        ClientApi.setToken(response.data.data.access_token);
        return response;
      });
  };

  public static logOut = (): Promise<AppResponse<any>> => {
    const unsetToken = () => ClientApi.unsetToken();
    return this.api.post(this.endpoints.logOut()).finally(unsetToken); // Todo type
  };

  public static getCurrent = (): Promise<AppResponse<AppAuth.LoggedUser>> => {
    return this.api.get(this.endpoints.getCurrent());
  };

  public static refreshToken = (): Promise<AppResponse<any>> => {
    return this.api.get(this.endpoints.refresh()); // Todo type
  };
}

import { useMlmRulesSelector } from "../../../redux/selectors.redux";
import { useNavigate } from "react-router-dom";
import { BlockTemplate } from "../BlockTemplate";
import { BaseTableProps } from "../../Atoms/Table";
import { MlmRulesGroupEntity } from "../../../types";
import { useAppDispatch } from "../../../redux/store.redux";
import { useEffect } from "react";
import {
  getAllMlmRuleGroupsThunk,
  removeMlmRulesGroupThunk,
  restoreMlmRulesGroupThunk,
} from "../../../redux/mlm-rules/mlm-rules.thunks";
import { deleteStatusColumn } from "../../table-columns/tableColumns";
import { TableWithActions } from "../../Tables/TableWithActions";
import { useLoaders } from "../../../hooks";

const columns: BaseTableProps<MlmRulesGroupEntity>["columns"] = [
  {
    title: "Label",
    getData(d) {
      return d.label;
    },
  },
  {
    title: "Scope",
    getData(d) {
      return d.scope;
    },
  },
  {
    title: "Rules count",
    getData(d) {
      return d.rules?.length;
    },
  },
  deleteStatusColumn(),
];
export const MlmRuleGroupsBlock = () => {
  const dispatch = useAppDispatch();
  const groupsList = useMlmRulesSelector().groups;
  const navTo = useNavigate();
  const loadings = useLoaders<"delete" | "restore" | "update" | "create">();

  useEffect(() => {
    !groupsList.length && dispatch(getAllMlmRuleGroupsThunk({}));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <BlockTemplate
      title={"Mlm rules groups list"}
      contentContainerStyles={{
        $padding: "0 16px 16px",
        $xsStyles: { $padding: "0 0 16px" },
      }}
    >
      <TableWithActions
        expandable={false}
        data={groupsList}
        columns={columns}
        isDeleting={loadings.isLoading.delete}
        onRemove={(data) => {
          dispatch(
            removeMlmRulesGroupThunk({
              params: { id: data._id },
            }),
          );
        }}
        isRestoring={loadings.isLoading.restore}
        onRestore={(data) => {
          dispatch(
            restoreMlmRulesGroupThunk({
              params: { id: data._id },
            }),
          );
        }}
        onOpenInNew={(data) => {
          navTo(`/admin/mlm-rules/group/${data._id}`);
        }}
      />
    </BlockTemplate>
  );
};

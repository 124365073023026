import { Tag, TagProps } from "antd";
import { Wallet } from "../../../types/wallets.types";

export const WalletStatusTag = ({
  info,
  status,
}: {
  info?: Partial<Wallet.Entity>;
  status?: Wallet.Entity["status"];
}) => {
  const _status = info?.status || status;
  return (
    <Tag
      bordered
      color={_status ? balanceAccountTagColors?.[_status] : "default"}
    >
      {_status}
    </Tag>
  );
};

export const balanceAccountTagColors: Record<
  Wallet.StatusEnum | string,
  TagProps["color"]
> = {
  [Wallet.StatusEnum.s]: "default",
  [Wallet.StatusEnum.m]: "geekblue",
  [Wallet.StatusEnum.l]: "blue",
  [Wallet.StatusEnum.xl]: "yellow",
  [Wallet.StatusEnum.xxl]: "pink",
  [Wallet.StatusEnum.xxxl]: "gold",
};

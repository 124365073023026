import { FlexForm } from "../../Atoms/Flex";
import React from "react";
import { useForm } from "react-hook-form";
import { OAuth } from "../../../types/auth/o-auth.namespace";
import { useAppDispatch } from "../../../redux/store.redux";
import { createOAuthConnectionThunk } from "../../../redux/auth/o-auth/o-autn.thunks";
import { CreatedModal } from "../../../providers/ModalProvider";
import ModalBase from "../../Atoms/ModalBase";
import { t } from "../../../i18e";

export const CreateOAuthConnectionModal = ({ onClose }: CreatedModal) => {
  const form = useForm<OAuth.Connection.Dto>();

  const dispatch = useAppDispatch();

  const submit = (fData: OAuth.Connection.Dto) => {
    dispatch(
      createOAuthConnectionThunk({
        data: { data: fData },
      }),
    );
  };
  return (
    <ModalBase onClose={onClose} title={t("Create OAuth connection")}>
      <FlexForm onSubmit={form.handleSubmit(submit)}></FlexForm>
    </ModalBase>
  );
};

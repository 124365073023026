import { createAsyncThunk } from "@reduxjs/toolkit";
import { pick } from "lodash";
import { AppResponse } from "../../types/utils.types";
import { ActionPayload, ThunkPayload } from "../../redux/store.redux";
import axiosErrorCheck from "./axiosErrorCheck";

type ThunkGetAppResponseFn<
  Data = any,
  Params = any,
  Return = any,
  Meta = any,
  Extra = any,
> = (
  data?: Data,
  params?: Params,
  extra?: Extra,
) => Promise<AppResponse<Return, Meta>>;

type ThunkDataTransformerFn<Input = any, Return = any> = (
  input: Input,
) => Promise<Return> | Return;

export const createAppAsyncThunk = <
  Data = any,
  Params = any,
  Return = any,
  Meta = any,
  Extra = any,
  Error = any,
  TrReturn = any,
  // InitData extends Return['data'] = Return['data'],
  // ThunkConfig extends AsyncThunkConfig = any
>(
  type: string,
  getResponse: ThunkGetAppResponseFn<Data, Params, Return, Meta, Extra>,
  dataTransformer?: ThunkDataTransformerFn<
    ActionPayload<{ data: Return; params?: Params }, Extra>,
    TrReturn
  >,
) => {
  return createAsyncThunk<
    ActionPayload<
      { data: Return; params?: Params; transformed?: TrReturn },
      Extra
    >,
    ThunkPayload<
      // ! поміняти місцями
      Data,
      ActionPayload<
        { data: Return; params?: Params; transformed?: TrReturn },
        Extra
      >,
      Error,
      Meta,
      Params,
      Extra,
      Return
    >
  >(
    type,
    async (
      { onError, onLoading, onSuccess, initialData, ...arg } = {},
      thunkAPI,
    ) => {
      if (initialData) {
        const rData: ActionPayload<
          { data: Return; params?: Params; transformed?: TrReturn },
          Extra
        > = {
          data: initialData,
          ...pick(arg, ["params", "update", "extra", "refresh"]),
        };

        if (dataTransformer) {
          const trRes = dataTransformer(rData);
          if (trRes instanceof Promise) {
            rData.transformed = await trRes;
          } else {
            rData.transformed = trRes;
          }
        }
        return rData;
      }

      onLoading && onLoading(true);

      try {
        const res = await getResponse(arg?.data, arg?.params, arg.extra);
        const rData: ActionPayload<
          { data: Return; params?: Params; transformed?: TrReturn },
          Extra
        > = {
          data: res?.data.data,
          ...pick(arg, ["params", "update", "extra", "refresh"]),
        };

        if (dataTransformer) {
          const trRes = dataTransformer(rData);
          if (trRes instanceof Promise) {
            rData.transformed = await trRes;
          } else {
            rData.transformed = trRes;
          }
        }

        if (res) {
          onSuccess && onSuccess(rData, res?.data?.meta);
        }

        return rData;
      } catch (error) {
        onError && onError(error as Error);
        return thunkAPI.rejectWithValue(axiosErrorCheck(error));
      } finally {
        onLoading && onLoading(false);
      }
    },
  );
};

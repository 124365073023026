import { BaseTableProps, TableColumnData } from "../components/Atoms/Table";
import {
  RevenueEventEntity,
  TempRevenueEvent,
} from "../types/app-events.types";
import { Tag } from "antd";
import FlexBox from "../components/Atoms/Flex";
import { defaultColumn } from "../components/table-columns/tableColumns";

export const revenueEventColumns: BaseTableProps<RevenueEventEntity>["columns"] =
  [
    {
      title: "User reference",
      width: "120px",
      getData: (d) => {
        return d?.user?.reference;
      },
    },
    {
      title: "User email",
      getData: (d) => {
        return d?.user?.email;
      },
    },

    priceTotalColumn(),
    {
      title: "Token",
      getData: (row) =>
        (row?.token?.type ?? "---") + " / " + (row?.token?.symbol ?? "---"),
    },
    {
      title: "Offer label",
      path: "label",
    },

    {
      title: "Offer category",
      path: "category",
    },

    // {
    //   title: "Cashback token",
    //   getData: (row) =>
    //     (row?.cashback?.token?.type ?? "---") +
    //     " / " +
    //     (row?.cashback?.token?.symbol ?? "---"),
    // },

    {
      title: "Custom key",
      getData: (row) => row.cashback?.customKey,
    },
    defaultColumn({
      title: "Token",
      getData: (row) => row.token?.publicKey,
      getCopyValue: (row) => row.token?.publicKey,
    }),
  ];

function priceTotalColumn(): TableColumnData<any> {
  return {
    title: "Price/Quantity/Total",
    path: "price",
    getData: (row) => {
      return (
        <FlexBox $fxDirection={"row"} $gap={6}>
          <Tag>{row.price}</Tag>
          <Tag>{row.quantity}</Tag>
          <Tag>{row.total}</Tag>
        </FlexBox>
      );
    },
  };
}

export const tempRevenueColumns: BaseTableProps<TempRevenueEvent>["columns"] = [
  {
    title: "User email",
    getData: (d) => {
      return d?.user?.email;
    },
  },
  {
    title: "User reference",
    width: "120px",
    getData: (d) => {
      return d?.user?.reference;
    },
  },

  priceTotalColumn(),
  {
    title: "Token",
    getData: (row) =>
      (row?.token?.type ?? "---") + " / " + (row?.token?.symbol ?? "---"),
  },
  {
    title: "Offer label",
    path: "label",
  },

  {
    title: "Offer category",
    path: "category",
  },

  {
    title: "Cashback token",
    getData: (row) =>
      (row?.cashback?.token?.type ?? "---") +
      " / " +
      (row?.cashback?.token?.symbol ?? "---"),
  },

  {
    title: "Custom key",
    getData: (row) => row.cashback?.customKey,
  },

  defaultColumn({
    title: "Token",
    getData: (row) => row.token?.publicKey,
    getCopyValue: (row) => row.token?.publicKey,
  }),
];

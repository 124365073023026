import { createSlice } from "@reduxjs/toolkit";
import {
  createOAuthConnectionThunk,
  getAllOAuthConnectionsThunk,
} from "./o-autn.thunks";
import { OAuth } from "../../../types/auth/o-auth.namespace";

export interface OAuthState {
  list: OAuth.Connection.Entity[];
}

const iState: OAuthState = {
  list: [],
};
export const oAuthSlice = createSlice({
  name: "o_auth",
  reducers: {},
  initialState: iState,
  extraReducers: (builder) =>
    builder
      .addCase(getAllOAuthConnectionsThunk.fulfilled, (st, a) => {
        st.list = a.payload.data;
      })
      .addCase(createOAuthConnectionThunk.fulfilled, (st, a) => {
        st.list.push(a.payload.data);
      }),
});

import APP_CONFIGS from "../../configs/APP_CONFIGS";
import ClientApi from "../Client.api";
import { BalanceAccount } from "../../types";
import { AppResponse, UUID } from "../../types/utils.types";

export class BalanceAccountsApi {
  private static api = ClientApi.getClient();
  private static endpoints = APP_CONFIGS.endpoints.balances;

  public static getAll = (
    _data?: undefined,
    params?: {
      offset?: number;
      limit?: number;
      type?: BalanceAccount.TypesEnum;
      types?: BalanceAccount.TypesEnum[];
      // statuses?: WalletStatusEnum[];
      level?: BalanceAccount.LevelEnum;
      levels?: BalanceAccount.LevelEnum[];
      walletsIds?: UUID[];
      ids?: UUID[];

      ownerId?: UUID;
      holderId?: UUID;
      tokenId?: UUID;
    },
  ): Promise<AppResponse<BalanceAccount.Entity[]>> => {
    return this.api.get(this.endpoints.getList(), { params });
  };

  public static createAmbassadorCount = (
    data?: BalanceAccount.DtoMap["ambassador"],
  ): Promise<AppResponse<BalanceAccount.Entity[]>> => {
    return this.api.post(this.endpoints.createAmbassador(), data);
  };
}

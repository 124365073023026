import styled from "styled-components";
import FlexBox, { FlexFieldSet } from "./Flex";

const BlockContainer = styled(FlexFieldSet)`
  appearance: unset;
  border: 1px solid ${(p) => p.theme.lightBorderColor};
  width: 100%;
  border-radius: 8px;

  min-inline-size: unset;

  overflow: hidden;
`;

const BlockHeader = styled(FlexBox)`
  padding: 16px;
  border-bottom: 1px solid ${(p) => p.theme.lightBorderColor};
`;
const BlockFooter = styled(FlexBox)`
  padding: 16px;
  border-top: 1px solid ${(p) => p.theme.lightBorderColor};
`;

// export const Box = {
//   Container: BlockContainer,
//   Header: BlockHeader,
//   Footer: BlockFooter,
// };

export class Block {
  public static Container = BlockContainer;
  public static Header = BlockHeader;
  public static Footer = BlockFooter;
}

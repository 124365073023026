import { useAuthSelector } from "../redux/selectors.redux";
import React from "react";
import { Navigate } from "react-router-dom";
import { User } from "../types";
import PrivateLayout from "./PrivateLayout";

const AdminLayout = ({ isDevMode }: { isDevMode?: boolean }) => {
  const { user } = useAuthSelector();

  const canActivate = isDevMode || user?.role === User.RoleEnum.admin;

  return !canActivate ? <Navigate to={"/transactions"} /> : <PrivateLayout />;
};

export default AdminLayout;

import React, { useMemo } from "react";
import styled from "styled-components";
import { Property } from "csstype";
import FlexBox from "./Flex";
import { IBase } from "../../types/utils.types";
import { Alert, Empty } from "antd";

export type TableDataType = Partial<IBase> & { [key: string]: any | undefined };
export type TableCellData = React.ReactNode;

export type TableColumnData<DataType extends TableDataType> = {
  title?: string;
  key?: string;
  dataIndex?: keyof DataType | string;
  path?: keyof DataType;
  maxWidth?: Property.Width;
  minWidth?: Property.Width;
  width?: Property.Width;
  getData?: (
    data: DataType,
    column: TableColumnData<DataType>,
  ) => TableCellData;
  render?: (data: DataType) => TableCellData;
  canBeCopied?: boolean;
  isVisible?: boolean;
  align?: Property.TextAlign;
};
export type BaseTableProps<DataType extends TableDataType> = {
  data?: DataType[];
  // onRowPress?: (data: DataType, index: number) => void;
  onRowPress?: (row: DataType | undefined, index: number | undefined) => void;

  columns: TableColumnData<DataType>[];
  noDataDescription?: string;
  noDataLabel?: string;
  onDataPress?: () => void;
  selected?: DataType;
  keyExtractor?: (data: DataType, index: number) => string | undefined;
};
const BaseTable = <DataType extends TableDataType>({
  data,
  onRowPress,
  columns,
  noDataDescription,
  noDataLabel = "Дані відсутні",
  onDataPress,
  selected,
  keyExtractor,
}: BaseTableProps<DataType>) => {
  const tableColumns = useMemo((): TableColumnData<DataType>[] => {
    return columns?.filter((el) => el?.isVisible !== false) ?? [];
  }, [columns]);
  const selectedId =
    (selected && keyExtractor && keyExtractor(selected, -1)) ?? -1;

  return (
    <FlexBox
      $fillWidth
      $gap={16}
      style={{ position: "relative", overflow: "hidden" }}
    >
      <TableContainer $fillWidth overflow={"auto"}>
        <Table>
          {/* <thead> */}
          <THead>
            <tr>
              {tableColumns?.map((col) => {
                return (
                  <th
                    key={`col_${col?.key || col?.title}`}
                    scope="col"
                    style={{
                      whiteSpace: "nowrap",
                      minWidth: col?.minWidth || col?.width,
                      maxWidth: col?.maxWidth,
                      textAlign: col?.align,
                    }}
                  >
                    {col?.title}
                  </th>
                );
              })}
            </tr>
          </THead>

          <tbody>
            {data?.map((item, index) => {
              const rowId =
                (keyExtractor && keyExtractor(item, index)) ??
                item?._id ??
                index;
              return (
                // <Component key={index} {...item} />
                <tr
                  key={`tableBdRow_${rowId || index}`}
                  className={selectedId === rowId ? "selected" : ""}
                  onClick={() => {
                    onRowPress && onRowPress(item, index);
                    console.log(item);
                  }}
                >
                  {tableColumns?.map((col) => {
                    return (
                      <td
                        key={`cell_${col?.key || col?.title}`}
                        style={{
                          minWidth: col?.minWidth || col?.width,
                          maxWidth: col?.maxWidth,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textAlign: col?.align,
                        }}
                      >
                        {(col?.getData
                          ? col?.getData(item, col)
                          : col?.path && (item?.[col?.path] ?? null)) ?? "---"}
                      </td>
                    );
                  })}
                  {/* <td>item[itemValuesArray[index]]</td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {!data?.length ? (
        <Empty description={noDataLabel}>
          {noDataDescription && (
            <Alert message={noDataDescription} type={"info"} />
          )}
        </Empty>
      ) : (
        <></>
      )}
    </FlexBox>
  );
};

export default BaseTable;

const TableContainer = styled(FlexBox)`
  border: 1px solid ${(p) => p.theme.lightBorderColor};
`;

const Table = styled.table`
  border-collapse: collapse;
  border-radius: 12px 12px 0 0;
  table-layout: auto;

  background-color: inherit;

  tr {
    position: relative;
    background-color: inherit;
  }
  th {
    padding: 8px 12px;
    width: max-content;
    background-color: inherit;
    /* height: 50px; */
  }

  td {
    padding: 2px 12px;
    width: max-content;
    white-space: nowrap;
    height: 44px;

    //&:first-child {
    //  position: sticky;
    //  top: 0;
    //  left: 0;
    //  z-index: 12;
    //}
  }

  th {
    text-align: left;
  }
  tbody {
    td {
      background-color: inherit;
    }
    tr {
      border-bottom: 1px solid ${(p) => p.theme.lightBorderColor};
      cursor: default;

      &.selected {
        background-color: ${(p) => p.theme.tableHeadClr};
      }

      &:hover {
        background-color: ${(p) => p.theme.tableHeadClr};
      }
    }
  }
`;

const THead = styled.thead`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  min-width: 100%;

  background-color: inherit;
  width: max-content;

  color: ${(p) => p.theme.colors.default};

  th {
    background-color: ${(p) => p.theme.tableHeadClr};
    border-bottom: 1px solid ${(p) => p.theme.lightBorderColor};
  }
  tr {
    th {
      background-color: ${(p) => p.theme.tableHeadClr};
    }
    //border-bottom: 1px solid ${(p) => p.theme.lightBorderColor};
    //border-top: 1px solid ${(p) => p.theme.lightBorderColor};
  }
`;

// const TableBody = styled(FlexBox)`
//   overflow-x: auto;
//   margin-top: 0px;
//   /* border: 1px solid ${(p) => p.theme.accentColor}; */
// `;

import { HasAccess, HasAccessStatus, HasType, IBase } from "./utils.types";
import { RefmeUserRoleEnum, UserEntity } from "./user.types";
import { TokenEntity } from "./token.types";
import { Wallet } from "./wallets.types";

export namespace BalanceAccount {
  export enum TypesEnum {
    debit = "debit",
    credit = "credit",
    deposit = "deposit",
    hold = "hold",
    reserve = "reserve",
  }

  export enum LevelEnum {
    account = "account",
    card = "card",
  }

  export enum AccessEnum {
    active = "active",
    closed = "closed",
    freeze = "freeze",
  }
  export interface StatisticsEmbeddedEntity {
    referral?: string;
    extra?: string;
    personal?: string;
    transfersIn?: string;
    transfersOut?: string;
  }
  export interface DetailsEmbeddedEntity {
    debit?: number;
    credit?: number;
  }
  export interface BaseEntity
    extends HasType<TypesEnum>,
      HasAccessStatus<AccessEnum>,
      HasAccess<AccessEnum> {
    code?: string;
    saldo?: string;

    level?: LevelEnum;

    details?: DetailsEmbeddedEntity;

    statistics?: StatisticsEmbeddedEntity;
  }
  export interface Entity extends IBase, BaseEntity {
    emitter?: UserEntity;

    holder?: UserEntity;

    token?: TokenEntity;

    wallet?: Wallet.Entity;
  }

  export interface DtoMap {
    [RefmeUserRoleEnum.ambassador]: {
      userId?: string;
      vendorId?: string;
      tokenId?: string;
    };
  }
}

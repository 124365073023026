import APP_CONFIGS from "../configs/APP_CONFIGS";
import ClientApi from "./Client.api";
import { AppResponse } from "../types/utils.types";
import { EmitTokenDto, TokenEntity } from "../types";

export default class TokensApi {
  private static api = ClientApi.getClient();
  private static endpoints = APP_CONFIGS.endpoints.tokens;
  private static limit = 10;

  public static getAll = (
    _data?: unknown,
    params?: {
      offset?: number;
      limit?: number;
    },
  ): Promise<AppResponse<TokenEntity[]>> => {
    return this.api.get(this.endpoints.getAll(), {
      params: {
        limit: this.limit,
        ...params,
      },
    });
  };

  public static getById = (params?: {
    id: string;
  }): Promise<AppResponse<TokenEntity>> => {
    return this.api.get(this.endpoints.getOne(), { params });
  };

  public static emitBonus = (
    data?: EmitTokenDto,
  ): Promise<AppResponse<TokenEntity>> => {
    return this.api.post(this.endpoints.emitBonus(), data);
  };
  public static emitFiat = (
    data?: EmitTokenDto,
  ): Promise<AppResponse<TokenEntity>> => {
    return this.api.post(this.endpoints.emitFiat(), data);
  };
}

import { BlockTemplate } from "../BlockTemplate";
import { Path } from "react-hook-form";
import { Button, Input, Select } from "antd";
import { Label } from "../../FormElements/Label";
import { FlexBox, FlexForm } from "../../Atoms/Flex";
import { useAppDispatch } from "../../../redux/store.redux";
import { createMlmRulesGroupThunk } from "../../../redux/mlm-rules/mlm-rules.thunks";
import { useAppForm } from "../../../hooks";

export interface CreateMlmRulesGroupFormData {
  label?: string;
  scope?: string;
}
export const CreateMlmRulesGroupBlock = () => {
  const dispatch = useAppDispatch();
  const form = useAppForm<CreateMlmRulesGroupFormData>();
  const fValues = form.watch();
  const onValid = (fData: CreateMlmRulesGroupFormData) => {
    console.log({ fData });
    dispatch(
      createMlmRulesGroupThunk({
        data: fData,
        onSuccess: () => {
          form.reset();
        },
      }),
    );
  };

  const registerOnChange = (name: Path<CreateMlmRulesGroupFormData>) => {
    return (ev: React.ChangeEvent<HTMLInputElement>) => {
      form.setValue(name, ev.target.value);
    };
  };

  return (
    <BlockTemplate title={"Create group"}>
      <FlexForm
        width={"360px"}
        $gap={16}
        onSubmit={form.handleSubmit(onValid)}
        onReset={() => {
          form.reset();
        }}
      >
        <FlexBox $gap={6}>
          <Label label={"Label"}>
            <Input
              {...form.register("label", { required: true })}
              value={fValues?.label}
              {...form.register("label", { required: true })}
              placeholder={"Enter label"}
              required
            ></Input>
          </Label>

          <Label label={"Scope"}>
            {/*<Input*/}
            {/*  {...form.register("scope")}*/}
            {/*  value={fValues?.scope}*/}
            {/*  onChange={registerOnChange("scope")}*/}
            {/*  placeholder={"Enter scope"}*/}
            {/*></Input>*/}

            <Select
              style={{ width: "100%" }}
              value={fValues?.scope}
              options={[
                { value: "default", label: "default" },
                { value: "private", label: "private" },
                { value: "public", label: "public" },
              ]}
              placeholder={"Select scope"}
              onSelect={form.registerOnSelect("scope")}
            />
          </Label>
        </FlexBox>

        <Button htmlType={"submit"} type={"primary"}>
          {"Submit"}
        </Button>

        <Button htmlType={"reset"}>{"Clear"}</Button>
      </FlexForm>
    </BlockTemplate>
  );
};

import AppRoutes from "./AppRoutes/AppRoutes";
import { ToastContainer } from "react-toastify";
import React from "react";

export interface AppProps {}
export default function App(props: AppProps) {
  return (
    <>
      <AppRoutes />
      <ToastContainer theme={"light"} autoClose={2000} />
    </>
  );
}

import { BaseApiProviders } from "../api/Client.api";

export enum EnvKeyEnum {
  API_KEY = "API_KEY",
  API_DEV_KEY = "API_DEV_KEY",
  PORT = "PORT",
  NOVA_POST_API_KEY = "NOVA_POST_API_KEY",
  ACCESS_TOKEN = "ACCESS_TOKEN",
  PERMISSION_TOKEN = "PERMISSION_TOKEN",
  BASE_API_PROVIDER = "BASE_API_PROVIDER",
  BASE_URL_LOCAL_SERVER = "BASE_URL_LOCAL_SERVER",
  VERCEL_URL = "VERCEL_URL",
  VERCEL_ENV = "VERCEL_ENV",
  VERCEL = "VERCEL",
}

export enum VercelEnvTypeEnum {
  preview = "preview",
  development = "development",
  production = "production",
}

type ConfigKeyType = keyof typeof EnvKeyEnum | EnvKeyEnum;

export type EnvValueByKey =
  | ({
      [EnvKeyEnum.VERCEL_ENV]: VercelEnvTypeEnum;
      [EnvKeyEnum.BASE_API_PROVIDER]: string;
    } & Record<ConfigKeyType, string>)
  | NodeJS.ProcessEnv;
export class ConfigService {
  public static get isDevMode() {
    return process.env.NODE_ENV === "development";
  }
  private static _api_key = process.env[EnvKeyEnum.API_KEY];

  public static get<T extends ConfigKeyType = any>(key: T): EnvValueByKey[T] {
    return process.env[key];
  }
  public static getApiKey(): string | null {
    return this._api_key || null;
  }
  public static getApiProvider(): BaseApiProviders | null {
    return (
      (process.env[EnvKeyEnum.BASE_API_PROVIDER] as BaseApiProviders) ?? null
    );
  }
}

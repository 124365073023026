import { MlmRulesGroupOverviewBlock } from "../../components/Blocks/Admin/MlmRulesGroupOverviewBlock";
import { Tabs, TabsProps } from "antd";
import { ObjectEntries } from "../../shared/utils";
import { useAppRouter } from "../../hooks";
import { ReferralRulesBlock } from "../../components/Blocks/Admin/ReferralRulesBlock";
import { PersonalRulesBlock } from "../../components/Blocks/Admin/PersonalRulesBlock";
import { VolumeRulesBlock } from "../../components/Blocks/Admin/VolumeRulesBlock";
import { ExtraRulesBlock } from "../../components/Blocks/Admin/ExtraRulesBlock";

const tabs = {
  Personal: <PersonalRulesBlock />,
  Referral: <ReferralRulesBlock />,
  Volume: <VolumeRulesBlock />,
  Extra: <ExtraRulesBlock />,
  Overview: <MlmRulesGroupOverviewBlock />,
};

const items: TabsProps["items"] = ObjectEntries(tabs).map(([key, item]) => {
  return {
    key,
    label: key,
    children: item,
  };
});

const MlmRulesGroupOverviewPage = () => {
  const { setHash, hash } = useAppRouter();

  return (
    <Tabs
      items={items}
      activeKey={hash}
      onChange={(v) => {
        setHash(v);
      }}
    />
  );
};

export default MlmRulesGroupOverviewPage;

import React from "react";
import { ContainerSmall } from "../components/Atoms/Container";
import LogInUserBlock from "../components/Blocks/Auth/LogInUserBlock";

const LogInPage = () => {
  return (
    <ContainerSmall $gap={24} $alignItems={"center"} $justifyContent={"center"}>
      <LogInUserBlock />
    </ContainerSmall>
  );
};

export default LogInPage;

import { RouteObject, useNavigate } from "react-router-dom";
import { ConfigService } from "../services/ConfigService.serv";
import PublicLayout from "../layout/PublicLayout";
import RegisterPage from "../AppPages/RegisterPage";
import SuccessRegisterPage from "../AppPages/SuccessRegisterPage";
import LogInPage from "../AppPages/LoginPage";
import PrivateLayout from "../layout/PrivateLayout";
import Transactions from "../AppPages/Transactions";
import InvoicesPage from "../AppPages/InvoicesPage";
import UsersPage from "../AppPages/UsersPage";
import ProfilePage from "../AppPages/ProfilePage";
import React, { useEffect } from "react";
import AdminLayout from "../layout/AdminLayout";
import NotFoundPage from "../AppPages/NotFoundPage";
import EventsPage from "../AppPages/EventsPage";
import MlmRuleGroupsPage from "../AppPages/admin/MlmRuleGroupsPage";
import MlmRulesPage from "../AppPages/admin/MlmRulesPage";
import MlmRulesGroupOverviewPage from "../AppPages/admin/MlmRulesGroupOverviewPage";
import { WalletsPage } from "../AppPages/WalletsPage";
import { OAuthConnectionsPage } from "../AppPages/OAuthConnectionsPage";
import { OAuthCreateConnectionPage } from "../AppPages/OAuthCreateConnectionPage";

const NavToLogin = () => {
  const navTo = useNavigate();

  useEffect(() => {
    navTo("logIn");
  });
  return null;
};

export const appRoutesList: RouteObject[] = [
  { path: "/", element: <NavToLogin /> },
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      {
        path: "/register",
        element: <RegisterPage />,
        children: [
          { path: "/register/success", element: <SuccessRegisterPage /> },
        ],
      },
      {
        path: "/login",
        element: <LogInPage />,
      },
    ],
  },
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      {
        // label: "Dashboard",
        path: "/transactions",
        element: <Transactions />,
      },
      { path: "/invoices", element: <InvoicesPage /> },
      { path: "/users", element: <UsersPage /> },
      { path: "/profile", element: <ProfilePage /> },
      { path: "/events", element: <EventsPage /> },
      { path: "/wallets", element: <WalletsPage /> },
    ],
  },
  {
    path: "/o-auth",
    element: <PrivateLayout />,
    children: [
      {
        path: "connections",
        element: <OAuthConnectionsPage />,
      },
      {
        path: "connections/create",
        element: <OAuthCreateConnectionPage />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout isDevMode={ConfigService.isDevMode} />,
    children: [
      {
        path: "mlm-rules/groups",
        element: <MlmRuleGroupsPage />,
      },
      {
        path: "mlm-rules",
        element: <MlmRulesPage />,
      },
      {
        path: "mlm-rules/group/:groupId",
        element: <MlmRulesGroupOverviewPage />,
      },
    ],
  },
  { path: "*", element: <NotFoundPage /> },
];

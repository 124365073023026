import {
  MlmRuleDtoKeyEnum,
  MlmRuleEntity,
  MlmRuleSourceVolumeEnum,
  MlmRuleValueTypeEnum,
} from "../../../types";
import { EnumToSelectOptions, ObjectValues } from "../../../shared/utils";

export const sourceVolumes = ObjectValues(MlmRuleSourceVolumeEnum);

export const ruleValueTypeOptions = EnumToSelectOptions(MlmRuleValueTypeEnum);

export function getRuleKey(
  rule: Partial<MlmRuleEntity>,
  keys?: MlmRuleDtoKeyEnum[],
) {
  let key = "";
  for (const k of keys ?? [
    MlmRuleDtoKeyEnum.depth,
    MlmRuleDtoKeyEnum.userStatus,
    MlmRuleDtoKeyEnum.walletStatus,
    MlmRuleDtoKeyEnum.customKey,
  ]) {
    const v = rule[k];
    if (v) {
      key = key ? key + "_" + v : `${v}`;
    }
  }
  // return `${rule?.depth}_${rule?.userStatus}_${rule?.accountStatus}_${rule?.customKey}`;
  return key;
}
export type RuleDataGetter = (rule: Partial<MlmRuleEntity>) => MlmRuleEntity;

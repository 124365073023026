import ToastService from "./ToastService.serv";

export enum StorageKeys {
  FilterValues = "filterValues",
}

export class StorageService {
  public static async copyText(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      ToastService.info("Дані скопійовано до буферу обміну");
    } catch (e) {
      console.log("e", e);
    }
  }
  public static async getText() {
    try {
      return navigator.clipboard.readText();
    } catch (e) {}
  }

  public static async shareContent(data: ShareData) {
    try {
      await navigator.share(data);
    } catch (err) {
      console.log(err);
      ToastService.error(`Помилка: ${err}`);
    }
  }

  public static setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public static getItem<T>(key: string): T | null {
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

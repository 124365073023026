import { RefmeUserRoleEnum, UserEntity } from "../user.types";
import { IBase, Values } from "../utils.types";
import { Device } from "../../api/auth/Devices.api";
import { OAuth } from "./o-auth.namespace";
import { TokenEntity } from "../token.types";
import { ApiResponse } from "../../api/types.api";

export namespace AppAuth {
  export namespace Session {
    export enum Type {
      user = "user",
      token = "token",
      service = "service",
    }

    export interface ServiceOrTokenFields {
      // * service + token fields
      label?: string;

      publicKey?: string;
      privateKey?: string;

      public_key?: string;
      private_key?: string;
    }
    export interface HasServiceFields extends ServiceOrTokenFields {
      type: Type.service;
      cors?: {
        ips?: string[];
        origins?: string[];
      };
    }
    export interface StartForServiceDto {
      label?: string;
      expiresAt?: string;
      ip?: string;
      originUrl?: string;
    }
    export interface HasTokenFields extends ServiceOrTokenFields {
      type: Type.token;
      token?: TokenEntity; // * якщо дозвіл на токен виписаний.
    }

    export interface HasUserFields {
      type: Type.user;
      // * user fields
      device?: Device.Entity;
      oAuth?: OAuth.Profile.Entity;
      access_token?: string;
      refresh_token?: string;
    }

    export type AddFieldsMap = {
      user: HasUserFields;
      token: HasTokenFields;
      service: HasServiceFields;
    };
    interface _Entity extends IBase {
      user?: UserEntity;
    }

    export type Entity<T extends Type = never> = _Entity &
      (T extends any
        ? AddFieldsMap[T]
        : HasServiceFields | HasTokenFields | HasUserFields);

    export interface GetAllApiQuery<
      T extends
        | AppAuth.Session.Type
        | Values<typeof AppAuth.Session.Type> = AppAuth.Session.Type,
    > {
      label?: string;
      type?: T;
    }
    export interface GetOneApiQuery {
      _id?: string;
      publicKey: string;
    }

    export type GetAllResponse<T extends Session.Type> = ApiResponse<
      Session.Entity<T>
    >;
  }

  export interface LoggedUser extends UserEntity {
    access_token: string;
    refresh_token?: string;
    session?: AppAuth.Session.Entity<AppAuth.Session.Type.user>;
  }

  export interface RegisterByEmailDto {
    email?: string;
    password?: string;
    label?: {
      base: string;
      full: string;
      print: string;
    };
    name?: {
      first?: string;
      second?: string;
      middle?: string;
    };
    referrerRef?: string;

    admin?: {
      private_key: string;
    };
  }
  export interface RegisterApiQuery {
    role: RefmeUserRoleEnum;
    admin_secret_key?: string;
  }
  export interface LogInByEmailDto {
    email: string;
    password: string;
  }
  export interface LogInByEmailQuery {
    withUnverified?: boolean;
  }
}

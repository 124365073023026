import axios, { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { ClientLogger } from "../services/ClientLogger.service";
import { EvEmitter } from "../services/EventEmitterService.serv";
import { BooleanishType, PartialRecord, Values } from "../types/utils.types";
import { AppQuery } from "../hooks";

export namespace HttpApi {
  export enum Headers {
    x_token_server = "x-token-server",
    origin = "origin",
    referer = "referer",
    dnt = "dnt",
    isSecureRequest = "isSecureRequest",

    is_client = "is-client",
    x_token = "X-Token",
    p_token = "P-Token",
    x_token_private = "X-Token-Private",
    x_token_crm = "X-Token-Crm",
    Track_Id = "Track-Id",
    track_id = "track-id",
    Reference = "Reference",
    reference = "reference",

    cookies_permission = "cookies-permission",
    Cookies_Permission = "Cookies-Permission",

    S_To_S_key = "s-to-s-key",
    x_proxy_source = "x-proxy-source",
    x_forwarded_for = "x-forwarded-for",
    x_forwarded_proto = "x-forwarded-proto",
    x_request_id = "x-request-id",
    host = "host",

    x_invoke_path = "x-invoke-path",
    x_invoke_query = "x-invoke-query",
    x_invoke_output = "x-invoke-output",
  }
  export interface ResponseData<D = any, M = any> {
    statusCode?: number;
    message?: string;
    innerCode?: number;
    description?: string;
    meta?: M;
    data: D;
  }
  export interface Response<D = any, M = any>
    extends AxiosResponse<ResponseData<D, M>> {}

  export enum ReservedEventName {
    onUnauthorized = "onUnauthorized",
    onForbidden = "onForbidden",
    onRefreshToken = "onRefreshToken",
  }

  export type DefaultEventsMap = {
    [ReservedEventName.onUnauthorized]: AxiosError;
    [ReservedEventName.onForbidden]: AxiosError;
    [ReservedEventName.onRefreshToken]: {
      _id: string;
      access_token: string;
      refresh_token?: string;
    };
  };

  type DefaultEventListenersMap =
    EvEmitter.ListenersMappedType<DefaultEventsMap>;

  export type EventListenersMap<
    ListenersMap extends EvEmitter.Events = EvEmitter.Events,
  > = ListenersMap & DefaultEventListenersMap;

  type BooleanHeaderType =
    | Headers.dnt
    | Headers.cookies_permission
    | Headers.isSecureRequest;
  type BooleanishHeaders = PartialRecord<BooleanHeaderType, BooleanishType>;

  type StringHeaders = PartialRecord<
    Values<Omit<typeof Headers, BooleanHeaderType>>,
    string
  >;

  export type CreateOptions = {
    name?: string;
    baseURL?: string;
    apiKey?: string | null;
    withCredentials?: boolean;
    isRefreshing?: boolean;
    x_server_api_key?: string;
    withAuthInterceptor?: boolean;

    headers?: StringHeaders & BooleanishHeaders;

    eventListeners?: Partial<EventListenersMap>;

    refreshParams?: {
      skipPaths?: string[];
      url: string;
      logOutUrl?: string;
    };
  };

  export type Query = AppQuery & {
    limit?: number;
    offset: number;
  };

  export type PickQueryParams<Key extends keyof Query = never> = Pick<
    Query,
    Key extends keyof Query ? Key : never
  >;

  export type PickQueryParamsForMany<Key extends keyof Query = never> = Pick<
    Query,
    ("limit" | "offset") | (Key extends keyof Query ? Key : never)
  >;

  export const create = createApiClient;
  export const createLocal = createLocalClient;
}

export function createApiClient(
  {
    name,
    baseURL,
    withCredentials = true,
    headers,
    eventListeners,
    x_server_api_key,
    withAuthInterceptor = true,
  }: HttpApi.CreateOptions = { name: "" },
) {
  const logger = new ClientLogger(name ?? createApiClient.name);

  logger.log(
    {
      baseURL,
      withCredentials,
      headers: {
        [HttpApi.Headers.cookies_permission]: withCredentials,
        [HttpApi.Headers.x_token_server]: x_server_api_key,
        ...headers,
      },
    },
    // ConfigService.devIs,
    // ConfigService.apiProviderIs,
  );

  const client = axios.create({
    baseURL,
    withCredentials,
    headers: {
      // [HttpApi.Headers.is_client]: true, // TODO фільтрувати на проксі

      [HttpApi.Headers.cookies_permission]: withCredentials,
      [HttpApi.Headers.x_token_server]: x_server_api_key,
      ...headers,
    },
  });

  if (withAuthInterceptor) {
    //  const _queue = new AxiosQueueService(client, { name: "default" });
    // console.log("setAuthInterceptor", { _queue });

    client.interceptors.response.use(
      (value) => value,
      async (error: AxiosError) => {
        const statusIs: PartialRecord<`_${HttpStatusCode}`, boolean> = {
          [`_${error.response?.status ?? 500}`]: true,
        };

        if (statusIs._401 && eventListeners?.onUnauthorized) {
          // if (eventListeners?.onRefreshToken) {
          //   // await eventListeners.onRefreshToken({_id: , access_token: ,});
          //   console.log("refresh");
          // } else {
          // }

          eventListeners.onUnauthorized(error);
        }

        throw error;
      },
    );
  }

  // ! тут вішаємо інтецептор який постійно слідкує за наявніст. 401 ствтусу підчас помилки відповді сервреі
  // ! ну добавляємо до черги чи запускаємо її обробку

  return client;
}

function createLocalClient(...args: Parameters<typeof createApiClient>) {
  const client = createApiClient(...args);

  return client;
}

import React from "react";
import { Property } from "csstype";
import styled from "styled-components";

type FontWeight = 400 | 500 | 600 | 700 | 800 | 900;

export interface StButtonProps {
  $flex?: Property.Flex;
  $fxDirection?: Property.FlexDirection;
  $justifyContent?: Property.JustifyContent;
  $alignItems?: Property.AlignItems;
  $alignSelf?: Property.AlignSelf;
  $flexWrap?: Property.FlexWrap;
  $gap?: number;

  $width?: Property.Width;
  $minWidth?: string;
  $maxWidth?: string;
  $height?: Property.Height;
  $minHeight?: string;
  $maxHeight?: string;
  $borderBottom?: Property.BorderBottom;
  $borderRadius?: Property.BorderRadius;
  $border?: Property.Border;
  $fillWidth?: boolean;
  $fillHeight?: boolean;
  $padding?: Property.Padding;
  $margin?: string;

  $size?: string;
  $iconId?: string;
  $iconSize?: string;
  $index?: number;
  $fontFamily?: Property.FontFamily;
  $fontSize?: string;
  $fontWeight?: FontWeight;
  $fontStyle?: Property.FontStyle;
  $textTransform?: Property.TextTransform;
  $isActive?: boolean;
  $background?: Property.Background;
  $backgroundColor?: Property.BackgroundColor;
  $overflow?: Property.Overflow;
}

export interface BaseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    StButtonProps {}

export const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  $isActive,
  type = "button",
  $iconSize = "18px",
  onClick = () => {},

  ...props
}) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      $isActive={$isActive}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<StButtonProps>`
  display: flex;
  flex: ${({ $flex = "" }) => $flex};
  flex-direction: ${({ $fxDirection = "row" }) => $fxDirection};
  justify-content: ${({ $justifyContent = "center" }) => $justifyContent};
  align-items: ${({ $alignItems = "center" }) => $alignItems};
  align-self: ${({ $alignSelf }) => $alignSelf};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};
  gap: ${({ $gap }) => $gap}px;

  width: ${({ $width, $size }) => $size || $width};
  min-width: ${({ $minWidth, $size }) => $size || $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  height: ${({ $height, $size }) => $size || $height};
  min-height: ${({ $minHeight, $size }) => $size || $minHeight};
  max-height: ${({ $maxHeight }) => $maxHeight};
  border: ${({ $border = "none" }) => $border};
  border-bottom: ${({ $borderBottom }) => $borderBottom};
  border-radius: ${({ $borderRadius = "2px" }) => $borderRadius};

  padding: ${({ $padding }) => $padding || 0};
  margin: ${({ $margin }) => $margin || 0};

  text-align: center;
  font-family: ${({ $fontFamily = "inherit" }) => $fontFamily};
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-style: ${({ $fontStyle = "normal" }) => $fontStyle};
  text-transform: ${({ $textTransform }) => $textTransform};
  color: ${({ color = "inherit" }) => color};
  fill: currentColor;

  background: ${({ $background }) => $background};
  background-color: ${({ $backgroundColor = "transparent" }) =>
    $backgroundColor};
  overflow: ${({ $overflow }) => $overflow};

  cursor: pointer;

  &[disabled] {
    pointer-events: none;
    background-color: ${(p) => p.theme.fieldBGDColorLight};
    color: ${(p) => p.theme.disabledTextDarkColor};
    opacity: 70%;
  }
`;

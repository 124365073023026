import FlexBox from "../components/Atoms/Flex";
import { Tabs, TabsProps } from "antd";
import { ObjectEntries } from "../shared/utils";
import EventsListBlock from "../components/Blocks/EventsListBlock";
import EmitRevenueEventBlock from "../components/Blocks/EmitRevenueEventBlock";
import { useAppRouter } from "../hooks";

export interface EventsPageProps {}

const tabs = {
  Emit_event: <EmitRevenueEventBlock />,
  Revenues_list: <EventsListBlock />,
};
const items: TabsProps["items"] = ObjectEntries(tabs).map(([key, item]) => {
  return {
    key,
    label: key.replace("_", " "),
    children: item,
  };
});
const EventsPage = () => {
  const { setHash, hash } = useAppRouter();
  // usePreloadRevenues({ isLogged: true, roleIs: { admin: true } });

  return (
    <FlexBox $gap={16}>
      <Tabs items={items} activeKey={hash} onChange={setHash} />
    </FlexBox>
  );
};

export default EventsPage;

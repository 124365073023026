import React from "react";
import BaseTable from "../Atoms/Table";
import { useForm } from "react-hook-form";
import { generateApiKeyData } from "../data/formsData";
import { FlexBox, FlexForm } from "../Atoms/Flex";
import FormInput from "../FormElements/FormInput";
import { Text } from "../Atoms/Text";
import { Alert, Button } from "antd";
import { BlockTemplate } from "./BlockTemplate";
import { Label } from "../FormElements/Label";
import { useAuthSessionsSelector } from "../../redux/selectors.redux";
import { useAppDispatch } from "../../redux/store.redux";
import { createServiceSessionThunk } from "../../redux/auth-sessions/sessions.thunks";

interface ApiKeyFormData {
  ip?: string;
  originUrl?: string;
  label?: string;
}

const GenerateApiKeys = () => {
  const state = useAuthSessionsSelector().service;
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    // watch,
    // formState: { errors },
  } = useForm<ApiKeyFormData>();

  const generatePublicKey = (fData: ApiKeyFormData) => {
    dispatch(
      createServiceSessionThunk({
        data: fData,
      }),
    );
  };

  return (
    <FlexBox $fillWidth $gap={16}>
      <BlockTemplate title={"Api keys list"}>
        <FlexBox $gap={8} $padding={"8px 0"} $alignItems="center" $fillWidth>
          <Text>{"(External services sessions)"}</Text>
        </FlexBox>

        <BaseTable
          data={state.list}
          columns={[
            { title: "Label", path: "label", width: "150px" },
            {
              title: "Api key",
              getData: (data) => data?.publicKey,
              width: "300px",
              // maxWidth: "150px",
            },
            {
              title: "Api secret key",
              getData: (data) => data?.privateKey,
              width: "300px",
              // maxWidth: "150px",
            },
            {
              title: "Ip address list",
              getData: (s) => s.cors?.ips?.join(", "),
              width: "150px",
            },
            {
              title: "Urls list",
              getData: (s) => s.cors?.origins?.join(", "),
              width: "150px",
            },
            { title: "Id", path: "_id", width: "300px" },
          ]}
        />
      </BlockTemplate>

      {state.list.length > 5 ? (
        <Alert message={"Sessions limit is reached"} type={"warning"} />
      ) : (
        <BlockTemplate title={"Generate service API Key"}>
          <FlexForm onSubmit={handleSubmit(generatePublicKey)} $gap={16}>
            {generateApiKeyData.map((item) => {
              return (
                <Label key={item.name} label={item.label}>
                  <FormInput {...item} register={register} />
                </Label>
              );
            })}

            <Button htmlType="submit" size={"middle"} type={"primary"}>
              {"Generate"}
            </Button>
          </FlexForm>
        </BlockTemplate>
      )}
    </FlexBox>
  );
};

export default GenerateApiKeys;

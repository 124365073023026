import { createSlice } from "@reduxjs/toolkit";
import { SliceMap } from "../type";
import * as thunks from "./sessions.thunks";
import { AppAuth } from "../../types/auth/app-auth.namespace";

type AuthSessionId = string;
type UserId = string;

export interface AuthSessionsState {
  service: SliceMap<
    AppAuth.Session.Entity<AppAuth.Session.Type.service>,
    AuthSessionId,
    UserId
  >;
  token: SliceMap<
    AppAuth.Session.Entity<AppAuth.Session.Type.token>,
    AuthSessionId,
    UserId
  >;
  user: SliceMap<
    AppAuth.Session.Entity<AppAuth.Session.Type.user>,
    AuthSessionId,
    UserId
  >;
}

const baseMap = {
  dataMap: {},
  keysMap: {},
  list: [],
  ids: [],
};

const initState: AuthSessionsState = {
  service: baseMap,
  token: baseMap,
  user: baseMap,
};

export const authSessionsSlice = createSlice({
  name: "auth/sessions",
  initialState: initState,
  reducers: {
    // resetMlmRulesStateAction(
    //   s,
    //   {
    //     payload: { type },
    //   }: AnyAction<{
    //     type?: RefmeAuthSessionTypeEnum;
    //   }>,
    // ) {
    //   if (type) {
    //     s.list[type] = [];
    //     return s;
    //   } else {
    //     // ObjectKeys(s.list).forEach((key) => {
    //     //   s.list[key] = [];
    //     // });
    //     s.list = {
    //       service: [],
    //       token: [],
    //       user: [],
    //     };
    //     return s;
    //   }
    // },
  },
  extraReducers: (builder) =>
    builder
      .addCase(thunks.getAllServiceSessionsThunk.fulfilled, (st, a) => {
        st.service = {
          ...st.service,
          list: a.payload.update
            ? a.payload.data.concat(st.service?.list)
            : a.payload.data,
        };
      })

      .addCase(thunks.getAllUserSessionsThunk.fulfilled, (st, a) => {
        st.user = {
          ...st.user,
          list: a.payload.update
            ? a.payload.data.concat(st.user?.list)
            : a.payload.data,
        };
      })

      .addCase(thunks.getAllTokenSessionsThunk.fulfilled, (st, a) => {
        st.token = {
          ...st.token,
          list: a.payload.update
            ? a.payload.data.concat(st.token?.list)
            : a.payload.data,
        };
      })
      .addCase(thunks.createServiceSessionThunk.fulfilled, (st, a) => {
        st.service = {
          ...st.service,
          list: st.service.list.concat([a.payload.data]),
        };
      }),
});

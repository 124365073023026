import { BlockTemplate } from "../BlockTemplate";
import { useMlmRulesSelector } from "../../../redux/selectors.redux";
import { useMemo } from "react";
import { useAppParams } from "../../../hooks/useAppParams";

export interface MlmRulesGroupOverviewBlockProps {
  groupId?: string;
}

export const MlmRulesGroupOverviewBlock =
  ({}: MlmRulesGroupOverviewBlockProps) => {
    const { groupId } = useAppParams();
    const groups = useMlmRulesSelector().groups;

    const current = useMemo(() => {
      return groups.find((el) => el._id === groupId);
    }, [groupId, groups]);

    return (
      <BlockTemplate
        title={`Mlm rules group overview | ${current?.label}`}
      ></BlockTemplate>
    );
  };

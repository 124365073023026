import APP_CONFIGS from "../configs/APP_CONFIGS";
import ClientApi from "./Client.api";
import {
  CreatePaymentRequestDto,
  InvoiceEntity,
  RefmeInvoiceStatusEnum,
  RefmeInvoiceTypeEnum,
} from "../types";
import { AppResponse } from "../types/utils.types";
import { ParsedAppUrlQuery } from "../types/api.types";

export interface GetInvoicesQuery
  extends Partial<Pick<ParsedAppUrlQuery, "limit" | "offset">> {
  statuses?: RefmeInvoiceStatusEnum[];
  types?: RefmeInvoiceTypeEnum[];
}
export default class InvoicesApi {
  private static api = ClientApi.getClient();
  private static endpoints = APP_CONFIGS.endpoints.invoices;

  public static getAll = (
    _?: unknown,
    params?: GetInvoicesQuery,
  ): Promise<AppResponse<InvoiceEntity[]>> => {
    return this.api.get(this.endpoints.getList(), { params });
  };
  public static paymentRequest = (
    data?: CreatePaymentRequestDto,
  ): Promise<AppResponse<InvoiceEntity>> => {
    return this.api.post(this.endpoints.paymentRequest(), data);
  };
}

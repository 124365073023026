import {
  HasAccessStatus,
  HasStatus,
  HasType,
  IBase,
  UUID,
} from "./utils.types";
import { UserEntity } from "./user.types";
import { BalanceAccount } from "./balance-accounts.types";

export namespace Wallet {
  export enum StatusEnum {
    s = "s",
    m = "m",
    l = "l",
    xl = "xl",
    xxl = "xxl",
    xxxl = "xxxl",
  }
  export enum AccessEnum {
    active = "active",
    closed = "closed",
    freeze = "freeze",
  }

  export interface Entity
    extends IBase,
      HasType,
      HasStatus<StatusEnum>,
      HasAccessStatus<AccessEnum>,
      Pick<
        BalanceAccount.Entity,
        "details" | "statistics" | "code" | "saldo" | "token"
      > {
    emitter?: UserEntity;
    holder?: UserEntity;
    accounts?: BalanceAccount.Entity[];
  }

  export interface Dto {
    tokenId: UUID;
    withAccounts?: BalanceAccount.TypesEnum[];
  }
}

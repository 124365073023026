import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { nanoid } from "@reduxjs/toolkit";
import styled from "styled-components";
import ClientApi from "../api/Client.api";
import { useAuthSelector } from "../redux/selectors.redux";
import FlexBox from "../components/Atoms/Flex";

export interface ModalProviderProps {
  children?: React.ReactNode;
}

export interface CreatedModal {
  compId?: string;
  onClose?: () => void;
  index?: number;
}

export type OpenModalHandler = <P extends Partial<CreatedModal> = any>(
  Component: React.FC<P>,
  props?: Omit<P, keyof CreatedModal>,
) => CreatedModal;

export interface ModalStackInfo<P extends Partial<CreatedModal> = any> {
  modalId: string;
  Component: React.FC<P>;
  props?: P;
}

export interface ModalService extends ModalProviderProps {
  open: OpenModalHandler;
  close: (id: string) => void;
}

const ModalProviderCTX = createContext({} as ModalService);

export const useModalService = (): ModalService => useContext(ModalProviderCTX);

const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalStack, setModalStack] = useState<ModalStackInfo[]>([]);

  const handleCloseModal = (id: string) => {
    setModalStack((prev) => prev.filter((m) => m.modalId !== id));
  };

  // const _handleCloseOnBackdrop = useCallback(
  //   (id: string) => (ev: React.MouseEvent<HTMLDivElement>) => {
  //     const { target, currentTarget } = ev;
  //
  //     if (target === currentTarget) handleCloseModal(id);
  //   },
  //   []
  // );

  const handleCloseOnBackdrop = useCallback(
    (id: string) => (ev: React.MouseEvent<HTMLDivElement>) => {
      if (ev.target === ev.currentTarget) {
        ev.target.addEventListener("mouseup", () => handleCloseModal(id), {
          once: true,
        });
        return;
      }
    },
    [],
  );

  const handleOpenModal: OpenModalHandler = useCallback(
    (Component, props) => {
      const modalId = `${Component.name}_${nanoid(8)}`;

      const isModalAlreadyOpen = modalStack.some(
        (modal) => modal.Component.name === Component.name,
      );

      if (!isModalAlreadyOpen && typeof Component === "function") {
        setModalStack((prev) => [...prev, { Component, modalId, props }]);
      }

      return {
        onClose: () => handleCloseModal(modalId),
        compId: modalId,
        index: modalStack.length,
      };
    },
    [modalStack],
  );

  const CTX = useMemo(
    (): ModalService => ({
      open: handleOpenModal,
      close: handleCloseModal,
    }),
    [handleOpenModal],
  );

  const renderModalStack = useMemo(() => {
    return modalStack.map(({ Component, modalId, props }, index) => (
      <Backdrop
        key={modalId}
        $justifyContent={"center"}
        $alignItems={"center"}
        className="Backdrop"
        onMouseDown={(e) => {
          handleCloseOnBackdrop(modalId)(e);
        }}
      >
        <ModalComponentBase>
          <Component
            {...props}
            modalId={modalId}
            onClose={() => handleCloseModal(modalId)}
            index={index}
          />
        </ModalComponentBase>
      </Backdrop>
    ));
  }, [handleCloseOnBackdrop, modalStack]);
  const auth = useAuthSelector();

  useEffect(() => {
    if (auth.access_token) {
      ClientApi.setToken(auth.access_token);
    } else {
      ClientApi.unsetApiKey();
    }
  }, [auth.access_token]);

  return (
    <ModalProviderCTX.Provider value={CTX}>
      {children}

      <ModalStack className={"modalStack"}>{renderModalStack}</ModalStack>
    </ModalProviderCTX.Provider>
  );
};

const ModalStack = styled.div`
  position: relative;
  z-index: 1000;
`;

const Backdrop = styled(FlexBox)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 200;

  background-color: ${(p) => p.theme.backdropDarkColor};
`;

const ModalComponentBase = styled(FlexBox)`
  max-width: 98vw;
  max-height: 98vh;
  overflow: hidden;
  //animation: Backdrop 250ms linear;
  animation: BackdropAnimation 250ms linear;
`;

export default ModalProvider;

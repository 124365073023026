import { CreatePaymentRequestDto, RefmeInvoiceStatusEnum } from "../../types";
import { useAppDispatch } from "../../redux/store.redux";
import React, { HTMLInputTypeAttribute, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import createApiCall from "../../api/createApiCall.api";
import InvoicesApi from "../../api/Invoices.api";
import { pick } from "lodash";
import { setInvoicesListAction } from "../../redux/invoices/invoices.slice";
import { BlockTemplate } from "./BlockTemplate";
import FlexBox, { FlexForm } from "../Atoms/Flex";
import { Button, Radio, Select } from "antd";
import { DefaultOptionType } from "rc-select/es/Select";
import { createPaymentRequest } from "../data/formsData";
import FormInput from "../FormElements/FormInput";
import {
  useInvoicesSelector,
  useTokensSelector,
} from "../../redux/selectors.redux";

const sendOptions: {
  name: keyof CreatePaymentRequestDto;
  label: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  required?: boolean;
}[] = [
  { name: "email", label: "Email", placeholder: "Enter email", type: "email" },
  { name: "phone", label: "Phone", placeholder: "Enter phone" },
  {
    name: "receiverRef",
    label: "Reference",
    placeholder: "Enter receiver reference",
  },
];
const REDIRECT_BASE_URL = "https://gendliar.com.ua/payment/check";

const defV = {
  amount: 1235.542345,
  description: "Your invoice for this beautiful day", // webHookUrl: string;
  redirectUrls: {
    onSuccess: `${REDIRECT_BASE_URL}?status=success`,
    onError: `${REDIRECT_BASE_URL}?status=error`,
    onReject: `${REDIRECT_BASE_URL}?status=rejected`,
  },
  // receiverRef: user?.ref,
  email: "111@mail.com",
  phone: "+380961576499",
  orderId: "cdba3f44-6ea0-42bf-a1fd-018538137b41",
};

const CreateInvoiceBlock = () => {
  const { list: invoices } = useInvoicesSelector();
  const { list: tokens } = useTokensSelector();
  const dispatch = useAppDispatch();

  const [currentTab, setCurrentTab] = useState(0);

  const { reset, register, handleSubmit, unregister, watch, setValue } =
    useForm<CreatePaymentRequestDto>({
      defaultValues: defV,
    });

  const fv = watch();

  const onValid = (fData: CreatePaymentRequestDto) => {
    return createApiCall(InvoicesApi.paymentRequest, {
      data: pick(fData, [
        "tokenId",
        "amount",
        "description",
        "redirectUrls",
        "orderId",
        sendOptions[currentTab].name ?? "email",
      ]),
      onSuccess({ data }) {
        reset();
      },
    });
  };

  useEffect(() => {
    if (invoices.length) {
      return;
    }

    createApiCall(InvoicesApi.getAll, {
      params: {
        statuses: [RefmeInvoiceStatusEnum.wait_confirm],
        offset: invoices.length,
        limit: 20,
      },
      onSuccess: ({ data }) =>
        dispatch(
          setInvoicesListAction({
            refresh: true,
            data: data.map((el) => ({ ...el, key: el._id })),
          }),
        ),
    });
    // eslint-disable-next-line
  }, []);

  return (
    <BlockTemplate title={"Payment request"}>
      <FlexForm onSubmit={handleSubmit(onValid)} $gap={16}>
        <FlexBox
          $fxDirection={"row"}
          $justifyContent={"center"}
          $fillWidth
          $gap={12}
        >
          <Select<string>
            options={tokens?.map(
              (tab): DefaultOptionType & { value: string } => {
                return {
                  label: tab.label,
                  value: tab._id,
                };
              },
            )}
            placeholder={"Select token"}
            value={fv?.tokenId}
            size={"large"}
            style={{ width: "100%" }}
            onChange={(id) => setValue("tokenId", id)}
            allowClear
            onClear={() => setValue("tokenId", "")}
          />
        </FlexBox>

        {createPaymentRequest.map((item) => {
          return <FormInput key={item.name} {...item} register={register} />;
        })}

        <FlexBox $fillWidth>
          <Radio.Group
            value={currentTab}
            size={"large"}
            onChange={(e) => {
              setCurrentTab(e.target.value);
            }}
          >
            {sendOptions.map((tab, index) => {
              return <Radio.Button value={index}>{tab.label}</Radio.Button>;
            })}
          </Radio.Group>
        </FlexBox>

        <FormInput {...sendOptions[currentTab]} required register={register} />

        <Button htmlType={"submit"} size={"large"}>
          {"Publish invoice"}
        </Button>
      </FlexForm>
    </BlockTemplate>
  );
};
export default CreateInvoiceBlock;

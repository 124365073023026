import React, { useCallback, useLayoutEffect } from "react";
import { Navigate, Outlet, redirect } from "react-router-dom";
import FlexBox from "../components/Atoms/Flex";
import { Container } from "../components/Atoms/Container";
import { useAuthSelector } from "../redux/selectors.redux";
import Header from "../components/layout/Header";
import ClientApi from "../api/Client.api";
import SideBar, { SideBarContainer } from "../components/layout/SideBar";
import { useAppDispatch } from "../redux/store.redux";
import {
  clearUserAction,
  setAuthTokensAction,
  SetAuthTokensActionPayload,
} from "../redux/auth/auth.slice";
import { DataPreloader } from "../components/Atoms/DataPreloader";

const PrivateLayout = () => {
  const dispatch = useAppDispatch();
  const { access_token, roleIs } = useAuthSelector();

  useLayoutEffect(() => {
    if (access_token) {
      ClientApi.setToken(access_token);
    } else {
      ClientApi.unsetToken();
      dispatch(clearUserAction());
    }
  }, [access_token, dispatch]);

  return !access_token ? (
    <Navigate to={"/logIn"} />
  ) : (
    <FlexBox>
      <Header />

      {access_token && (
        <DataPreloader roleIs={roleIs} isLogged={!!access_token} />
      )}

      <FlexBox $fxDirection={"row"} $fillWidth $flex={1}>
        <SideBarContainer>
          <SideBar />
        </SideBarContainer>

        <Container $flex={1} overflow={"auto"}>
          <Outlet />
        </Container>
      </FlexBox>
    </FlexBox>
  );
};

export default PrivateLayout;

export type BaseRoutes =
  | "/register"
  | "/login"
  | "/balances"
  | "/dashboard"
  | string;

export const usePrivateRoute = ({ redirectTo }: { redirectTo: BaseRoutes }) => {
  const user = useAuthSelector();
  const dispatch = useAppDispatch();

  const onUnauthorized = useCallback(() => {
    dispatch(clearUserAction());
  }, [dispatch]);

  const onRefreshToken = useCallback(
    (data: SetAuthTokensActionPayload) => {
      console.log("Token refreshed:", data);

      dispatch(setAuthTokensAction(data));
    },
    [dispatch],
  );

  useLayoutEffect(() => {
    if (!user?.access_token) {
      ClientApi.unsetToken();
      // LocalApi.Client.unsetToken();

      redirect(redirectTo);
    } else {
      ClientApi.setToken(user.access_token);
      // LocalApi.Client.setToken(user.access_token);

      const unsubscribers = [
        ClientApi.onUnauthorized(onUnauthorized),
        ClientApi.onRefreshToken(onRefreshToken),
      ];
      return () => {
        unsubscribers.forEach((cb) => cb());
      };
    }
  }, [onRefreshToken, onUnauthorized, redirectTo, user]);
};

import { Title } from "../Atoms/Title";
import React from "react";
import { Block } from "../Atoms/BlockContainer";
import FlexBox, { FlexProps } from "../Atoms/Flex";

export const BlockTemplate = ({
  title,
  children,
  header,
  footer,
  disabled,
  contentContainerStyles,
}: {
  title?: string;
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  disabled?: boolean;
  contentContainerStyles?: FlexProps;
}) => {
  return (
    <Block.Container $alignItems="center" className={"BlockContainer"}>
      <Block.Header $fillWidth $borderBottom={"1px solid green"}>
        {title && <Title>{title}</Title>}
        {header && header}
      </Block.Header>

      <FlexBox
        className={"BlockMain"}
        $padding={"8px 16px 16px"}
        overflow={"hidden"}
        $maxWidth={"100%"}
        $fillWidth
        $alignItems={"center"}
        {...contentContainerStyles}
      >
        {children}
      </FlexBox>

      {footer && (
        <Block.Footer $fillWidth className={"BlockFooter"}>
          {footer}
        </Block.Footer>
      )}
    </Block.Container>
  );
};

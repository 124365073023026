import FlexBox from "./Flex";
import styled from "styled-components";

export const Container = styled(FlexBox)`
  padding: 16px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  min-height: 100%;
`;

export const ContainerSmall = styled(FlexBox)`
  padding: 16px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  min-height: 100%;
`;

import { isAxiosError } from "axios";
import { AppResponse } from "../types/utils.types";

export type GetResponseCallback<SD = any, RD = any, MD = any, Params = any> = (
  data?: SD,
  params?: Params,
) => Promise<AppResponse<RD, MD>>;

export type ApiEndpoint<
  Data = any,
  Params = any,
  ResData = any,
  Meta = any,
> = GetResponseCallback<Data, ResData, Meta, Params>;

export interface ApiCallerPayload<
  SD = any,
  RD = any,
  MD = any,
  E = any | unknown,
  Params = any,
> {
  data?: SD;
  params?: Params;
  onSuccess?: (data: { data: RD; meta?: MD }) => void;
  onError?: (error: E) => void;
  onLoading?: (loading: boolean) => void;
  logError?: boolean;
  logRes?: boolean;
  logResData?: boolean;
  throwError?: boolean;
}

export type ApiCaller<SD = any, RD = any, E = any, MD = any, CTX = any> = (
  payload: ApiCallerPayload<SD, RD, E>,
  getResponseCallback: GetResponseCallback<SD, RD, MD>,
  context?: CTX,
) => Promise<AppResponse<RD, MD> | undefined>;

export const createApiCall = async <
  SD = any,
  RD = any,
  E = any,
  MD = any,
  CTX = any,
  Params = any,
>(
  getResponseCallback: GetResponseCallback<SD, RD, MD, Params>,
  {
    onLoading,
    onError,
    onSuccess,
    data,
    params,
    logError,
    logRes,
    logResData,
    throwError,
  }: ApiCallerPayload<SD, RD, MD, E, Params> = {},
  context?: CTX,
): Promise<AppResponse<RD, MD> | undefined> => {
  onLoading && onLoading(true);

  const getResponse = context
    ? getResponseCallback.bind(context)
    : getResponseCallback;

  try {
    const res = await getResponse(data, params);
    if (res && onSuccess) {
      logResData && console.log(getResponseCallback.name, res?.data);

      onSuccess(res.data);
    }
    logRes && console.log(getResponseCallback.name, res);
    onLoading && onLoading(false);

    return res;
  } catch (e) {
    onError && onError(e as unknown as E);
    logError && console.error(getResponseCallback.name, e);
    onLoading && onLoading(false);

    if (throwError) {
      throw e;
    }
  }
};

const createThunkApiCall = async <
  SD = any,
  RD = any,
  E = any,
  MD = any,
  CTX = any,
>(
  getResponseCallback: GetResponseCallback<SD, RD, MD>,
  {
    onLoading,
    onError,
    onSuccess,
    data,
    logError,
    logRes,
  }: ApiCallerPayload<SD, RD, MD, E>,
  context?: CTX,
): Promise<AppResponse<RD, MD> | undefined> => {
  onLoading && onLoading(true);

  const getResponse = context
    ? getResponseCallback.bind(context)
    : getResponseCallback;

  try {
    const res = await getResponse(data);
    if (res && res.data.data && onSuccess) {
      onSuccess(res.data);
    }
    logRes && console.log(res);
    return res;
  } catch (e) {
    onError && onError(e as unknown as E);
    logError && console.error(e);
    throw e;
  } finally {
    onLoading && onLoading(false);
  }
};

export const buildThunkPayloadCreator =
  <SD = any, RD = any, MD = any, E = any, Context = any>(
    getResponseCallback: GetResponseCallback<SD, RD, MD>,
    context?: Context,
  ) =>
  () => {
    return async (payload: ApiCallerPayload<SD, RD, MD, E>, thunkApi: any) => {
      try {
        const res = await createThunkApiCall<SD, RD, E, MD, Context>(
          getResponseCallback,
          payload,
          context,
        );

        if (res) return res.data.data;
      } catch (e) {
        return thunkApi?.rejectWithValue(isAxiosError(e));
      }
    };
  };

export default createApiCall;

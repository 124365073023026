import AuthApi from "../../api/auth/Auth.api";
import { createAppAsyncThunk } from "../../shared/utils";

export enum AuthThunkEnum {
  getCurrent = "auth/getCurrent/thunk",
  refreshToken = "auth/refreshUserThunk",
  loginByEmail = "auth/logInByEmailThunk",
  logOutUser = "auth/logOutThunk",
}

export const logInByEmailThunk = createAppAsyncThunk(
  AuthThunkEnum.loginByEmail,
  AuthApi.logIn,
);

export const logoutUserThunk = createAppAsyncThunk(
  AuthThunkEnum.logOutUser,
  AuthApi.logOut,
);

export const getCurrentUserThunk = createAppAsyncThunk(
  AuthThunkEnum.getCurrent,
  AuthApi.getCurrent,
);

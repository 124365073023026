import ClientApi from "./Client.api";
import APP_CONFIGS from "../configs/APP_CONFIGS";
import { AppResponse } from "../types/utils.types";
import {
  CreateMlmRuleDto,
  CreateMlmRulesGroupDto,
  MlmRuleDepthType,
  MlmRuleEntity,
  MlmRulesGroupEntity,
  MlmRuleSourceVolumeEnum,
  MlmRuleThresholdTypeEnum,
  MlmRuleTypeEnum,
  UpdateMlmRuleDto,
  UserStatusEnum,
} from "../types";
import { ParsedAppUrlQuery } from "../types/api.types";
import { ApiAsyncResponse } from "./types.api";
import { Wallet } from "../types/wallets.types";

export namespace Mlm {
  export namespace Rules {
    export type GetOneQuery = GetOneRuleQuery;
    export type GetListQuery = GetMlmRulesQuery;
    export type RemoveOneQuery = RemoveOneParams;
  }
}
export interface GetOneRuleQuery
  extends Pick<ParsedAppUrlQuery, "withDeleted"> {
  type?: MlmRuleTypeEnum;

  sourceVolume?: MlmRuleSourceVolumeEnum;

  thresholdType?: MlmRuleThresholdTypeEnum;

  userStatus?: UserStatusEnum;

  walletStatus?: Wallet.StatusEnum;

  depth?: MlmRuleDepthType;
}
export interface GetMlmRulesQuery extends GetOneRuleQuery {
  types?: MlmRuleTypeEnum[];

  sourceVolumes?: MlmRuleSourceVolumeEnum[];

  thresholdTypes?: MlmRuleThresholdTypeEnum[];

  userStatuses?: UserStatusEnum[];

  walletStatuses?: Wallet.StatusEnum[];

  depths?: MlmRuleDepthType[];

  hasCustomKey?: boolean;
  customKey?: string;

  groupId?: string;
}

interface RemoveOneParams {
  id: string;
  isSoft?: boolean;
}

export class MlmRulesGroupApi {
  private static _client = ClientApi.getClient();
  private static _enpoints = ClientApi.endpoints.mlm_rules.groups;

  public static getAll = (
    _?: undefined,
    params?: { types?: MlmRuleTypeEnum[] } & Pick<
      ParsedAppUrlQuery,
      "withDeleted"
    >,
  ): Promise<AppResponse<MlmRulesGroupEntity[]>> => {
    return this._client.get(this._enpoints.getAll(), {
      params: { ...params, withDeleted: params?.withDeleted ?? true },
    });
  };
  public static create = (
    data?: CreateMlmRulesGroupDto,
  ): ApiAsyncResponse<MlmRulesGroupEntity> => {
    return this._client.post(this._enpoints.create(), data);
  };
  // public static update = (
  //   data?: UpdateMlmRuleDto,
  // ): Promise<AppResponse<MlmRuleEntity>> => {
  //   return this._client.patch(this._enpoints.updateRule(), data);
  // };
  public static remove = (
    _?: undefined,
    params?: Mlm.Rules.RemoveOneQuery,
  ): Promise<AppResponse<MlmRuleEntity>> => {
    return this._client.delete(this._enpoints.delete(params?.id), {
      params: {
        isSoft: params?.isSoft ?? true,
      },
    });
  };
  public static restore = (
    _?: undefined,
    query?: { id: string },
  ): Promise<AppResponse<MlmRuleEntity>> => {
    return this._client.patch(this._enpoints.restore(query?.id));
  };
}

export class MlmRulesApi {
  private static _client = ClientApi.getClient();

  private static _enpoints = APP_CONFIGS.endpoints.mlm_rules;

  static groups = MlmRulesGroupApi;

  public static getRulesList = (
    _?: undefined,
    params?: GetMlmRulesQuery,
  ): Promise<AppResponse<MlmRuleEntity[]>> => {
    return this._client.get(this._enpoints.getAll(), { params });
  };
  public static createRule = (
    data?: CreateMlmRuleDto,
  ): Promise<AppResponse<MlmRuleEntity>> => {
    return this._client.post(this._enpoints.create(), data);
  };
  public static updateRule = (
    data?: UpdateMlmRuleDto,
  ): Promise<AppResponse<MlmRuleEntity>> => {
    return this._client.patch(this._enpoints.update(), data);
  };
  public static removeRule = (
    _?: undefined,
    query?: RemoveOneParams,
  ): Promise<AppResponse<MlmRuleEntity>> => {
    return this._client.delete(this._enpoints.delete(query?.id), {
      params: {
        isSoft: query?.isSoft ?? true,
      },
    });
  };
  public static restoreRule = (
    _?: undefined,
    query?: { id: string },
  ): Promise<AppResponse<MlmRuleEntity>> => {
    return this._client.patch(this._enpoints.restore(query?.id));
  };
}

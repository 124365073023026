import { InvoiceEntity } from "../../types";
import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../store.redux";

export interface InvoicesState {
  list: InvoiceEntity[];
}

const state: InvoicesState = {
  list: [],
};
export const invoicesSlice = createSlice({
  name: "invoices",
  initialState: state,
  reducers: {
    setInvoicesListAction: (
      s: InvoicesState,
      { payload: { refresh, data } }: Action<{ data: InvoiceEntity[] }>,
    ) => {
      s.list = refresh ? data : s.list.concat(data);
    },
  },
  extraReducers: (builder) => builder,
});

export const { setInvoicesListAction } = invoicesSlice.actions;

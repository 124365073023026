import { RoleIsReturn } from "../shared/utils";
import { MlmRuleTypeEnum, RefmeUserRoleEnum } from "../types";
import { useAppDispatch } from "../redux/store.redux";
import {
  useAuthSessionsSelector,
  useEventsSelector,
  useInvoicesSelector,
  useMlmRulesSelector,
  useTransactionsSelector,
} from "../redux/selectors.redux";
import { useCallback, useEffect } from "react";
import createApiCall from "../api/createApiCall.api";
import InvoicesApi from "../api/Invoices.api";
import { setInvoicesListAction } from "../redux/invoices/invoices.slice";
import { TrackEventsApi } from "../api";
import { getAllServiceSessionsThunk } from "../redux/auth-sessions/sessions.thunks";
import { getAllUsersThunk } from "../redux/users/users.thunks";
import { getAllTransactions } from "../redux/transactions/transactions.thunks";
import { getRevenueEventsListThunk } from "../redux/events/revenue-events.thunks";
import { getAllTokensThunk } from "../redux/tokens/tokens.thunks";
import { getMlmRulesListThunk } from "../redux/mlm-rules/mlm-rules.thunks";

export interface UsePreloadDataHookProps {
  isLogged?: boolean;
  roleIs?: RoleIsReturn<RefmeUserRoleEnum>;
}

export function usePreloadTokens({
  isLogged = false,
}: UsePreloadDataHookProps) {
  const dispatch = useAppDispatch();
  // const list = useTokensSelector().list;

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    // if (list.length) {
    //   return;
    // }

    dispatch(getAllTokensThunk({}));

    // eslint-disable-next-line
  }, [dispatch]);
}
export function usePreloadInvoices({
  isLogged = false,
}: UsePreloadDataHookProps) {
  const dispatch = useAppDispatch();
  const list = useInvoicesSelector().list;

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    if (list.length) {
      return;
    }
    createApiCall(InvoicesApi.getAll, {
      onSuccess: ({ data }) =>
        dispatch(
          setInvoicesListAction({
            refresh: true,
            data: data.map((el) => ({ ...el, key: el._id })),
          }),
        ),
    });
    // eslint-disable-next-line
  }, [dispatch]);
}

export function usePreloadMlmRules({
  isLogged = false,
  type,
}: { type?: MlmRuleTypeEnum } & UsePreloadDataHookProps) {
  const dispatch = useAppDispatch();
  const list = useMlmRulesSelector().list;

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    if (list.length) {
      return;
    }
    dispatch(
      getMlmRulesListThunk({
        refresh: true,
        params: {
          type,
        },
      }),
    );

    // eslint-disable-next-line
  }, [dispatch]);
}

export function usePreloadServiceSessions({
  isLogged = false,
}: UsePreloadDataHookProps) {
  const dispatch = useAppDispatch();
  const list = useAuthSessionsSelector().service.list;

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    if (list?.length) {
      return;
    }

    dispatch(getAllServiceSessionsThunk({}));

    // eslint-disable-next-line
  }, [dispatch]);
}

export function usePreloadTransactions({
  isLogged = false,
}: UsePreloadDataHookProps) {
  const dispatch = useAppDispatch();

  const list = useTransactionsSelector().list;

  useEffect(() => {
    if (!isLogged) {
      return;
    }

    if (!list?.length) {
      dispatch(getAllTransactions({ refresh: true }));
    }
    // eslint-disable-next-line
  }, [dispatch]);
}

export function usePreloadUsers({
  isLogged = false,
  roleIs,
}: UsePreloadDataHookProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    if (!roleIs?.admin) {
      return;
    }
    dispatch(getAllUsersThunk({ refresh: true }));

    // eslint-disable-next-line
  }, [dispatch]);
}

export function usePreloadRevenues({
  isLogged = false,
  roleIs,
}: UsePreloadDataHookProps) {
  // const {user}=useAuthSelector()
  const state = useEventsSelector();
  const dispatch = useAppDispatch();
  type Args = Parameters<typeof TrackEventsApi.getRevenuesList>;

  const loadData = useCallback(
    (...args: Args) => {
      const [data, params] = args;

      return dispatch(
        getRevenueEventsListThunk({
          data,
          params,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    // if (!roleIs?.admin) {
    //   return;
    // }
    loadData();
    // eslint-disable-next-line
  }, [dispatch]);

  return {
    refreshData: () => loadData(),
    loadData,
    state,
  };
}

import { toast, ToastContent, ToastOptions } from "react-toastify";

type ToastLoaderRemover = () => void;
type ToastLoaderHandler = {
  open: (params?: {
    beforeClose?: [message?: ToastContent, options?: ToastOptions];
    afterClose?: [message?: ToastContent, options?: ToastOptions];
  }) => { close: ToastLoaderRemover };
};

export class ToastService {
  public static loading = toast.loading;
  public static remove = toast.dismiss;
  public static createToastLoader(
    message?: ToastContent,
    options?: ToastOptions,
  ): ToastLoaderRemover {
    const toastId = toast.loading(message, options);
    return () => toast.dismiss(toastId);
  }
  public static createLoader(
    message?: ToastContent,
    options?: ToastOptions,
  ): ToastLoaderHandler {
    return {
      open: (params) => {
        const toastId = toast.loading(message, options);
        return {
          close: () => {
            // controls?.beforeClose && controls?.beforeClose();
            params?.afterClose && toast(...params?.afterClose);

            toast.dismiss(toastId);

            params?.beforeClose && toast(...params?.beforeClose);
            // controls?.afterClose && controls?.afterClose();
          },
        };
      },
    };
  }
  public static success(
    message: ToastContent,
    options?: ToastOptions,
  ): ToastLoaderRemover {
    const toastId = toast.success(message, options);
    return () => toast.dismiss(toastId);
  }
  public static error(
    message: ToastContent,
    options?: ToastOptions,
  ): ToastLoaderRemover {
    const toastId = toast.error(message, options);
    return () => toast.dismiss(toastId);
  }
  public static info(
    message: ToastContent,
    options?: ToastOptions,
  ): ToastLoaderRemover {
    const toastId = toast.info(message, options);
    return () => toast.dismiss(toastId);
  }
}

export default ToastService;

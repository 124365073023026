import { User } from "../../../types";
import { Tag, TagProps } from "antd";
import React from "react";

export const UserStatusTag = ({
  user,
  status,
}: {
  user?: Partial<User.Entity>;
  status?: User.Entity["status"];
}) => {
  const _status = user?.status || status;
  return (
    <Tag bordered color={_status ? userTagColors?.[_status] : "default"}>
      {_status}
    </Tag>
  );
};

export const UserRoleTag = ({ user }: { user: Partial<User.Entity> }) => {
  return (
    <Tag bordered color={user?.role ? userTagColors?.[user.role] : "default"}>
      {user?.role}
    </Tag>
  );
};

export const UserAccessStatusTag = ({
  user,
}: {
  user: Partial<User.Entity>;
}) => {
  return (
    <Tag
      bordered
      color={
        user?.accessStatus ? userTagColors?.[user.accessStatus] : "default"
      }
    >
      {user?.accessStatus}
    </Tag>
  );
};
export const userTagColors: Record<
  User.StatusEnum | User.AccessEnum | User.RoleEnum | string,
  TagProps["color"]
> = {
  [User.StatusEnum.init]: "default",
  [User.StatusEnum.trainee]: "yellow",
  // [RefmeUser.StatusEnum.sinior]: "gold",
  [User.StatusEnum.senior]: "gold",
  [User.StatusEnum.junior]: "blue",
  [User.StatusEnum.middle]: "purple",
  [User.AccessEnum.ban]: "error",
  [User.AccessEnum.active]: "success",
  [User.AccessEnum.freeze]: "warning",
  [User.RoleEnum.admin]: "default",
  [User.RoleEnum.vendor]: "yellow",
  [User.RoleEnum.ambassador]: "blue",
};

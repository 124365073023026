import { createAppAsyncThunk } from "../../shared/utils";
import { WalletsApi } from "../../api";

export enum WalletsThunkType {
  getAll = "wallets/getAllThunk",
}

export const getAllWalletsThunk = createAppAsyncThunk(
  WalletsThunkType.getAll,
  WalletsApi.getAll,
);

export enum BalancesThunkType {
  getAll = "wallets/balances/getAllThunk",
}
export const getAllBalancesThunk = createAppAsyncThunk(
  BalancesThunkType.getAll,
  WalletsApi.balances.getAll,
);
